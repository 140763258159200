import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label } from 'reactstrap';
import { setNewBanner, addNewBanner } from "../store/banner/actions"
import axios from "axios"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalAdd extends Component {
  constructor(props){
    super(props)
    this.state = {
      title: "",
      description: "",
      photo: "",
      status: "0",
      type: "",
      priority: "",
      link: "",
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  async toggle(){
    await this.props.setNewBanner("toggleAdd", !this.props.toggleAdd)
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "photo"){
      await this.setState({
        photo: e.target.files[0]
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      let flag = false

      if(!this.state.title){
        await this.setState({
          detail: "Form title is empty"
        })
        flag = true
      } else if(!this.state.photo){
        await this.setState({
          detail: "Form photo is empty"
        })
        flag = true
      } else if(!this.state.description){
        await this.setState({
          detail: "Form description is empty"
        })
        flag = true
      } else if(!this.state.type){
        await this.setState({
          detail: "Form type is empty"
        })
        flag = true
      } else if(!this.state.priority){
        await this.setState({
          detail: "Form priority is empty"
        })
        flag = true
      }

      if(flag){
        await this.setState({
          error_dlg: true
        })
      } else {
        await this.setState({
          expRight: true
        })
        var FormData = require('form-data');
        const fd = new FormData();
        fd.append("file", this.state.photo, this.state.photo.name)
  
        const config = {
          url: BASE_URL_UPLOAD,
          method: "POST",
          data: fd
        }
        const result = await axios(config)
        let obj = {
          title: this.state.title,
          photo: result.data.imageurl,
          description: this.state.description,
          status: this.state.status,
          type: this.state.type,
          priority: this.state.priority,
          link: this.state.link
        }
        await this.props.addNewBanner("/admin/banner", obj, "POST")
        await this.setState({
          title: "",
          description: "",
          photo: "",
          type: "",
          status: "0",
          priority: ""
        })
        await this.props.setNewBanner("toggleAdd", !this.props.toggleAdd)
        await this.setState({
          expRight: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Banner</ModalHeader>
          <AvForm>
            <FormGroup>
            <Label sm="12">Title</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter title" name="title" value={this.state.title} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label>Photo</Label>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="photo" onChange={this.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="textarea" placeholder="Enter description" name="description" value={this.state.description} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Type</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter type" name="type" value={this.state.type} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Priority</Label>
              <Col sm="12">
                <AvField required type="number" placeholder="Enter priotity" name="priority" value={this.state.priority} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Link</Label>
              <Col sm="12">
                <AvField type="text" placeholder="Enter link" name="link" value={this.state.link} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd } = state.Banner
  return { toggleAdd };
}


export default withRouter(connect(mapStatetoProps, { setNewBanner, addNewBanner })(ModalAdd));