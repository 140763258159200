import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_CASH_IN, } from './actionTypes';
import {  setCashIn } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* cashIn({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const methodbank = state.method === "-1" ? "" : state.method
        const statusCash = state.status === "-1" ? "" : state.status
        state.method = methodbank
        state.status = statusCash
        // console.log(state);
        response = yield call(callGetCategory, "/admin/cashin", state)
        yield put(setCashIn(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setCashIn({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
                amount: error.data.amount,
                pending: error.data.pending,
                total_cashin: error.data.total_cashin,
                nameBank: error.data.nameBank,
            }))
        }
    }
}

export function* watchCashIn() {
    yield takeEvery(CHECK_CASH_IN, cashIn)
}

function* cashInSaga() {
    yield all([fork(watchCashIn)]);
}

export default cashInSaga;