import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Badge, Button } from 'reactstrap';
import moment from "moment"
import { setDataP2P } from "../store/actions"
import { convertToRupiah } from "../../../helpers/authUtils"
import SweetAlert from 'react-bootstrap-sweetalert';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'

class ModalDetailP2P extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalEdit: false,
            modalEditBiller: false,
            status: "",
            text: ""
        }
        this.toggle = this.toggle.bind(this)
        this.updateTrans = this.updateTrans.bind(this)
        this.updateTransBiller = this.updateTransBiller.bind(this)
    }

    toggle(){
        this.props.setDataP2P("toggleSee", !this.props.toggleSee)
    }

    async updateTrans(){
        if(this.state.status === "SUKSES"){
            await this.props.setDataP2P("expRightEdit1", !this.props.expRightEdit1)
        } else {
            await this.props.setDataP2P("expRightEdit2", !this.props.expRightEdit2)
        }
        const request = {
            id: this.props.dataP2P._id,
            buyer_id: this.props.dataP2P.buyer_id,
            status: this.state.status
        }

        await this.props.update(request)
        await this.setState({
            modalEdit: false
        })

    }

    async updateTransBiller(){
        if(this.state.status === "RESEND"){
            await this.props.setDataP2P("expRightEdit3", !this.props.expRightEdit3)
        } else {
            await this.props.setDataP2P("expRightEdit4", !this.props.expRightEdit4)
        }

        const request = {
            id: this.props.dataP2P._id,
            status: this.state.status
        }

        await this.props.updateBiller(request)
        await this.setState({
            modalEditBiller: false
        })
    }

    render() {
        return(
            <React.Fragment>
                <Modal className="modal-xl" isOpen={this.props.toggleSee} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Detail Transaction P2P</ModalHeader>
                    <ModalBody  style={{backgroundColor: "#f2f2f2"}}>
                    {!this.props.dataP2P ? <p>Test1</p> :
                        <Row>
                        <Col>
                        <span><h5>Data Transaction</h5></span>
                          <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Transaction ID</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P._id ? <b>-</b> : <b>{this.props.dataP2P._id}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Category</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.category.name ? <b>-</b> : <b>{this.props.dataP2P.category.name}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Brand</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.brands.name ? <b>-</b> : <b>{this.props.dataP2P.brands.name}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Payment Method</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.selected_payment_method.payment_channel ? <b>-</b> : <b>{this.props.dataP2P.selected_payment_method.payment_channel}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Seller ID</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.seller_id ? <b>-</b> : <b>{this.props.dataP2P.seller_id}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Seller Name</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.seller_name ? <b>-</b> : <b>{this.props.dataP2P.seller_name}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Seller Photo</span>
                              <span className="traffic-sales-amount">
                              {!this.props.dataP2P.seller_photo ? <b>-</b> : <img src={this.props.dataP2P.seller_photo} alt="logoStore" width="100px" height="100px"/>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Buyer ID</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.buyer_id ? <b>-</b> : <b>{this.props.dataP2P.buyer_id}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Buyer Name</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.buyer_name ? <b>-</b> : <b>{this.props.dataP2P.buyer_name}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Payment Status</span>
                              <span className="traffic-sales-amount">
                                {
                                    this.props.dataP2P.payment_status === "WAITING" ? <Badge color="primary">{this.props.dataP2P.payment_status}</Badge> :
                                    this.props.dataP2P.payment_status === "SUCCESS" ? <Badge color="success">{this.props.dataP2P.payment_status}</Badge> :
                                    this.props.dataP2P.payment_status === "FAILED" ? <Badge color="danger">{this.props.dataP2P.payment_status}</Badge> :
                                    <Badge color="dark">EXPIRED</Badge>
                                }
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Transaction Status</span>
                              <span className="traffic-sales-amount">
                                {
                                    this.props.dataP2P.status === "BELUMBAYAR" ? <Badge color="info">{this.props.dataP2P.status}</Badge> :
                                    this.props.dataP2P.status === "MENUNGGU" ? <Badge color="warning">{this.props.dataP2P.status}</Badge> :
                                    this.props.dataP2P.status === "DITERIMA" ? <Badge color="secondary">{this.props.dataP2P.status}</Badge> :
                                    this.props.dataP2P.status === "DIKIRIM" ?  <Badge color="primary">{this.props.dataP2P.status}</Badge> :
                                    this.props.dataP2P.status === "SUKSES" ?  <Badge color="success">{this.props.dataP2P.status}</Badge> :
                                    <Badge color="danger">{this.props.dataP2P.status}</Badge>
                                }
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                          </ul>
                        </Col>

                        <Col>
                        <span><h5>Data Waktu Transaction</h5></span>
                            <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Created</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.created_at ? <b>-</b> : <b>{moment(this.props.dataP2P.created_at, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Waktu Diterima</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.waktu_diterima ? <b>-</b> : <b>{moment(this.props.dataP2P.waktu_diterima, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Waktu Dikirim</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.waktu_dikirim ? <b>-</b> : <b>{moment(this.props.dataP2P.waktu_dikirim, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Waktu Sukses</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.waktu_sukses ? <b>-</b> : <b>{moment(this.props.dataP2P.waktu_sukses, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Waktu Gagal</span>
                              <span className="traffic-sales-amount">
                                {!this.props.dataP2P.waktu_gagal ? <b>-</b> : <b>{moment(this.props.dataP2P.waktu_gagal, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")}</b>}
                                <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                </span>
                              </span>
                            </li>
                            </ul>

                            <span><h5>Bukti Pengiriman</h5></span>
                            <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                            {!this.props.dataP2P.bukti_kirim ?
                            <li className="traffic-sales-content list-group-item ">
                            <span className="traffic-sales-name">Image</span>
                              <span className="traffic-sales-amount">
                                  <b>Tidak Ada</b>
                                  <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light"></span>
                              </span>
                            </li>
                            :
                            <>
                              <li className="traffic-sales-content list-group-item ">
                              <span className="traffic-sales-name">Image</span>
                                <span className="traffic-sales-amount">
                                {!this.props.dataP2P.bukti_kirim ? <b>-</b> : 
                                  <a href={this.props.dataP2P.bukti_kirim} target="_blank" rel="noreferrer noopener" className="text-white">
                                    <img src={this.props.dataP2P.bukti_kirim} alt={this.props.dataP2P.bukti_kirim} width="100px" height="100px" />
                                  </a>
                                }
                                    <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light"></span>
                                </span>
                              </li>
                              </>
                            }
                            </ul>

                            <span><h5>Data Komplain</h5></span>
                            <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                            {!this.props.dataP2P.komplain.status ? 
                            <li className="traffic-sales-content list-group-item ">
                                <span className="traffic-sales-name">Komplain</span>
                                <span className="traffic-sales-amount">
                                    <b>Tidak Ada</b>
                                    <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light"></span>
                                </span>
                            </li>
                            :
                                <>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Photo Komplain</span>
                                        <span className="traffic-sales-amount">
                                          {!this.props.dataP2P.komplain.image ? <b>-</b> : 
                                            <a href={this.props.dataP2P.komplain.image} target="_blank" rel="noreferrer noopener" className="text-white">
                                              <img src={this.props.dataP2P.komplain.image} alt={this.props.dataP2P.komplain.image} width="100px" height="100px" />
                                            </a>
                                          }
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Detail Komplain</span>
                                        <span className="traffic-sales-amount">
                                            {!this.props.dataP2P.komplain.detail_komplain ? <b>-</b> : <b>{this.props.dataP2P.komplain.detail_komplain}</b>}
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                </>
                            }
                            </ul>
                        </Col>

                        <Col>
                        <span><h5>Data Product Transaction</h5></span>
                            <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">ID</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].id ? <b>-</b> : <b>{this.props.dataP2P.products[0].id}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Name</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].name ? <b>-</b> : <b>{this.props.dataP2P.products[0].name}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Image</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].name ? <b>-</b> : <img src={this.props.dataP2P.products[0].image} alt="logoStore" width="100px" height="100px"/>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">SKU</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].sku ? <b>-</b> : <b>{this.props.dataP2P.products[0].sku}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Price</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].name ? <b>-</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.products[0].price))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">TYPE</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].type ? <b>-</b> : <b>{this.props.dataP2P.products[0].type}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Quantity</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].qty ? <b>-</b> : <b>{this.props.dataP2P.products[0].qty}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Discount Price</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].discount_price ? <b>-</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.products[0].discount_price))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Sub Total</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].sub_total ? <b>-</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.products[0].sub_total))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Platform Fee</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.platform_fee ? <b>Rp. 0</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.platform_fee))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Payment Channel Fee</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.selected_payment_method.type ? <b>Rp. 0</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.selected_payment_method.fee))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Total Price</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.total_price ? <b>Rp. 0</b> : <b>{convertToRupiah(parseInt(this.props.dataP2P.total_price))}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">ID Pelanggan</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].idpelanggan ? <b>-</b> : <b>{this.props.dataP2P.products[0].idpelanggan}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                                <li className="traffic-sales-content list-group-item ">
                                    <span className="traffic-sales-name">Description</span>
                                    <span className="traffic-sales-amount">
                                        {!this.props.dataP2P.products[0].description ? <b>-</b> : <b>{this.props.dataP2P.products[0].description}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </Col>
                      </Row>
                    }
                    </ModalBody>
                    <ModalFooter>
                    {!this.props.dataP2P ? 
                        ""
                    : 
                    this.props.dataP2P.komplain.status === 1 && this.props.dataP2P.status === "DIKIRIM" && this.props.type !== "cs" ?
                    <>
                    <LaddaButton
                        loading={this.props.expRightEdit1}
                        onClick={() => this.setState({modalEdit:true, status: "SUKSES", text: "Apakah kamu yakin untuk meneruskan saldo ke penjual?"})}
                        data-style={EXPAND_RIGHT}
                        className="btn btn-primary"
                        >SUKSESKAN</LaddaButton>
                    <LaddaButton
                        loading={this.props.expRightEdit2}
                        onClick={() => this.setState({modalEdit:true, status: "GAGAL", text: "Apakah kamu yakin mengembalikan saldo ke pembeli?"})}
                        data-style={EXPAND_RIGHT}
                        className="btn btn-danger"
                        >GAGALKAN</LaddaButton>
                    </> : ""
                    }
                    {this.props.dataP2P.product_type !== "PRODUCT_INSTANT" ?
                        ""
                    :
                    this.props.type === "cs" ? 
                    <LaddaButton
                      loading={this.props.expRightEdit3}
                      onClick={() => this.setState({modalEditBiller:true, status: "RESEND", text: "Apakah kamu yakin untuk RESEND CALLBACK?"})}
                      data-style={EXPAND_RIGHT}
                      className="btn btn-primary">
                        Resend Callback
                    </LaddaButton>
                    :
                    <>
                        <LaddaButton
                            loading={this.props.expRightEdit3}
                            onClick={() => this.setState({modalEditBiller:true, status: "RESEND", text: "Apakah kamu yakin untuk RESEND CALLBACK?"})}
                            data-style={EXPAND_RIGHT}
                            className="btn btn-primary"
                            >Resend Callback
                        </LaddaButton>

                        <LaddaButton
                            loading={this.props.expRightEdit4}
                            onClick={() => this.setState({modalEditBiller:true, status: "GAGAL", text: "Apakah kamu yakin refund dana ke user?"})}
                            data-style={EXPAND_RIGHT}
                            className="btn btn-danger">
                              GAGALKAN
                        </LaddaButton>
                    </>
                    }
                    <Button onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
                </Modal>

                {this.state.modalEdit ? 
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.updateTrans()}
                    onCancel={() => this.setState({ modalEdit: false })} >
                    {this.state.text}
                    </SweetAlert> : null
                }
                {this.state.modalEditBiller ? 
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.updateTransBiller()}
                    onCancel={() => this.setState({ modalEditBiller: false })} >
                    {this.state.text}
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { toggleSee, dataP2P, expRightEdit, expRightEdit1, expRightEdit2, expRightEdit3, expRightEdit4 } = state.P2P
    const { type } = state.Username
    return { toggleSee, dataP2P, expRightEdit, expRightEdit1, expRightEdit2, expRightEdit3, expRightEdit4, type };
  }

  export default withRouter(connect(mapStatetoProps, { setDataP2P })(ModalDetailP2P));