import { CHECK_KYC, SET_KYC, SET_DATA_KYC } from "./actionTypes"

const initialState = {
  kycs: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, kyc: null,
  toggleEdit: false
}

const kyc = (state = initialState, action) => {
  switch(action.type){
    case CHECK_KYC:
      state = {
        ...state
      }
      break;
    case SET_KYC:
      state = {
        ...state,
        kycs: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
      case SET_DATA_KYC:
        const { name, data } = action.payload
        state = {
          ...state,
          [name]: data
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default kyc