import { CHECK_TAG, SET_TAG, SET_DATA_TAG, CUD_TAG } from "./actionTypes"

export const checkTag = (history, state, flag) => {
  return {
    type: CHECK_TAG,
    payload: {history, state, flag}
  }
}

export const setTag = (props) => {
  return {
    type: SET_TAG,
    payload: props
  }
}

export const setDataTag = (name, data) => {
  return {
    type: SET_DATA_TAG,
    payload: {name, data}
  }
}

export const cudTag = (url, state, method, id) => {
  return {
    type: CUD_TAG,
    payload: {url, state, method, id}
  }
}