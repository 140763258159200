import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalFooter, Button, Col, FormGroup, Label } from 'reactstrap';
import { setDataTag, cudTag } from "../store/tag/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { AvField, AvForm } from "availity-reactstrap-validation";

class ModalAddPages extends Component{
    constructor(props){
            super(props)
            this.state = {
                name: "",
                expRight: false,
            }
            this.toggle = this.toggle.bind(this)
            this.handleSubmit = this.handleSubmit.bind(this)
            this.onChangeInput = this.onChangeInput.bind(this)
    }

    async onChangeInput(e){
        const { name, value } = e.target
        await this.setState({
            [name]: value
        })
    }

    async toggle(){
        await this.props.setDataTag("toggleAdd", !this.props.toggleAdd)
    }

    async handleSubmit(){
        await this.setState({ expRight: true })
        const payload = {
            name: this.state.name
        }
        await this.props.cudTag("/admin/tag", payload, "POST")
        await this.toggle()
        await this.setState({ expRight: false, name: "" })
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Add Tag</ModalHeader>
                    <AvForm onValidSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label sm="12">Name</Label>
                            <Col sm="12">
                                <AvField required type="text" placeholder="Enter name" name="name" value={this.state.name} onChange={this.onChangeInput} />
                            </Col>
                        </FormGroup>
                        <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        <LaddaButton
                            loading={this.state.expRight}
                            data-style={EXPAND_RIGHT}
                            className="btn btn-primary"
                        >Save changes</LaddaButton>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { toggleAdd } = state.Tag
    return { toggleAdd };
}


export default withRouter(connect(mapStatetoProps, { setDataTag, cudTag })(ModalAddPages));