import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_POST, } from './actionTypes';
import {  setPost } from './actions';
import { setUsername } from "../../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* transaction({payload: {history, state, flag}}) {
    try {
      let response = null
      if(flag === true){
          const responseUsername = yield call(checkToken)
          yield put(setUsername(responseUsername))
      }
      response = yield call(callGetCategory, "/admin/post", state)
      yield put(setPost(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setPost({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
        }
    }
}

export function* watchPost() {
    yield takeEvery(CHECK_POST, transaction)
}

function* postSaga() {
    yield all([fork(watchPost)]);
}

export default postSaga;