import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { Row, Col, Card, CardBody, Container, Table, Button } from "reactstrap";
import { convertToRupiah } from "../../../helpers/authUtils"
import { checkSetting, setNewSetting } from "../store/setting/actions"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"
// import DataTable from 'react-data-table-component';
import ModalEdit from "./ModalEdit"

class SettingPage extends Component{
    constructor(props){
        super(props)
        this.state = {
            id: 0,
            sellerFee: 0,
            feeVA: 0
        }
        this.buttonEdit = this.buttonEdit.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    componentDidMount(){
        this.props.activateAuthLayout()
        this.props.checkSetting(this.props.history, {}, true)
    }

    async onChangeInput(e){
        const {name, value} = e.target
        await this.setState({
            [name]: value
        })
    }

    async buttonEdit(){
        let row = this.props.settings[0]
        await this.setState({
            id: row.id,
            sellerFee: row.sellerFee,
            feeVA: row.feeVA,
        })
        await this.props.setNewSetting("toggleEdit", !this.props.toggleEdit)
    }

    render(){
        return(
            <React.Fragment>
                <NotificationMessage />
                <Container style={{width:"50%"}}>
                    
                    <Row>
                        <Col className="stretched_card">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="4" lg="4" md="8" sm="8">
                                            <h3 className="mb-3">Settings</h3>
                                        </Col>
                                        <Col xl={{ offset: 6, size: 1  }}
                                        lg={{ offset: 6, size: 1 }} 
                                        md="1" 
                                        sm="1"
                                        className="mb-2">
                                            <Button color="primary" className="ml-2" onClick={this.buttonEdit}>Edit</Button>
                                        </Col>
                                    </Row>
                                    <Table bordered hover size="sm">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Seller Product Fee Transaction</th>
                                                <td>{this.props.settings.length > 0 ? convertToRupiah(this.props.settings[0].sellerFee) : convertToRupiah(0)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Fee VA</th>
                                                <td>{this.props.settings.length > 0 ? convertToRupiah(this.props.settings[0].feeVA) : convertToRupiah(0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalEdit
                    onChangeInput={this.onChangeInput}
                    data={{id: this.state.id, sellerFee: this.state.sellerFee, feeVA: this.state.feeVA}}
                />
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { settings, toggleEdit } = state.Setting
    return { settings, toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkSetting, setNewSetting })(SettingPage));