import { NOTIFICATION, CHECK_USERNAME_MSG } from "./actionTypes"

export const setDataMessage = (name, data) => {
  return {
    type: NOTIFICATION,
    payload: {name, data}
  }
}

export const checkUsernameMessage = (history) => {
    return  {
        type: CHECK_USERNAME_MSG,
        payload: {history}
    }
}