import React, { Component } from "react";
import { Button, Row, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkUsername } from "../../modules/Category/store/username/actions";
import { checkLogin, expiredToken } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';

class Pageslogin extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    if(localStorage.getItem('token')){
      this.props.checkUsername(this.props.history)
    }
  }

  handleSubmit(event, values) {
    this.props.checkLogin(values.email, values.password, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="container-fluid">
            <Row>
              <div className="login-bg">
                <div className="login-overlay"></div>
                <div className="login-left">
                  <img src="https://hay-images.sgp1.digitaloceanspaces.com/hayumarket-logo.png" alt="Logo" />
                </div>
              </div>

              <div className="login-form">
                <AvForm
                  id="login_form_sec"
                  onValidSubmit={this.handleSubmit}
                  onInvalidSubmit={this.onInvalidSubmit}
                >
                  <div className="login-form-body">
                    {this.props.user && (
                      <Alert color="success">Your Login is successfull.</Alert>
                    )}

                    {this.props.loginError && (
                      <Alert color="danger">{this.props.loginError}</Alert>
                    )}

                    <div>
                      <AvField
                        name="email"
                        label="Email"
                        value={this.state.email}
                        placeholder="Enter Email"
                        type="text"
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <AvField
                        name="password"
                        label="Password"
                        value={this.state.password}
                        placeholder="Enter Password"
                        type="password"
                        required
                      />
                    </div>
                    <div className="submit-btn-area">
                      <Button
                        color="primary"
                        className="btn btn-primary"
                        type="submit"
                      >
                        Log In <i className="ti-arrow-right"></i>
                      </Button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Row>
          </div>
        </div>
          {this.props.expired ?
            <SweetAlert
                error
                title="ERROR"
                onConfirm={() => this.props.expiredToken("expired", false)}  >
                  Your token is expired
              </SweetAlert> : null}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, loginError, loading, expired } = state.Login;
  return { user, loginError, loading, expired };
};

export default withRouter(connect(mapStatetoProps, { checkLogin, checkUsername, expiredToken })(Pageslogin));
