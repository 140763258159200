import { CHECK_BRAND_P2P, SET_BRAND_P2P, SET_CHANGE_BRAND_P2P } from "./actionTypes"

const initialState = {
  brandP2P: [], toggleAdd: false, toggleEdit: false, successDelete: false,
  descSuccess: "", failedDelete: false, descFailed: "", categori: ""
}

const brandP2P = (state = initialState, action) => {
  switch(action.type){
    case CHECK_BRAND_P2P:
      state = {
        ...state
      }
      break;
    case SET_BRAND_P2P:
      state = {
        ...state,
        brandP2P: action.payload
      }
      break;
    case SET_CHANGE_BRAND_P2P:
      const {name, data} = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default brandP2P