import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_CASH_IN } from '../cashin/actionTypes';
import {  setCashIn, setDataCashIn } from '../cashin/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updateCashOut({payload: {url, state, method, id}}) {
    try {
        const { name, status, method: methodBank, from, to, tmpStatus, remark } = state
        let sendMethod = methodBank === "-1" ? "" : methodBank
        const responseStatus = yield call(hitAPIWasmarket, url, {status, remark}, method, id)
        const response = yield call(callGetCategory, "/admin/cashin", {page: 1, size: 10, name, method: sendMethod, from, to, status: tmpStatus === "-1" ? "" : tmpStatus })
        yield put(setCashIn(response))
        yield put(setDataCashIn("confirm", true))
        yield put(setDataCashIn("msgSuccess", responseStatus.data))
    } catch (error) {
        console.log(error);
    }
}

export function* watchUpdateCashIn() {
    yield takeEvery(UPDATE_CASH_IN, updateCashOut)
}

function* updateCashInSaga() {
    yield all([fork(watchUpdateCashIn)]);
}

export default updateCashInSaga;