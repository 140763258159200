import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_MANAGEMENT_USER, } from './actionTypes';
import {  setManagementUser } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* managementUser({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/user/", state)
        yield put(setManagementUser(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setManagementUser({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
                totalSeller: error.data.totalSeller,
                totalUser: error.data.totalUser
            }))
        }
    }
}

export function* watchManagementUser() {
    yield takeEvery(CHECK_MANAGEMENT_USER, managementUser)
}

function* managementUserSaga() {
    yield all([fork(watchManagementUser)]);
}

export default managementUserSaga;