import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_BADGE } from '../badge/actionTypes';
import { setBadge } from '../badge/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* badge({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/badge", {page: 1, size: 10})
        yield put(setBadge(response))
    } catch (error) {
    }
}

export function* watchCUDBadge() {
    yield takeEvery(ADD_BADGE, badge)
}

function* cudBadgeSaga() {
    yield all([fork(watchCUDBadge)]);
}

export default cudBadgeSaga;