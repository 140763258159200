import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CREATE_TRANSFER } from '../monitoring/actionTypes';
import {  setMonitoring, setDataMonitoring } from '../monitoring/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* createTransferMonitoring({payload: {url, state, method, id}}) {
    try {
        const responseStatus = yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/monitoring", {})
        yield put(setMonitoring(response))
        yield put(setDataMonitoring("confirm", true))
        yield put(setDataMonitoring("msgSuccess", responseStatus.data))
    } catch (error) {
    }
}

export function* watchCreateTransfer() {
    yield takeEvery(CREATE_TRANSFER, createTransferMonitoring)
}

function* createTransferSaga() {
    yield all([fork(watchCreateTransfer)]);
}

export default createTransferSaga;