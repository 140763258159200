import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_BA_TRANSFER } from '../monitoring/actionTypes';
import {  setBATransfer } from '../monitoring/actions';
//============ Authentication Functions =============*
import { callGetCategory } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* createGetDataBATransfer({payload: {state}}) {
    try {
      let response = null
      response = yield call(callGetCategory, "/admin/monitoring/ba-transfer", state)
      yield put(setBATransfer(response))
    } catch (error) {
      if(error.responseCode === 404){
        yield put(setBATransfer({
            rows: [],
            currentPage: 1,
            totalItems: 0,
            totalPages: 0,
        }))
    }
    }
}

export function* watchgetBATransfer() {
    yield takeEvery(CHECK_BA_TRANSFER, createGetDataBATransfer)
}

function* getBATransfer() {
    yield all([fork(watchgetBATransfer)]);
}

export default getBATransfer;