import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Metismenu
import MetisMenu from "metismenujs";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    new MetisMenu("#menu", {});

    var matchingMenuItem = null;
    var ul = document.getElementById("menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("active");
          }
        }
      }
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* {this.props.type === "Super" ?} */}
        <div className="left side-menu">
          <div className="sidebar-menu light-sidebar">
            <div className="sidebar-header">
              <div className="logo">
                <img src="https://hay-images.sgp1.digitaloceanspaces.com/hayumarket-logo.png" alt="Logo" />
              </div>
            </div>
            <div className="main-menu">
              <div className="menu-inner" id="sidebar_menu">
                <nav>
                  <ul className="metismenu" id="menu">
                    {this.props.type === "Super" || this.props.type === "cs" ? 
                    <>
                      <li>
                        <Link to="/#">
                          <i className="feather ft-gitlab"></i>
                          <span>Product Management</span>
                        </Link>
                        <ul className="submenu">
                          <li><Link to="category"><i className="ti-tag"></i><span>category</span></Link></li>
                          <li><Link to="brand"><i className="fa fa-gg"></i><span>brand</span></Link></li>
                          <li><Link to="product"><i className="fa fa-diamond"></i><span>product master</span></Link></li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#">
                          <i className="feather ft-gitlab"></i>
                          <span>P2P Product Management</span>
                        </Link>
                        <ul className="submenu">
                          <li><Link to="etalase-p2p"><i className="fa fa-gg"></i><span>etalase</span></Link></li>
                          <li><Link to="category-p2p"><i className="ti-tag"></i><span>category</span></Link></li>
                          <li><Link to="brand-p2p"><i className="fa fa-diamond"></i><span>brand</span></Link></li>
                        </ul>
                      </li>
                    <li>
                        <Link to="banner">
                          <i className="ion-flag"></i>
                          <span>banner</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/#">
                          <i className="fa fa-home"></i>
                          <span>store</span>
                        </Link>
                        <ul className="submenu">
                          <li><Link to="badge"><i className="fa fa-certificate"></i><span>badge</span></Link></li>
                          <li><Link to="store-badge"><i className="fa fa-tag"></i><span>store badge</span></Link></li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#">
                          <i className="fa fa-gears"></i>
                          <span>Content</span>
                        </Link>
                        <ul className="submenu">
                          <li><Link to="post"><i className="fa fa-folder"></i><span>post</span></Link></li>
                          <li><Link to="tag"><i className="fa fa-tags"></i><span>tag</span></Link></li>
                          <li><Link to="post-tag"><i className="fa fa-share-alt"></i><span>post-tag</span></Link></li>
                        </ul>
                      </li> 
                    </> : ""
                    }
                    <li>
                        <Link to="manage-user">
                            <i className="ion-person-stalker"></i>
                            <span>user management</span>
                        </Link>
                      </li>
                    <li>
                        <Link to="transaction">
                            <i className="fa fa-money"></i>
                            <span>transaction</span>
                        </Link>
                    </li>
                    {this.props.type === "cs" ? 
                    <>
                      <li>
                        <Link to="/#">
                          <i className="fa fa-dollar"></i>
                          <span>Cash</span>
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="cashin">
                              <i className="fa fa-arrow-right"></i>
                              <span>IN</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="cashout">
                              <i className="fa fa-arrow-left"></i>
                              <span>OUT</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : ""
                    }
                    <li>
                        <Link to="/#">
                          <i className="fa fa-gamepad"></i>
                          <span>P2P</span>
                        </Link>
                        <ul className="submenu">
                          {this.props.type === "Super" || this.props.type === "admin" ?
                            <li>
                                <Link to="cashout-p2p">
                                <i className="fa fa-arrow-left"></i>
                                <span>COMMISION OUT</span>
                                </Link>
                            </li> : ""
                            }
                          <li>
                            <Link to="p2p">
                              <i className="fa fa-cart-plus" aria-hidden="true"></i>
                              <span>Transaction</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="komplain-p2p">
                              <i className="fa fa-cart-plus" aria-hidden="true"></i>
                              <span>Komplain</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="log-monit">
                          <i className="fa fa-tv"></i>
                          <span>monitoring callback</span>
                        </Link>
                      </li>
                    {this.props.type === "Super" || this.props.type === "admin" ? 
                    <>
                      <li>
                        <Link to="/#">
                          <i className="fa fa-dollar"></i>
                          <span>Cash</span>
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="cashin">
                              <i className="fa fa-arrow-right"></i>
                              <span>IN</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="cashout">
                              <i className="fa fa-arrow-left"></i>
                              <span>OUT</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="monitoring">
                          <i className="fa fa-tv"></i>
                          <span>monitoring balance</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="mutasi-bank">
                          <i className="fa fa-bank"></i>
                          <span>mutasi bank</span>
                        </Link>
                      </li>
                    </> : ""
                    }
                    {this.props.type === "Super" ? 
                    <li>
                      <Link to="setting">
                        <i className="fa fa-gear"></i>
                        <span>settings fee</span>
                      </Link>
                    </li> : ""
                    }
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { type } = state.Username
  return { type };
}

export default withRouter(connect(mapStatetoProps, {  })(Sidebar))