import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment"
import { activateAuthLayout } from "../../../../store/actions";
import NotificationMessage from "../../../NotificationMessage/Pages/Notification"
import { checkPost, setDataPost, cudPost } from "../store/post/actions"
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import FormPostComponent from "./Components/PostContent"

class PagePost extends Component{
    constructor(props){
        super(props)
        this.state = {
            delPost: false,
            post: "",
            title: "",
            snippet: "",
            content: "",
            data: "",
            id: "",
            contents: ""
        }
        this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.onShowModalAdd = this.onShowModalAdd.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.buttonEdit = this.buttonEdit.bind(this)
        this.buttonDelete = this.buttonDelete.bind(this)
    }

    componentDidMount(){
        this.props.activateAuthLayout()
        this.props.checkPost(this.props.history, {
            page: this.props.currentPage,
            size: this.props.size
        }, true)
    }


    async buttonEdit(data){
        await this.setState({
            title: data.title,
            snippet: data.snippet,
            content: data.content,
            id: data.id
        })
        await this.props.setDataPost("toggleEdit", !this.props.toggleEdit)
    }

    async buttonDelete(data){
        await this.props.cudPost("/admin/post", {}, "DELETE", data.id)
        await this.setState({ delPost: false })
    }

    async onChangeInput(e){
        const {value, name} = e.target
        await this.setState({
            [name]: value
        })
    }

    async changeRowsPerPage(size){
        await this.props.setDataPost("size", size)
        let props = this.props
        await this.props.checkPost(this.props.history, {
            page: props.currentPage,
            size: size,
        }, false)
    }

    async onChangePage(page){
        await this.props.setDataPost("currentPage", page)
        let props = this.props
        await this.props.checkPost(this.props.history, {
            page: page,
            size: props.size,
        }, false)
    }

    async onShowModalAdd(){
        await this.props.setDataPost("toggleAdd", !this.props.toggleAdd)
    }

    render(){
        const columns = [
            {
                name: "Action",
                center: true,
                cell: (row) => {
                    return (
                    <>
                        <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(row)}></i>
                        <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                            delPost: true,
                            post: row
                        })}></i>
                    </>
                    )
                }
            },
            {
                name: "ID",
                selector: "id",
                width: "70px"
            },
            {
                name: "Title",
                selector: "title",
                width: "200px",
                sortable: true
            },
            {
                name: "Snippet",
                selector: "snippet",
                width: "150px"
            },
            {
                name: "AuthorID",
                selector: "author_id",
                width: "80px",
                center: true
            },
            {
                name: "Created At",
                selector: "created_at",
                width: "200px",
                cell: (row) => {
                    return moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")
                }
            },
            {
                name: "Updated At",
                selector: "updated_at",
                cell: (row) => {
                    return row.updated_at ? moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss") : <span></span>
                },
                width: "200px",
            },
        ]
        return (
            <React.Fragment>
                <NotificationMessage />
                <Row>
                <Col className="stretched_card">
                    <Card>
                        <CardBody>
                            <Container>
                            <Row>
                                <Col xl="2" lg="3" md="3" sm="6">
                                <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i> Add Post</Button>
                                </Col>
                            </Row>
                            </Container>
                            <Container>
                                <DataTable
                                    title="POSTS"
                                    columns={columns}
                                    data={this.props.posts}
                                    pagination
                                    responsive={true}
                                    paginationTotalRows={this.props.totalItems}
                                    paginationRowsPerPageOptions={[10,20,50,100]}
                                    onChangeRowsPerPage={this.changeRowsPerPage}
                                    onChangePage={this.onChangePage}
                                    paginationServer={true}
                                    expandableRows={true}
                                    expandableRowsComponent={<FormPostComponent/>}
                                />
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
                </Row>
                <ModalAdd />
                <ModalEdit
                    data={{id: this.state.id, title: this.state.title, snippet: this.state.snippet, content: this.state.content, from: this.state.from, to: this.state.to}}
                    onChangeInput={this.onChangeInput}
                />

                {this.props.confirm ?
                    <SweetAlert
                        success
                        title="SUCCESS"
                        onConfirm={() => this.props.setDataPost("confirm", false)}  >
                        {this.props.msgSuccess}
                    </SweetAlert> : null
                }

                {this.state.delPost ? 
                    <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.buttonDelete(this.state.post)}
                    onCancel={() => this.setState({ delPost: false })} >
                    Are you sure to delete: <b>{this.state.post.title}</b>?
                    </SweetAlert> : null
                }

                {this.props.confirmError ?
                    <SweetAlert
                        error
                        title="ERROR"
                        onConfirm={() => this.props.setDataPost("confirmError", false)}  >
                        {this.props.msgError}
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { posts, currentPage, totalItems, size, toggleAdd, toggleEdit, confirm, msgSuccess, confirmError, msgError } = state.Post
    return { posts, currentPage, totalItems, size, toggleAdd, toggleEdit, confirm, msgSuccess, confirmError, msgError };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkPost, setDataPost, cudPost })(PagePost));