import { CHECK_USERNAME, SET_USERNAME} from './actionTypes'

const initialState = {
    username: "",
    email: "",
    type: ""
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_USERNAME:
            state = {
                ...state
            }
        break;
        case SET_USERNAME:
            state = {
                ...state,
                username: action.payload.name,
                email: action.payload.email,
                type: action.payload.type
            }
        break;
        default:
            state = { ...state };
            break;
    }
    return state
}

export default user