import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_BRAND_P2P } from '../actionTypes';
import {  setBrandP2P, setNewBrandP2P } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* brandActionP2P({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/p2p/brand", {})
        yield put(setBrandP2P(response))
        if(method === "POST"){
          yield put(setNewBrandP2P("descSuccess", "Sukses menambah brand!"))
        }
        if(method === "PUT"){
          yield put(setNewBrandP2P("descSuccess", "Sukses mengganti brand!"))
        }
        if(method === "DELETE"){
          yield put(setNewBrandP2P("descSuccess", "Sukses menghapus brand!"))
        }
        yield put(setNewBrandP2P("successDelete", true))
    } catch (error) {
      console.log("MASUK")
      console.log(error, "saga======")
      yield put(setNewBrandP2P("failedDelete", true))
      yield put(setNewBrandP2P("descFailed", "Internal server error saga!"))
    }
}

export function* watchUserBrandActionP2P() {
    yield takeEvery(ADD_BRAND_P2P, brandActionP2P)
}

function* addBrandActionP2PSaga() {
    yield all([fork(watchUserBrandActionP2P)]);
}

export default addBrandActionP2PSaga;