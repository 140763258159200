import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_USERNAME_MSG } from './actionTypes';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* messageCheck({payload: {history}}) {
    try {
        const responseUsername = yield call(checkToken)
        yield put(setUsername(responseUsername))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            console.log(error)
        }
    }
}

export function* watchMessage() {
    yield takeEvery(CHECK_USERNAME_MSG, messageCheck)
}

function* messageSaga() {
    yield all([fork(watchMessage)]);
}

export default messageSaga;