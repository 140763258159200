import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Badge, Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import { activateAuthLayout } from "../../../store/actions";
import { checkBanner, setNewBanner, addNewBanner } from "../store/banner/actions"
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class BannerPages extends Component{
  constructor(props){
    super(props)
    this.state = {
      id: "",
      title: "",
      description: "",
      photo: "",
      tmpPhoto: "",
      type: "",
      status: "",
      priority: "",
      link: "",
      dataDelete: null,
      swal: false, success_dlg: false, dynamic_title: "", 
      dynamic_description: ""
    }
    this.buttonEdit = this.buttonEdit.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
  }

  async componentDidMount(){
    let props = this.props
    await this.props.activateAuthLayout()
    await this.props.checkBanner(this.props.history, {
      page: props.currentPage,
      size: props.size,
      status: props.status
    }, true)
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "photo"){
      await this.setState({
        photo: e.target.files[0],
        tmpPhoto: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async onChangePage(page){
    await this.props.setNewBanner("page", page)
    let props = this.props
    await this.props.checkBanner(this.props.history, {
      page: page,
      size: props.size,
      status: props.status
    }, false)
  }

  async changeRowsPerPage(size){
    let props = this.props
    await this.props.setNewBanner("size", size)
    await this.props.checkBanner(this.props.history, {
      page: props.currentPage,
      size: size,
      status: props.status
    }, false)
  }

  async onShowModalAdd(){
    await this.props.setNewBanner("toggleAdd", !this.props.toggleAdd)
  }

  async buttonEdit(e, data){
    await this.setState({
      id: data.id,
      title: data.title,
      description: data.description,
      status: data.status,
      type: data.type,
      tmpPhoto: data.photo,
      priority: data.priority,
      link: data.link
    })
    await this.props.setNewBanner("toggleEdit", !this.props.toggleEdit)
  }

  async buttonDelete(data){
    this.setState({ swal: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'Your file has been deleted.' })
    this.props.addNewBanner("/admin/banner", {}, "DELETE", data.id)
  }

  render() {
    const columns = [
      {
        name: "Photo",
        selector: "photo",
        sortable: true,
        width: "200px",
        cell: row => {
          return <img src={row.photo} alt={row.photo} />
        }
      },
      {
        name: "Title",
        selector: "title",
        sortable: true,
        width: "150px"
      },
      {
        name: "Description",
        selector: "description",
        sortable: true,
        width: "350px"
      },
      {
        name: "Type",
        selector: "type",
        sortable: true,
        width: "100px"
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        cell: row => {
          return (
            row.status === 0 || row.status === false ? <Badge color="danger">Inactive</Badge> :
            <Badge color="success" style={{color: "black"}}>Active</Badge> 
          )
        },
        width: "100px"
      },
      {
        name: "Priority",
        selector: "priority",
        sortable: true,
        center: true,
        cell: row => {
          return(
            <b>{row.priority}</b>
          )
        }
      },
      {
        name: "Link",
        selector: "link",
        width: "130px",
        center: true,
        cell: ({link})=> {
            return(
                link ? <a href={link} target="_blank" rel="noreferrer noopener" className="btn btn-primary">SHOW</a> : ""
            )
        }
      },
      {
        name: "Action",
        sortable: true,
        center: true,
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "30px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(e, row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "30px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                swal: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
    ]
    
    return(
      <React.Fragment>
        <NotificationMessage />
        
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i> Add Banner</Button>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    title="Banner"
                    columns={columns}
                    data={this.props.banners}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAdd />
        <ModalEdit 
          onChangeInput={this.onChangeInput} 
          data={{id: this.state.id, title: this.state.title, description: this.state.description, photo: this.state.photo, type: this.state.type, status: this.state.status, tmpPhoto: this.state.tmpPhoto, priority: this.state.priority, link: this.state.link}}
        />

        {this.state.swal ? 
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => this.buttonDelete(this.state.dataDelete)}
          onCancel={() => this.setState({ swal: false, error_dlg: true, dynamic_title: 'Cancelled', dynamic_description: 'Your imaginary file is safe :)' })} >
          You won't be able to revert this!
          </SweetAlert> : null
        }

        {this.state.success_dlg ?
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ success_dlg: false })}  >
            {this.state.dynamic_description}
            </SweetAlert> : null}

        {this.state.error_dlg ?
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.dynamic_description}
          </SweetAlert> : null}
              
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { currentPage, totalItems, size, status, banners, toggleAdd, toggleEdit } = state.Banner
  return { currentPage, totalItems, size, status, banners, toggleAdd, toggleEdit, };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkBanner, setNewBanner, addNewBanner })(BannerPages));