import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, Badge, Input, Button } from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import { activateAuthLayout } from "../../../../store/actions";
import NotificationMessage from "../../../NotificationMessage/Pages/Notification"
import { checkPostTag, setDataPostTag, cudPostTag } from "../store/postTag/actions"
import ModalEdit from "./ModalEdit"

class PostTagPages extends Component{
    constructor(props){
        super(props)
        this.state = {
            tags: [],
            id: "",
            title: "",
            tmpTitle: ""
        }
        this.buttonEdit = this.buttonEdit.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.searchName = this.searchName.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount(){
        this.props.activateAuthLayout()
        this.props.checkPostTag(this.props.history, {
            page: this.props.currentPage,
            size: this.props.size
        }, true)
    }

    async searchName(){
        let props = this.props
        await this.props.setDataPostTag("currentPage", 1)
        await this.props.setDataPostTag("size", 10)
        await this.props.checkPostTag(this.props.history, {
            page: props.currentPage,
            size: props.size,
            title: this.state.tmpTitle,
        }, false)
    }

    async handleKeyPress(e){
        if (e.key === "Enter") {
            let props = this.props
            await this.props.setDataPostTag("currentPage", 1)
            await this.props.setDataPostTag("size", 10)
            await this.props.checkPostTag(this.props.history, {
                page: props.currentPage,
                size: props.size,
                title: this.state.tmpTitle,
            }, false)
        }
    }

    async changeRowsPerPage(size){
        let props = this.props
        await this.props.setDataPostTag("size", size)
        await this.props.checkBadge(this.props.history, {
            page: props.currentPage,
            size: size,
        }, false)
    }

    async onChangePage(page){
        await this.props.setDataPostTag("currentPage", page)
        let props = this.props
        await this.props.checkBadge(this.props.history, {
            page: page,
            size: props.size,
        }, false)
    }

    async onChangeInput(e){
        const {name, value} = e.target
        if(name === "tags"){
            let dataTags = this.state.tags
            const filter = dataTags.filter(el => el === +value)
            if(filter.length > 0){
                const newFilter = dataTags.filter(el => el !== +value)
    
                await this.setState({
                    [name]: newFilter
                })
            } else {
                dataTags.push(+value)
                await this.setState({
                    [name]: dataTags
                })
            }
        } else {
            await this.setState({
                [name]: value
            })
        }
    }

    async buttonEdit(data){
        let tmp = []
        data.tags.forEach(el => {
            tmp.push(+el.id)
        })
        await this.setState({
            id: data.id,
            tags: tmp,
            title: data.title
        })
        await this.props.setDataPostTag("toggleEdit", !this.props.toggleEdit)
    }

    render(){
        const columns = [
            {
                name: "ID",
                selector: "id",
                width: "70px"
            },
            {
                name: "Title",
                selector: "title",
                width: "300px"
            },
            {
                name: "Tags",
                selector: "tags",
                center: true,
                grow: 10,
                cell: row => {
                    return(
                        row.tags.length === 0 ? "" :
                        row.tags.map(el => {
                            return (<Badge key={el.id} color="primary" className="mr-1">{el.name}</Badge>)
                        })
                    )
                }
            },
            {
                name: "Action",
                sortable: true,
                center: true,
                cell: (row) => {
                    return (
                    <>
                        <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={() => this.buttonEdit(row)}></i>
                    </>
                    )
                }
            },
        ]
        return(
            <React.Fragment>
                <NotificationMessage />
                <Container style={{width: "90%"}}>
                <h2 className="card_title mb-3">Post Tag</h2>
                    <Row>
                        <Col className="stretched_card">
                            <Card>
                                <CardBody>
                                    <Container>
                                        <Row>
                                            <Col xl="3" lg="3" md="4" sm="12">
                                                <div className="input-group">
                                                    <Input className="mr-1" type="text" onKeyPress={this.handleKeyPress} placeholder="Search name" name="tmpTitle" value={this.state.tmpTitle} onChange={this.onChangeInput}/>
                                                    <Button onClick={this.searchName}>Search</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container>
                                        <DataTable
                                            noHeader
                                            columns={columns}
                                            data={this.props.posts}
                                            pagination
                                            responsive={true}
                                            paginationTotalRows={this.props.totalItems}
                                            paginationRowsPerPageOptions={[10,20,50,100]}
                                            onChangeRowsPerPage={this.changeRowsPerPage}
                                            onChangePage={this.onChangePage}
                                            paginationServer={true}
                                        />
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalEdit 
                    data={{id: this.state.id, tags: this.state.tags, title: this.state.title}}
                    onChangeInput={this.onChangeInput}
                />

                {this.props.confirm ?
                    <SweetAlert
                        success
                        title="SUCCESS"
                        onConfirm={() => this.props.setDataPostTag("confirm", false)}  >
                        {this.props.msgSuccess}
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { posts, currentPage, totalItems, size, toggleEdit, confirm, msgSuccess } = state.PostTag
    return { posts, currentPage, totalItems, size, toggleEdit, confirm, msgSuccess };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkPostTag, setDataPostTag, cudPostTag })(PostTagPages));