import { CHECK_ETALASE_P2P, SET_ETALASE_P2P, SET_CHANGE_ETALASE_P2P, ADD_ETALASE_P2P } from "./actionTypes"

export const checkEtalaseP2P = (history, state, flag) => {
  return {
    type: CHECK_ETALASE_P2P,
    payload: {history, state, flag}
  }
}

export const setEtalaseP2P = (props) => {
  return {
    type: SET_ETALASE_P2P,
    payload: props
  }
}

export const setNewEtalaseP2P = (name, data) => {
  return {
    type: SET_CHANGE_ETALASE_P2P,
    payload: {name, data}
  }
}

export const addNewEtalaseP2P = (url, state, method, id) => {
  return {
    type: ADD_ETALASE_P2P,
    payload: {url, state, method, id}
  }
}