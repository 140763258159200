import { CHECK_MANAGEMENT_USER, SET_MANAGEMENT_USER, SET_DATE_MANAGEMENT_USER, UPDATE_MANAGEMENT_USER, EXPORT_MANAGEMENT_USER } from "./actionTypes"

export const checkManagementUser = (history, state, flag) => {
  return {
    type: CHECK_MANAGEMENT_USER,
    payload: {history, state, flag}
  }
}

export const setManagementUser = (props) => {
  return {
    type: SET_MANAGEMENT_USER,
    payload: props
  }
}

export const setDataManagementUser = (name, data) => {
  return {
    type: SET_DATE_MANAGEMENT_USER,
    payload: {name, data}
  }
}
export const updateDataManagementUser = (url, state, method, id) => {
  return {
    type: UPDATE_MANAGEMENT_USER,
    payload: {url, state, method, id}
  }
}

export const exportManagementUser = (state) => {
    return {
        type: EXPORT_MANAGEMENT_USER,
        payload: {state}
    }
}