import { CHECK_USER_COUNT, SET_USER_COUNT } from "./actionTypes"

export const checkCountUser = (history, state, flag) => {
  return {
    type: CHECK_USER_COUNT,
    payload: {history, state, flag}
  }
}

export const setCountUser = (props) => {
  return {
    type: SET_USER_COUNT,
    payload: props
  }
}