import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { setNewProductMaster, addNewProductMaster } from "../store/product/actions"
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { AvField, AvForm } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalEdit extends Component{
  constructor(props){
    super(props)
    this.state = {
      expRight: false,
      error_dlg: false,
      detail: "",
      tmpIcon: ""
    }
    this.toggle = this.toggle.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewProductMaster("toggleEdit", !this.props.toggleEdit)
  }

  async submitForm(){
    try {
      let flag = false

      if(!this.props.data.name){
        await this.setState({
          detail: "Form name is empty"
        })
        flag = true
      } else if(!this.props.data.code){
        await this.setState({
          detail: "Form code is empty"
        })
        flag = true
      } else if(!this.props.data.description){
        await this.setState({
          detail: "Form description is empty"
        })
        flag = true
      }

      if(flag){
        await this.setState({
          error_dlg: true
        })
      } else {
        await this.setState({
          expRight: true
        })
        let send = {...this.props.data}
        if(!send.icon){
          delete send.icon
        }else{
          var FormData = require('form-data');
          const fd = new FormData();
          fd.append("file", send.icon, send.icon.name)
  
          const config = {
            url: BASE_URL_UPLOAD,
            method: "POST",
            data: fd
          }
  
          const result = await axios(config)
          send.icon = result.data.imageurl
        }
        const bill = this.props.data.is_bill
        delete send.id
        delete send.is_bill
        send.is_bill_payment = bill

        send.currentPage = this.props.currentPage
        send.size = this.props.size
        await this.props.addNewProductMaster("/admin/product-master", send, "PUT", this.props.data.id)
        await this.props.setNewProductMaster("toggleEdit", !this.props.toggleEdit)
        await this.setState({
          expRight: false
        })
      }

      
    } catch (error) {
      console.log(error);
    }
  }

  render () {
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Product Master</ModalHeader>
          <AvForm>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="name" value={this.props.data.name} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Brand</Label>
              <Col sm="12">
                <Input required type="select" name="productBrandId" onChange={this.props.onChangeInput} id="exampleSelect2">
                  <option selected disabled>----- Select Brand -----</option>
                    {
                      this.props.arrBrand && this.props.arrBrand.map(el => {
                        if(el.id === this.props.data.productBrandId) return <option selected value={el.id}>{el.name}</option>
                        else return <option value={el.id}>{el.name}</option>
                    })
                  }
                </Input>
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Code</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="code" value={this.props.data.code} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Bill Payment</Label>
              <Col sm="12">
                <Input type="select" name="is_bill" onChange={this.props.onChangeInput} id="exampleSelect">
                {!this.props.data.is_bill ? (
                    <>
                    <option selected value="0">False</option>
                    <option value="1">True</option>
                    </>
                    
                  ): (
                    <>
                      <option value="0">False</option>
                      <option selected value="1">True</option> 
                    </>
                  )} 
                </Input>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <FormGroup>
                  <img src={this.props.data.tmpIcon} width="150px" height="150px" alt="icon"/>
                </FormGroup>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="icon" onChange={this.props.onChangeInput} />
              </Col>
              </FormGroup>
              <FormGroup>
            <Label sm="12">Denom</Label>
              <Col sm="12">
                <AvField type="text" placeholder="Input denom" name="denom" value={this.props.data.denom} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
              <FormGroup>
              <Label sm="12">Description</Label>
                <Col sm="12">
                  <AvField required type="textarea" placeholder="John Doe" name="description" value={this.props.data.description} onChange={this.props.onChangeInput} />
                </Col>
              </FormGroup>
              <Label sm="12">Status</Label>
              <Col sm="12">
                <Input type="select" name="status" onChange={this.props.onChangeInput} id="exampleSelect">
                {this.props.data.status === false ? (
                    <>
                    <option selected value="0">Inactive</option>
                    <option value="1">Active</option>
                    </>
                    
                  ): (
                    <>
                      <option value="0">Inactive</option>
                      <option selected value="1">Active</option> 
                    </>
                  )} 
                </Input>
              </Col>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
        <SweetAlert
          error
          title="Empty Form"
          onConfirm={() => this.setState({ error_dlg: false })}  >
          {this.state.detail}
        </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit, currentPage, size, arrBrand } = state.ProductMaster
  return { toggleEdit, currentPage, size, arrBrand };
}

export default withRouter(connect(mapStatetoProps, { setNewProductMaster, addNewProductMaster })(ModalEdit));