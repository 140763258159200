import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Container, Input, Badge } from "reactstrap";
import DataTable from 'react-data-table-component';
import moment from "moment"
import { activateAuthLayout } from "../../../store/actions";
import { setKomplainP2P, checkKomplainP2P, setDataKomplainP2P, updateDataKomplainP2P } from "../store/actions"
import { setDataP2P } from "../../P2P/store/actions"
import { convertToRupiah } from "../../../helpers/authUtils"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import ModalSee from "../../P2P/Pages/DetailModalP2P"
import SweetAlert from 'react-bootstrap-sweetalert';

class P2PKomplainTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            expRight: false,
            tooltiprefresh: false,
        }
        this.buttonSeeMU = this.buttonSeeMU.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.search = this.search.bind(this)
        this.refershPage = this.refershPage.bind(this)
        this.toolTipRefresh = this.toolTipRefresh.bind(this)
        this.updateStatusOrder = this.updateStatusOrder.bind(this)
    }

    toolTipRefresh = () => {
        this.setState({
            tooltiprefresh: !this.state.tooltiprefresh
        })
    }

    async componentDidMount() {
        this.props.activateAuthLayout()
        this.props.checkKomplainP2P(this.props.history, {
            id: this.state.id
        }, true)
    }

    async buttonSeeMU(data){
        await this.props.setDataP2P("dataP2P", data)
        await this.props.setDataP2P("toggleSee", !this.props.toggleSee)
    }

    async refershPage(){
        await this.setState({
            id: ""
        })
        this.search()
    }

    async onChangeInput(e){
        const {value, name} = e.target
        await this.setState({
            [name]: value
        })
    }

    async search(){
        await this.props.setDataKomplainP2P("expRight", true)
        await this.props.checkKomplainP2P(this.props.history,{
            id: this.state.id
        }, false)   
    }

    async updateStatusOrder(obj){
        // console.log("P2PKomplain")
        // console.log(obj);
        await this.props.updateDataKomplainP2P("/admin/p2p", {
            statusOrder: obj.status,
            buyer_id: obj.buyer_id
        }, "PUT", obj.id)
    }


    render() {
        const columns = [
            {
                name: "",
                cell: row => {
                    return(
                        <Badge className="rounded-pill mr-2" color="primary" style={{cursor: "pointer"}}
                            onClick={() => this.buttonSeeMU(row)}>
                                <i className="fa fa-eye"></i> Detail
                        </Badge>
                    )
                }
            },
            {
                name: "ID",
                selector: "_id",
                width: "10%"
            },
            {
                name: "Payment Status",
                selector: "payment_status",
                cell: row => {
                    return (
                        row.payment_status === "WAITING" ? <Badge className="rounded-pill" color="secondary">{row.payment_status}</Badge> :
                        row.payment_status === "SUCCESS" ?  <Badge className="rounded-pill" color="success">{row.payment_status}</Badge> :
                        row.payment_status === "FAILED" ?  <Badge className="rounded-pill" color="danger">{row.payment_status}</Badge> :
                        <Badge className="rounded-pill" color="dark">{row.payment_status}</Badge>
                    )
                }
            },
            {
                name: "Status Order",
                selector: "status",
                width: "150px",
                cell: row => {
                    return (
                        row.status === "BELUMBAYAR" ? <Badge className="rounded-pill" color="info">BELUMBAYAR</Badge> :
                        row.status === "MENUNGGU" ? <Badge className="rounded-pill" color="warning">MENUNGGU</Badge> :
                        row.status === "DITERIMA" ? <Badge className="rounded-pill" color="secondary">DITERIMA</Badge> :
                        row.status === "DIKIRIM" ?  <Badge className="rounded-pill" color="primary">DIKIRIM</Badge> :
                        row.status === "SUKSES" ?  <Badge className="rounded-pill" color="success">SUKSES</Badge> :
                        <Badge className="rounded-pill" color="danger">GAGAL</Badge>
                    )
                }
            },
            {
                name: "Seller Name",
                selector: "seller_name",
                width: "150px"
            },
            {
                name: "Product Name",
                selector: "products[0].name",
            },
            {
                name: "Product Price",
                selector: "products[0].price",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].price))
                    )
                },
                width: "130px"
            },
            {
                name: "Type",
                selector: "products[0].type",
            },
            {
                name: "Quantity",
                selector: "products[0].qty",
            },
            {
                name: "Discount Price",
                selector: "products[0].discount_price",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].discount_price))
                    )
                }
            },
            {
                name: "Sub Total",
                selector: "products[0].sub_total",
                width: "150px",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].sub_total))
                    )
                }
            },
            {
                name: "Total Price",
                selector: "total_price",
                width: "150px",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.total_price))
                    )
                }
            },
            {
                name: "ID Pelanggan",
                selector: "products[0].idpelanggan",
                width: "150px",
            },
            {
                name: "Created At",
                selector: "created_at",
                cell: row => {
                    return(
                        moment(row.created_at, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                    )
                },
                width: "200px"
            }
        ]

        return(
            <React.Fragment>
                <h2 className="card_title mb-3">P2P Komplain</h2>
                <Row>
                    <Col className="stretched_card">
                        <Card>
                        <CardBody>
                            <Container className="mt-2">
                                <Row>
                                    <Col xl="4" lg="4" md="8" sm="8">
                                    <div className="input-group">
                                        <Input className="mr-1" type="text" placeholder="ID Transaction" name="id" value={this.state.id} onChange={this.onChangeInput}/>
                                        <LaddaButton
                                        loading={this.props.expRight}
                                        data-style={EXPAND_RIGHT}
                                        className="btn btn-primary mr-2"
                                        onClick={this.search}
                                        >Search</LaddaButton>
                                    </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                            <DataTable
                                noHeader={true}
                                columns={columns}
                                data={this.props.komplains}
                                responsive={true}
                            />
                            </Container>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ModalSee 
                update={this.updateStatusOrder}/>

                {this.props.confirm ?
                    <SweetAlert
                        success
                        title="SUCCESS"
                        onConfirm={() => this.props.setDataP2P("confirm", false)}  >
                        {this.props.msgSuccess}
                    </SweetAlert> : null
                }
                {this.props.failedUpdate ?
                    <SweetAlert
                        error
                        title="FAILED"
                        onConfirm={() => this.props.setDataP2P("failedUpdate", false)}  >
                        {this.props.msgFailed}
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { komplains, expRight } = state.Komplain
    const { toggleSee, confirm, failedUpdate, msgSuccess, msgFailed } = state.P2P
    return { komplains, toggleSee, expRight, confirm, failedUpdate, msgSuccess, msgFailed };
  }

  export default withRouter(connect(mapStatetoProps, { activateAuthLayout, setKomplainP2P, checkKomplainP2P, setDataKomplainP2P, updateDataKomplainP2P, setDataP2P })(P2PKomplainTransaction));