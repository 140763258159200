import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col, Badge, Input } from 'reactstrap';
import { setDataManagementUser, updateDataManagementUser } from "../../store/actions"
import SweetAlert from 'react-bootstrap-sweetalert';

class ModalEditManagementUser extends Component{
  constructor(props){
    super(props)
    this.state = {
      confirm: false,
      confirmEmail: false,
      actionDesc: "",
      email: "", 
      wa: false
    }
    this.toggle = this.toggle.bind(this)
    this.submitAction = this.submitAction.bind(this)
    this.chatWA = this.chatWA.bind(this)
  }

  toggle(){
    this.props.setDataManagementUser("toggleEdit", !this.props.toggleEdit)
  }

  async submitAction(){
    const url = "/admin/user"
    if(this.state.actionDesc === "Activate"){
      await this.props.updateDataManagementUser(url, {status: 1}, "PUT", this.props.user.id)
    } else if(this.state.actionDesc === "Deactivate"){
      await this.props.updateDataManagementUser(url, {status: 0}, "PUT", this.props.user.id)
    } else if(this.state.actionDesc === "Reset Email"){
      await this.props.updateDataManagementUser(url, {email: this.state.email}, "PUT", this.props.user.id)
    } else if(this.state.actionDesc === "Reset Password"){
      await this.props.updateDataManagementUser(url, {password: true}, "PUT", this.props.user.id)
    } else if(this.state.actionDesc === "Send Email Confirmation"){
        await this.props.updateDataManagementUser(url, {sendEmailConf: true}, "PUT", this.props.user.id)
    } else if(this.state.actionDesc === "Email confirmation"){
        await this.props.updateDataManagementUser(url, {emailConf: true}, "PUT", this.props.user.id)
    }
    await this.toggle()
    await this.setState({
      confirm: false,
      confirmEmail: false,
      email: ""
    })
  }

  async chatWA(data) {
    if(data.phoneNumber){
      const tmpNumber = data.phoneNumber
      let number
      if(tmpNumber[0] === "6"){
        number = tmpNumber
      } else {
        number = "62"+tmpNumber.substring(1, tmpNumber.length)
      }
      window.open(`https://api.whatsapp.com/send?phone=${number}&text=`, "_blank")
      this.toggle()
    } else await this.setState({wa: true})
  }

  render(){
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleEdit} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} >User Management</ModalHeader>
            <ModalBody  style={{backgroundColor: "#f2f2f2"}}>
              {!this.props.user ? <p>Test1</p> :
                <Row>
                  <Col>
                    <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">User ID</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.id ? <b>Not Yet</b> : <b>{this.props.user.id}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Name</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.name ? <b>Not Yet</b> : <b>{this.props.user.name}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Email</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.email ? <b>Not Yet</b> : <b>{this.props.user.email}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Phone Number</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.phoneNumber ? <b>Not Yet</b> : <b>{this.props.user.phoneNumber}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Status</span>
                        <span className="traffic-sales-amount">
                          {this.props.user.status === true ? <Badge color="primary">ACTIVE</Badge>: <Badge color="danger">NON ACTIVE</Badge>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Is Seller</span>
                        <span className="traffic-sales-amount">
                        {this.props.user.isSeller === 1 ? <Badge color="primary">TRUE</Badge>: <Badge color="danger">FALSE</Badge>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Status</span>
                        <span className="traffic-sales-amount">
                        {this.props.user.storeStatus === 1 ? <Badge color="primary">BUKA</Badge>: <Badge color="danger">TUTUP</Badge>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Tagline</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.storeTagline ? <b>Not Yet</b> : <b>{this.props.user.storeTagline}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Description</span>
                        <span className="traffic-sales-amount">
                          {!this.props.user.storeDescription ? <b>Not Yet</b> : <b>{this.props.user.storeDescription}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Ratting</span>
                        <span className="traffic-sales-amount">
                        { <b>{this.props.user.storeRatting}</b>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Cover</span>
                        <span className="traffic-sales-amount">
                        {!this.props.user.storeCover ? <b>Not Yet</b> : <img src={this.props.user.storeCover} alt="logoStore" width="200px" height="80px"/>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                      <li className="traffic-sales-content list-group-item ">
                        <span className="traffic-sales-name">Store Logo</span>
                        <span className="traffic-sales-amount">
                        {!this.props.user.storeLogo ? <b>Not Yet</b> : <img src={this.props.user.storeLogo} alt="logoStore" width="200px" height="80px"/>}
                          <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                          </span>
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter>
            {this.props.type === "cs" ?
                <>
                  <Button color="primary" onClick={() => this.setState({confirm: true, actionDesc: "Activate"})}>Aktifkan</Button> 
                  <Button Button color="success" onClick={() => this.chatWA(this.props.user)}>Chat WA</Button>
                </>
                :
                <>
                    {!this.props.user ? "" : this.props.user.status === true ? 
                        <Button color="danger" onClick={() => this.setState({confirm: true, actionDesc: "Deactivate"})}>Non Aktifkan</Button> : 
                        <Button color="success" onClick={() => this.setState({confirm: true, actionDesc: "Activate"})}>Aktifkan</Button>
                    }
                    <Button color="warning" onClick={() => this.setState({confirmEmail: true, actionDesc: "Reset Email"})}>Update Email</Button>
                    <Button color="dark" onClick={() => this.setState({confirm: true, actionDesc: "Reset Password"})}>Reset Password</Button>
                </>
            }
            <Button color="dark" onClick={() => this.setState({confirm: true, actionDesc: "Send Email Confirmation"})}>Send Email Confirmation</Button>
            <Button color="dark" onClick={() => this.setState({confirm: true, actionDesc: "Email confirmation"})}>Email Confirmation</Button>
          </ModalFooter>
        </Modal>

        {this.state.confirm ? 
          <SweetAlert
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.submitAction()}
            onCancel={() => this.setState({ confirm: false, email: "" })} >
              Are you sure to <h5>{this.state.actionDesc}</h5> ID: <b>{this.props.user.id}</b> and Name: <b>{this.props.user.name}</b>
              {!this.state.email ? "" : <p>new email:<i><b> {this.state.email}</b></i></p>}
            </SweetAlert> : null
          }
        
        {this.state.confirmEmail ?
          <SweetAlert
            showCancel
            warning
            title={`${this.state.actionDesc} : ${!this.props.user.name ? "" : this.props.user.name}`}
            cancelBtnBsStyle="danger"
            confirmBtnBsStyle="success"
            onConfirm={() => this.setState({ confirmEmail: false, confirm: true })}
            onCancel={() => this.setState({ confirmEmail: false, email: "" })} >
            Input new email
            <Input type="email" className="form-control" value={this.state.email} placeholder="Input new email" onChange={(e) => this.setState({email: e.target.value})} />
          </SweetAlert> : null}

          {this.props.complete ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.props.setDataManagementUser("complete", false)}  >
            Success {this.state.actionDesc} user: {!this.props.user.name ? "" : this.props.user.name}
          </SweetAlert> : null}

          {this.props.errorFlag ?
            <SweetAlert
              error
              title="Failed"
              onConfirm={() => this.props.setDataManagementUser("errorFlag", false)}  >
              {this.props.msgError}
            </SweetAlert> : null
          }

        {this.state.wa ? 
          <SweetAlert
            error
            title="Failed"
            onConfirm={() => this.setState({wa: false})}  >
            Tidak ada nomer HP!
          </SweetAlert> : null
        }
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit, user, complete, errorFlag, msgError } = state.ManagementUser
  const { type } = state.Username
  return { toggleEdit, user, complete, errorFlag, msgError, type };
}

export default withRouter(connect(mapStatetoProps, { setDataManagementUser, updateDataManagementUser })(ModalEditManagementUser));
