import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { setNewCategory, addNewCategory } from "../store/category/actions"
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { AvField, AvForm } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalEdit extends Component {
  constructor(props){
    super(props)
    this.state = {
      expRight: false,
      error_dlg: false
    }
    this.toggle = this.toggle.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewCategory("toggleEdit", !this.props.toggleEdit)
  }

  async submitForm(){
    try {
      if(!this.props.data.name || !this.props.data.description){
        await this.setState({
          error_dlg: true
        })
      }else{
        await this.setState({
          expRight: true
        })
        let send = {...this.props.data}
        if(!send.icon){
          delete send.icon
        }else{
          var FormData = require('form-data');
          const fd = new FormData();
          fd.append("file", send.icon, send.icon.name)
  
          const config = {
            url: BASE_URL_UPLOAD,
            method: "POST",
            data: fd
          }
  
          const result = await axios(config)
          send.icon = result.data.imageurl
        }
        delete send.id
        console.log(send);
        await this.props.addNewCategory("/admin/category", send, "PUT", this.props.data.id)
        await this.props.setNewCategory("toggleEdit", !this.props.toggleEdit)
        await this.setState({
          expRight: false
        })
      }
      
    } catch (error) {
      console.log(error);
    }
    
  }

  render () {
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Category</ModalHeader>
          <AvForm>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="name" value={this.props.data.name} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <FormGroup>
                  <img src={this.props.data.tmpIcon} width="150px" height="150px" alt="icon"/>
                </FormGroup>
                <CustomInput type="file" accept={['.png', '.jpg']} id="exampleCustomFileBrowser" name="icon" onChange={this.props.onChangeInput} />
              </Col>
              </FormGroup>
              <FormGroup>
              <Label sm="12">Description</Label>
                <Col sm="12">
                  <AvField required type="text" placeholder="John Doe" name="description" value={this.props.data.description} onChange={this.props.onChangeInput} />
                </Col>
              </FormGroup>
              <FormGroup>
              <Label sm="12">Priority</Label>
                <Col sm="12">
                  <AvField required type="text" placeholder="John Doe" name="priority" value={this.props.data.priority === 0 ? "0" : this.props.data.priority} onChange={this.props.onChangeInput} />
                </Col>
              </FormGroup>
              <Label sm="12">status</Label>
              <Col sm="12">
                <Input type="select" name="status" onChange={this.props.onChangeInput} id="exampleSelect">
                  {this.props.data.status === false ? (
                    <>
                    <option selected value="0">Inactive</option>
                    <option value="1">Active</option>
                    </>
                    
                  ): (
                    <>
                      <option value="0">Inactive</option>
                      <option selected value="1">Active</option> 
                    </>
                  )} 
                </Input>
              </Col>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            Must input all form
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit } = state.Category
  return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewCategory, addNewCategory })(ModalEdit));