import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_POST_TAG } from './actionTypes';
import {  setPostTag } from './actions';
import { setUsername } from "../../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* postTagContent({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/post-tag", state)
        yield put(setPostTag(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setPostTag({
                rows: [],
                dataTags: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
        }
    }
}

export function* watchPostTag() {
    yield takeEvery(CHECK_POST_TAG, postTagContent)
}

function* postTagSaga() {
    yield all([fork(watchPostTag)]);
}

export default postTagSaga;