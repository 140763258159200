import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Alert, Button, Input } from 'reactstrap';
import ReactDOM from 'react-dom';
import { activateAuthLayout } from "../../../store/actions";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from "moment";
import { expiredToken } from "../../../store/auth/login/actions"
import { setDataMessage, checkUsernameMessage } from "../store/actions"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class MessagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            messages: [],
            message: "",
            myMessage: "",
            limit: 10,
            limitMessage: 0,
            fetch: 0,
        };
        this.showChat = this.showChat.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }
    
    componentDidMount() {
        let socket = this.props.socket
        this.props.activateAuthLayout();
        this.props.checkUsernameMessage(this.props.history)
        localStorage.removeItem("room");
        
        if(this.props.receiveNewMessage){
            this.props.setDataMessage("receiveNewMessage", !this.props.receiveNewMessage)
        }
        // Menampilkan jumlah room chat yang tersedia
        socket.emit("user", {
            id: localStorage.getItem("token")
        })
        
        // Membuat room di socket sesuai dengan ID (alternatif selain dikirim ke socket.id)
        if(!this.props.alreadyLogin){
            socket.emit("login", {
                token: localStorage.getItem("token")
            })
            this.props.setDataMessage("alreadyLogin", !this.props.alreadyLogin)
        }

        // Menampilkan list chat user yang akan menampilan pesan yang belum di baca lebih dulu
        socket.on("data user", async (msg) => {
            let output = msg.filter(el => el.unread !== 0)
            let tmp = []
            msg.forEach(el => {
                if(el.unread === 0){
                    tmp.push(el)
                }
            })
            tmp.forEach(el => {
                output.push(el)
            })
            this.setState({
                user: output
            })
        })

        // Menampilkan isi chat dari suatu room
        socket.on("showMessage", msg => {
            const { message, user } = msg
            let tmp = this.state.user
            let tmpUser = []
            for(let i = 0; i < tmp.length; i++){
                if(user.room === tmp[i].room){
                    tmpUser.push(user)
                } else {
                    tmpUser.push(tmp[i])
                }
            }
            this.setState({
                user: tmpUser,
                messages: message,
            })
        })

        // Menampilkan pesan baru yang dikirim, baik dari yang mengirim dan yang dikirimi
        socket.on("showNewMessage", async (msg) => {
            let payload = [...this.state.messages];
            payload.push(msg);
            this.setState({
                messages: payload,
            })
            if(msg.isMe === false && localStorage.getItem("room") === msg.idRoom){
                socket.emit("alreadyRead", msg)
            }
        })

        // Jika token yang dikirim ke server salah atau expired
        socket.on("somethinkWrong", async () => {
            await this.props.expiredToken("expired", true)
            this.props.history.push('/logout')
        })

        // Menambah notifikasi jumlah pesan yang belum di baca dan otomatis mensort pesan dengan unread lebih dari 1 ke posisi paling atas
        socket.on("plusOne", async (idRoom) => {
            if(idRoom !== localStorage.getItem("room")){
                let output = []
                let lastUsers = []
                let users = this.state.user
                users.forEach((el) => {
                    if(el.room === idRoom){
                        el.unread += 1
                        output.push(el)
                    }
                })
                users.forEach((el) => {
                    if(el.room !== idRoom){
                        lastUsers.push(el)
                    }
                })
                lastUsers.forEach((el) => {
                    output.push(el)
                })


                this.setState({
                    user: output
                })
            }
        })
    }

    componentWillUnmount(){
        // room yang tersimpan di localstorage akan otomatis terhapus jika pindah dari module message
        if(localStorage.getItem("room")){
            let socket = this.props.socket
            let data = localStorage.getItem("room");
            socket.emit("removeRoom", data)
            localStorage.removeItem("room");
        }
    }

    componentDidUpdate() {
        // Membuat posisi isi chat otomatis ke bawah
        if(this.state.fetch === 0){
            this.scrollToBottom();
        }
    }
    
    scrollToBottom() {
        // Atur posisi chat langsung ke yang paling bawah / baru
        this.el.scrollIntoView({ behavior: 'auto' });
    }

    handleUpdate(values){
        let socket = this.props.socket
        const { scrollTop } = values;
        // Menghandle emit ke server jika saat scroll chat sudah hampir di penghujung atas
        if(scrollTop > 0 && scrollTop <= 100){
            let data = this.state.limit + 10
            let limitMessage = this.state.messages.length

            // setState fetch agar saat mengambil data message yang lama tidak otomatis ke bawah lagi
            this.setState({
                limit: data,
                fetch: this.state.fetch + 1
            })

            const payload = {
                roomID: localStorage.getItem("room"),
                id: localStorage.getItem("token"),
                limit: this.state.limit
            }

            // mengecek apakah jumlah messaga dalam satu room sudah habis atau tidak
            if(this.state.limitMessage !== limitMessage){
                this.setState({
                    limitMessage: limitMessage
                })
                socket.emit("searchRoom", payload);
            }
        }
    }

    handleKeyPress(e){
        // Menghandle saat mengetik isi chat lalu menekan enter, pesan akan langsung terkirim atau ter-emit
        if (e.key === "Enter") {
            if(!localStorage.getItem("room")){
                this.setState({message: ""});
                ReactDOM.findDOMNode(this.myMessage).focus();
            } else {
                if(this.state.message.length > 0){
                    this.submitMessage();
                }
            }
        }
    }

    showChat(room){
        // Menampilkan isi chat dari suatu room chat
        let socket = this.props.socket
        this.setState({
            limit: 10,
            fetch: 0
        })
        const payload = {
            roomID: room,
            id: localStorage.getItem("token"),
            limit: this.state.limit
        };

        // Saat berpindah room chat ke room chat yang lain, akan menghapus room chat 
        // sebelumnya di localStorage dan akan digantikan dengan room chat yang 
        // sedang di buka saat itu
        if(localStorage.getItem("room")){
            payload.leaveRoom = localStorage.getItem("room")
        }
        localStorage.setItem("room", room);
        socket.emit("searchRoom", payload);
    }

    async onChangeInput(e){
        const {name, value} = e.target;

        this.setState({
            [name]: value
        });
    }

    async submitMessage(){
        // Function untuk mengirim atau meng-emit pesan ke server
        
        let socket = this.props.socket
        // Jika belum ada room di localStorage, maka pesan tidak akan di kirim
        if(!localStorage.getItem("room")){
            this.setState({message: ""});
            ReactDOM.findDOMNode(this.myMessage).focus();
        }else{
            if(this.state.message.length > 0){
                const idRoom = localStorage.getItem("room")
                const payload = {
                    id: localStorage.getItem("token"),
                    idRoom: idRoom,
                    message: this.state.message
                };
    
                socket.emit("saveMessage", payload);
    
                
                // Memfilter ulang posisi room chat ke posisi paling atas pada tampilan user rooms
                let lastUsers = []
                let users = this.state.user
                let output = users.filter(el => el.room === idRoom)
                users.forEach((el) => {
                    if(el.room !== idRoom){
                        lastUsers.push(el)
                    }
                })
                lastUsers.forEach((el) => {
                    output.push(el)
                })
    
    
                this.setState({
                    user: output
                })
        
                
                this.setState({message: ""});
                ReactDOM.findDOMNode(this.myMessage).focus();
                this.scrollToBottom();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <NotificationMessage />
                <Row>
                    <Col xl="3" lg="3" md="4" sm="4">
                        <Card>
                            <CardBody>
                                <Alert className="text-center" color="primary">Message</Alert>
                                {/* START SHOW USER #1 */}
                                <div className="menu-inner">
                                    <nav>
                                        <ul className="metismenu border">
                                        {this.state.user.length === 0 ? <p>&nbsp;</p> : this.state.user.map(el => 
                                            <li key={el._id} className={localStorage.getItem("room") === el.room ? "mm-active" : ""} onClick={() => this.showChat(el.room)}>
                                            <Link to="#">
                                                {el.name.length > 15 ? `${el.name.substr(0, 17)}...` : el.name}
                                                {el.unread ? <span className="badge badge-danger pull-right rounded-circle">{el.unread}</span> : ""}
                                            </Link>
                                            </li>
                                        )}
                                        </ul>
                                    </nav>
                                </div>
                                {/* END SHOW USER #1 */}

                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl="9" lg="9" md="8" sm="8" >
                        <Scrollbars 
                            style={{ height: 500 }}
                            onUpdate={this.handleUpdate}
                        >
                            <Card >
                                <CardBody >
                                    <Row className="mt-2">
                                        <Col xl="12" lg="12" md="12" sm="12">
                                            {this.state.messages.length === 0 ? <p>&nbsp;</p> : this.state.messages.map((el) => 
                                                el.isMe === true ? 
                                                <>
                                                <Row className="mt-2" key={el._id}>
                                                    <Col xl={{ offset: 6, size: 6,  }} 
                                                        lg={{ offset: 6, size: 6, }} 
                                                        md={{ offset: 6, size: 6, }} 
                                                        sm={{ offset: 6, size: 6, }}>
                                                        <Card body className="bg bg-primary mb-2">
                                                            <span className="text-white"><b>{el.name}</b></span>
                                                            {el.imageUrl ? <a href={el.imageUrl} target="_blank" rel="noreferrer noopener" className="text-white">
                                                                <img src={el.imageUrl} alt={el.imageUrl} />
                                                            </a>: ""}
                                                            {el.message ? <span>{el.message}</span> : ""}
                                                            <span className="card-text"><small>{moment.utc(el.createdAt).local().fromNow()}</small></span>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                </> : 
                                                <Row className="mt-2" key={el._id}>
                                                    <Col xl="6" lg="6" md="6" sm="6">
                                                        <Card body className="bg bg-light mb-2">
                                                            <span className="text-primary"><b>{el.name}</b></span>
                                                            {el.imageUrl ? <a href={el.imageUrl} target="_blank" rel="noreferrer noopener" className="text-white">
                                                                <img src={el.imageUrl} alt={el.imageUrl} />
                                                            </a>: ""}
                                                            {el.message ? <span>{el.message}</span> : ""}
                                                            <span className="card-text"><small className="text-muted">{moment.utc(el.createdAt).local().fromNow()}</small></span>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                            <div ref={el => { this.el = el }} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Scrollbars>
                    
                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" className="mt-3">
                                <div className="input-group">
                                    {localStorage.getItem("room") ? 
                                        <>
                                        <Input type="textarea" placeholder="Message" onKeyPress={this.handleKeyPress} className="form-control rounded-0 border-0 py-3 bg-light h-75" name="message" value={this.state.message} onChange={this.onChangeInput} autoFocus={true} ref={c => (this.myMessage = c)}/>
                                        <div className="input-group-append">
                                            <Button id="button-addon2" onClick={this.submitMessage} type="submit" color="primary"> <i className="fa fa-paper-plane"></i></Button>
                                        </div>
                                        </>
                                        :  ""}
                                </div>
                            </Col>
                        </Row>                        
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { socket, notif, option, receiveNewMessage, alreadyLogin } = state.Message
    return { socket, notif, option, receiveNewMessage, alreadyLogin }
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, expiredToken, setDataMessage, checkUsernameMessage })(MessagePage));