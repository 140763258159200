import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { Row, Col, Card, CardBody, Container, Button, Input, Badge } from "reactstrap";
import DataTable from 'react-data-table-component';
import { convertToRupiah } from "../../../helpers/authUtils"
import moment from "moment"
import { checkMutasiBank, setDataMutasiBank, exportMutasiBank } from "../store/actions"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class MutasiBankPages extends Component{
  constructor(props){
    const today = moment().format("YYYY-MM-DD")
    super(props)
    this.state = {
      from: today,
      to: today,
      errorExp: false
    }
    this.timeFormat = this.timeFormat.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.refresh = this.refresh.bind(this)
    this.exportFile = this.exportFile.bind(this)
  }

  componentDidMount(){
    this.props.activateAuthLayout()
    this.props.checkMutasiBank(this.props.history, {from: this.state.from, to: this.state.to}, true)
  }

  timeFormat(data){
    if(!data){
      return
    }else{
      let date = new Date(data)
      let month = null
      if(date.getUTCMonth() < 10){
        month = `0${date.getUTCMonth() + 1}`
      }else{
        month = date.getUTCMonth() + 1
      }
      return moment().format(`${date.getFullYear()}/${month}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds()}`)
    }
  }

  async refresh(){
    const today = moment().format("YYYY-MM-DD")
    await this.setState({
      from: today,
      to: today
    })
    let props = this.props
    await this.props.setDataMutasiBank("size", 10)
    await this.props.setDataMutasiBank("page", 1)
    await this.props.checkMutasiBank(this.props.history, {
      page: props.page,
      size: props.size,
      from: this.state.from,
      to: this.state.to,
    }, false)
  }

  async onChangeInput(e){
    const {value, name} = e.target
    let props = this.props
    await this.setState({
      [name]: value
    })

    if(name === "from" || name === "to"){
      await this.props.setDataMutasiBank("size", 10)
      await this.props.setDataMutasiBank("page", 1)
      await this.props.checkMutasiBank(this.props.history, {
        page: props.page,
        size: props.size,
        from: this.state.from,
        to: this.state.to,
      }, false)
    }
  }

  async changeRowsPerPage(size){
    await this.props.setDataMutasiBank("size", size)
    let props = this.props
    await this.props.checkMutasiBank(this.props.history, {
      page: props.currentPage,
      size: size,
      from: this.state.from,
      to: this.state.to,
    }, false)
  }

  async onChangePage(page){
    await this.props.setDataMutasiBank("currentPage", page)
    let props = this.props
    await this.props.checkMutasiBank(this.props.history, {
      page: page,
      size: props.size,
      from: this.state.from,
      to: this.state.to,
    }, false)
  }

  async exportFile(){
    if(this.props.mutasi.length === 0){
      this.setState({errorExp: true})
    } else {
      await this.props.exportMutasiBank({
        from: this.state.from,
        to: this.state.to,
      })
    }
  }

  render(){
    const columns = [
      {
          name: "TrxID",
          selector: "id",
          sortable: true,
          width: "100px"
      },
      {
        name: "Name",
        selector: "name_user"
      },
      {
          name: "Keterangan",
          selector: "description",
      },
      {
          name: "Mutasi",
          selector: "amount",
          sortable: true,
          cell: row => {
            return(
              row.source === "CASHIN" ? 
              <span className="text-success"><b>{convertToRupiah(row.amount)}</b></span> : 
              row.source === "CASHOUT" ?
              <span className="text-danger"><b>{convertToRupiah(row.amount)}</b></span> :
              <span className="text-muted"><b>{convertToRupiah(row.amount)}</b></span>
            )
          }
      },
      {
        name: "Type",
        selector: "type",
        center: true,
        cell: ({type}) => {
            return (
                type === "CREDIT" ? <Badge color="primary">CREDIT</Badge> : <Badge color="danger">DEBIT</Badge>
            )
        }
      },
      {
        name: "Waktu Trx",
        selector: "created_at",
        sortable: true,
        cell: row => {
          return (
            moment(row.created_at).format('YYYY/MM/DD HH:mm:ss')
          )
        }
      },
      {
          name: "Source",
          selector: "source",
          sortable: true,
      },
    ]

    return(
      <React.Fragment>
        <NotificationMessage />
        <h2 className="card_title mb-3">Mutasi Bank</h2>

        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container className="mt-2">
                  <Row>
                    <Col xl="4" lg="4" md="6" sm="12">
                    <div className="input-group">
                      <p style={{margin: "auto 0"}} className="mr-1">From</p>
                      <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                      <p style={{margin: "auto 0"}} className="ml-1 mr-1">To</p>
                      <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                    </div>
                    </Col>
                    <Col xl="4" lg="4" md="6" sm="12">
                    <div className="input-group">
                      <Button color="primary" onClick={this.refresh} className="mr-2"><i className="fa fa-refresh"></i> REFRESH</Button>
                      <Button color="primary" onClick={this.exportFile}><i className="fa fa-download"></i> DOWNLOAD</Button>
                    </div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    noHeader={true}
                    columns={columns}
                    data={this.props.mutasi}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.errorExp ?
          <SweetAlert
            error
            title="FAILED"
            onConfirm={() => this.setState({errorExp: false})}  >
            Don't have data to download
          </SweetAlert> : null
        }
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { mutasi, currentPage, totalItems, size } = state.MutasiBank
  return { mutasi, currentPage, totalItems, size };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkMutasiBank, setDataMutasiBank, exportMutasiBank })(MutasiBankPages));