import { CHECK_POST, SET_POST, SET_DATA_POST, CUD_POST } from "./actionTypes"

export const checkPost = (history, state, flag) => {
  return {
    type: CHECK_POST,
    payload: {history, state, flag}
  }
}

export const setPost = (props) => {
  return {
    type: SET_POST,
    payload: props
  }
}

export const setDataPost = (name, data) => {
  return {
    type: SET_DATA_POST,
    payload: {name, data}
  }
}

export const cudPost = (url, state, method, id) => {
  return {
    type: CUD_POST,
    payload: {url, state, method, id}
  }
}