import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BiImport } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { activateAuthLayout } from "../../../store/actions";
import { checkProductMaster, setNewProductMaster, addNewProductMaster } from "../store/product/actions"
import { checkBrand } from "../../Brand/store/brand/actions"
import DataTable from 'react-data-table-component';
import { Badge, Container, Button, Row, Col, Card, CardBody, Label, Input } from "reactstrap";
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import ModalImport from "./ModalImport"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class ProductPage extends Component{
  constructor(props){
    super(props)
    this.state = { 
      id: "",
      name: "",
      nameProduct: "",
      icon: "",
      tmpIcon: "",
      code: "",
      description: "",
      productBrandId: "",
      is_bill: "",
      status: "",
      dataDelete: null,
      swal: false, success_dlg: false, dynamic_title: "", 
      dynamic_description: ""
    }
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    this.onShowModalImport = this.onShowModalImport.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
    this.buttonEdit = this.buttonEdit.bind(this)
    this.searchName = this.searchName.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  async componentDidMount(){
    let props = this.props
    await this.props.activateAuthLayout()
    await this.props.checkProductMaster(this.props.history, {
      page: props.currentPage,
      size: props.size,
      status: props.status
    }, true)

    if(this.props.arrCategory.length === 0){
      await this.props.checkBrand(this.props.history, {
        page: props.currentPage,
        size: props.size,
        status: props.status
      }, true)
    }
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  handleKeyPress(e){
    if (e.key === "Enter") {
        this.searchName()
    }
  }

  async searchName(){
    await this.props.setNewProductMaster("size", 10)
    await this.props.setNewProductMaster("page", 1)
    let props = this.props
    await this.props.checkProductMaster(this.props.history, {
        page: props.page,
        size: props.size,
        search: this.state.nameProduct
    }, false)
  }



  //menampilkan data sebanyak 10/20/50/100
  async changeRowsPerPage(size){
    await this.props.setNewProductMaster("size", size)
    let props = this.props
    await this.props.checkProductMaster(this.props.history, {
      page: props.currentPage,
      size: size,
      search: this.state.nameProduct
    }, false)
  }

  async onChangePage(page){
    await this.props.setNewProductMaster("page", page)
    let props = this.props
    await this.props.checkProductMaster(this.props.history, {
      page: page,
      size: props.size,
      search: this.state.nameProduct
    }, false)
  }

  async onShowModalAdd(){
    await this.props.setNewProductMaster("toggleAdd", !this.props.toggleAdd)
  }

  async onShowModalImport(){
    await this.props.setNewProductMaster("toggleImport", !this.props.toggleImport)
  }

  async buttonEdit(e, data){
    this.setState({
      id: data.id,
      name: data.name,
      description: data.description,
      code: data.code,
      status: data.status,
      tmpIcon: data.icon,
      denom: data.denom ? data.denom : "",
      is_bill: data.is_bill_payment ? data.is_bill_payment : 0,
      productBrandId: data.product_digital_brand_id
    })
    await this.props.setNewProductMaster("toggleEdit", !this.props.toggleEdit)
  }

  buttonDelete(data){
    this.setState({ swal: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'Your file has been deleted.' })
    this.props.addNewProductMaster("/admin/product-master", {}, "DELETE", data.id)
  }

  render() {
    const columns = [
      {
        name: "Action",
        center: true,
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(e, row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                swal: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "80px"
      },
      {
        name: "Code",
        selector: "code",
        width: "20%"
      },
      {
        name: "Name",
        selector: "name",
        width: "200px"
      },
      {
        name: "Icon",
        selector: "icon",
        width: "70px",
        cell: row => {
          return <img src={row.icon} alt={row.icon} />
        }
      },
      {
        name: "Description",
        selector: "description",
        width: "200px"
      },

      {
        name: "Bill Payment",
        selector: "is_bill_payment",
        center: true,
        cell: ({is_bill_payment: bill}) => {
            return (
                !bill ? <Badge>False</Badge> :
                <Badge color="primary">True</Badge> 
            )
        }
      },
      {
        name: "Brand",
        selector: "brand.name",
        width: "100px"
      },
      {
        name: "Denom",
        selector: "denom",
        center: true,
        width: "150px",
        cell: ({denom}) => {
            return (
                denom ? denom : ""
            )
        }
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        cell: row => {
          return (
            row.status === 0 || row.status === false ? <Badge color="danger">Inactive</Badge> :
            <Badge color="success" style={{color: "black"}}>Active</Badge> 
          )
        },
        width: "100px"
      },
    ]

    return(
      <React.Fragment>
        <NotificationMessage />
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i>Add Product</Button>
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalImport}><BiImport /> Import Product</Button>
                    </Col>
                    <Col xl={{ offset: 2, size: 5 }} 
                        lg={{ offset: 1, size: 5 }} 
                        md="5" 
                        sm="12">
                          <div className="input-group">
                            <Label className="mt-2 mr-2">Search:</Label>
                            <Input className="mr-2" type="text" onKeyPress={this.handleKeyPress} name="nameProduct" placeholder="search name or code" value={this.state.nameProduct} onChange={(e) => this.setState({nameProduct: e.target.value})}/>
                            <Button color="primary" onClick={this.searchName}><FaSearch/>Search</Button>
                          </div>
                    </Col>
                  </Row>
                </Container>
                {/* <Container> */}
                  <DataTable
                    title="Product Master"
                    columns={columns}
                    data={this.props.products}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                {/* </Container> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAdd />
        <ModalEdit 
          onChangeInput={this.onChangeInput} 
          data={{id: this.state.id, name: this.state.name, description: this.state.description, icon: this.state.icon, code: this.state.code, status: this.state.status, tmpIcon: this.state.tmpIcon, denom: this.state.denom, is_bill: this.state.is_bill, productBrandId: this.state.productBrandId}}
        />
        <ModalImport />

        {this.state.swal ? 
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => this.buttonDelete(this.state.dataDelete)}
          onCancel={() => this.setState({ swal: false, error_dlg: true, dynamic_title: 'Cancelled', dynamic_description: 'Your imaginary file is safe :)' })} >
          You won't be able to revert this!
          </SweetAlert> : null
        }

        {this.state.success_dlg ?
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ success_dlg: false })}  >
            {this.state.dynamic_description}
            </SweetAlert> : null}

        {this.state.error_dlg ?
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.dynamic_description}
          </SweetAlert> : null}
        
        {this.props.swSuccess ? 
        <SweetAlert
        success
        title={this.props.desc}
        onConfirm={() => this.props.setNewProductMaster("swSuccess", false)}>
          <ul style={{listStyleType:"disc"}}>
          {this.props.message.length > 0 ? this.props.message.map(el => { return (<li>{el}</li>)}) : ""}
          </ul>

      </SweetAlert> : null}


      </React.Fragment>
    )
  }

}

const mapStatetoProps = state => {
  const { currentPage, totalItems, size, status, toggleAdd, toggleImport, products, swSuccess, desc, message } = state.ProductMaster
  const { arrCategory } = state.Brand
  return { currentPage, totalItems, size, status, toggleAdd, toggleImport, products, arrCategory, swSuccess, desc, message };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkProductMaster, setNewProductMaster, addNewProductMaster, checkBrand })(ProductPage));
