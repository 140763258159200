import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_CASH_OUT_P2P } from '../cashout/actionTypes';
import {  setCashOutP2P, setDataCashOutP2P } from '../cashout/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updateCashOutP2P({payload: {url, state, method, id}}) {
    try {
        const { name, status, method: methodBank, from, to, tmpStatus, remark } = state
        let sendMethod = methodBank === "-1" ? "" : methodBank
        const responseStatus = yield call(hitAPIWasmarket, url, {status, remark}, method, id)
        const response = yield call(callGetCategory, "/admin/cashout/p2p", {page: 1, size: 10, name, method: sendMethod, from, to, status: tmpStatus === "-1" ? "" : tmpStatus })
        yield put(setCashOutP2P(response))
        yield put(setDataCashOutP2P("confirm", true))
        yield put(setDataCashOutP2P("msgSuccess", responseStatus.data))
    } catch (error) {
    }
}

export function* watchUpdateCashOutP2P() {
    yield takeEvery(UPDATE_CASH_OUT_P2P, updateCashOutP2P)
}

function* updateCashOutP2PSaga() {
    yield all([fork(watchUpdateCashOutP2P)]);
}

export default updateCashOutP2PSaga;