//=====================================
//            All Componenets
//=====================================

// ------ Auth Pages -------
import Pageslogin from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";

// ------ Test ------
import Category from "./modules/Category/Pages/Category"
import Brand from "./modules/Brand/Pages/Brand"
import Banner from "./modules/Banner/Pages/Banner"
import ProductMaster from "./modules/ProductMaster/Pages/Product"
import ManagementUser from "./modules/ManagementUser/Pages/ManagementUser"
import ChangePassword from "./modules/ChangePassword/Pages/ChangePassword"
import CashOut from "./modules/CashOut/Pages/CashOut"
import CashIn from "./modules/CashIn/Pages/CashIn"
import MonitoringBalance from "./modules/MonitoringBalance/Pages/MonitoringBalance"
import MutasiBank from "./modules/MutasiBank/Pages/MutasiBank"
import Transaction from "./modules/Transaction/Pages/Transaction"
import Message from "./modules/Messages/Pages/Message"
import Badge from "./modules/Badges/Pages/Badge"
import StoreBadge from "./modules/StoreBadge/Pages/StoreBadge"
import Setting from "./modules/Setting/Pages/Setting"
import Post from "./modules/Content/Post/Pages/Post"
import Tag from "./modules/Content/Tag/Pages/Tag"
import PostTag from "./modules/Content/PostTag/Pages/PostTag"
import CountUser from "./modules/CountUsers/Pages/CountUserPage"
import CashOutP2P from "./modules/CashOutP2P/Pages/CashOut"
import P2P from "./modules/P2P/Pages/P2PTransaction"
import Komplain from "./modules/P2PKomplain/Pages/P2PKomplainPage"
import LogMonit from "./modules/MonitLogs/Pages/MonitLogsPage"
import CategoryP2P from "./modules/CategoryP2P//Pages/Category"
import EtalaseP2P from "./modules/EtalaseP2P/Pages/Etalase"
import BrandP2P from "./modules/BrandP2P/Pages/Brand"

const routes = [
  //=====================================
  //            Public Routes
  //=====================================
  { path: "/login", component: Pageslogin, ispublic: true },
  { path: "/logout", component: Logout, ispublic: true },


  //=====================================
  //            change passwprd
  //=====================================
  {path: "/change-password", component: ChangePassword},
  //=====================================
  //            wasmarket
  //=====================================
  {path: "/brand", component: Brand},
  {path: "/banner", component: Banner},
  {path: "/category", component: Category},
  {path: "/product", component: ProductMaster},
  {path: "/manage-user", component: ManagementUser},
  {path: "/cashout", component: CashOut},
  {path: "/cashin", component: CashIn},
  {path: "/monitoring", component: MonitoringBalance},
  {path: "/mutasi-bank", component: MutasiBank},
  {path: "/transaction", component: Transaction},
  {path: "/message", component: Message},
  {path: "/badge", component: Badge},
  {path: "/store-badge", component: StoreBadge},
  {path: "/setting", component: Setting},

  //=====================================
  //            Content
  //=====================================
  {path: "/post", component: Post},
  {path: "/tag", component: Tag},
  {path: "/post-tag", component: PostTag},
  
  //=====================================
  //            P2P
  //=====================================
    {path: "/p2p", component: P2P},
    {path: "/cashout-p2p", component: CashOutP2P},
    {path: "/komplain-p2p", component: Komplain},
    {path: "/log-monit", component: LogMonit},
    {path: "/category-p2p", component: CategoryP2P},
    {path: "/etalase-p2p", component: EtalaseP2P},
    {path: "/brand-p2p", component: BrandP2P},
  
  //=====================================
  //            Back Office
  //=====================================
  {path: "/count-user", component: CountUser},
  //=====================================

  { path: "/", component: Pageslogin, ispublic: true },
  //=====================================
];

export default routes;
