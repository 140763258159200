import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_BANNER, } from './actionTypes';
import {  setBanner } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* banner({payload: {history, state, flag}}) {
    try {
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
            if(responseUsername.type === "cs" || responseUsername.type === "Super"){
                history.push("/banner")
            } else {
                history.push("/manage-user")
            }
        }
        const response = yield call(callGetCategory, "/admin/banner", state)
        yield put(setBanner(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setBanner({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0
            }))
        }
    }
}

export function* watchUserBanner() {
    yield takeEvery(CHECK_BANNER, banner)
}

function* bannerSaga() {
    yield all([fork(watchUserBanner)]);
}

export default bannerSaga;