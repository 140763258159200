import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { setNewCategory, addNewCategory } from "../store/category/actions"
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalAddCategory extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      description: "",
      status: "0",
      priority: "",
      expRight: false,
      error_dlg: false
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewCategory("toggleAdd", !this.props.toggleAdd)
    this.setState({
      name: "",
      icon: null,
      description: ""
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0]
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      if(!this.state.icon || !this.state.name || !this.state.description || !this.state.priority){
        await this.setState({
          error_dlg: true
        })
      }else{
        await this.setState({
          expRight: true
        })
        var FormData = require('form-data');
        const fd = new FormData();
        fd.append("file", this.state.icon, this.state.icon.name)
  
        const config = {
          url: BASE_URL_UPLOAD,
          method: "POST",
          data: fd
        }
  
        const result = await axios(config)
        let obj = {
          name: this.state.name,
          icon: result.data.imageurl,
          description: this.state.description,
          status: this.state.status,
          priority: this.state.priority
        }
        await this.props.addNewCategory("/admin/category", obj, "POST")
        await this.setState({
          name: "",
          icon: "",
          description: "",
          status: "0",
          priority: ""
        })
        await this.props.setNewCategory("toggleAdd", !this.props.toggleAdd)
        await this.setState({
          expRight: false
        })
      }
        
    } catch (error) {
      console.log(error);
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Category</ModalHeader>
          <AvForm
            onInvalidSubmit={this.onInvalidSubmit}
            >
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput} required/>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter description" name="description" value={this.state.description} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Priority</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter priority" name="priority" value={this.state.priority} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Status</Label>
              <Col sm="12">
              <Input type="select" name="status" onChange={this.onChangeInput} id="exampleSelect">
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </Input>
              </Col>
            </FormGroup>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            Must input all form
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd } = state.Category
  return { toggleAdd };
}

export default withRouter(connect(mapStatetoProps, { setNewCategory, addNewCategory })(ModalAddCategory))