import { CHECK_BRAND, SET_CHANGE_BRAND, SET_BRAND } from "./actionTypes"

const initialState = {
  brands: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, toggleAdd: false,
  toggleEdit: false, status: "", arrCategory: []
}

const brand = (state = initialState, action) => {
  switch(action.type){
    case CHECK_BRAND:
      state = {
        ...state
      }
      break;
    case SET_BRAND:
      state = {
        ...state,
        brands: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_CHANGE_BRAND:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default brand