import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_KOMPLAIN_P2P, } from './actionTypes';
import { setKomplainP2P, setDataKomplainP2P } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* komplain({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/p2p/komplain", state)
        yield put(setKomplainP2P(response))
        yield put(setDataKomplainP2P("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setKomplainP2P({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
            yield put(setDataKomplainP2P("expRight", false))
        }
    }
}

export function* watchKomplainP2P() {
    yield takeEvery(CHECK_KOMPLAIN_P2P, komplain)
}

function* p2pKomplainSaga() {
    yield all([fork(watchKomplainP2P)]);
}

export default p2pKomplainSaga;