import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CUD_POST_TAG } from '../postTag/actionTypes';
import { setPostTag, setDataPostTag } from '../postTag/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* contentCUDTag({payload: {url, state, method, id}}) {
    try {
        const responseStatus = yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/post-tag", {page: 1, size: 10})
        yield put(setPostTag(response))
        yield put(setDataPostTag("confirm", true))
        yield put(setDataPostTag("msgSuccess", responseStatus.data))
    } catch (error) {
      console.log(error);
    }
}

export function* watchContentPostTag() {
    yield takeEvery(CUD_POST_TAG, contentCUDTag)
}

function* cudPostTagSaga() {
    yield all([fork(watchContentPostTag)]);
}

export default cudPostTagSaga;