import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHANGE_PASSWORD } from './actionTypes';
import {  setNotif } from './actions';
//============ Authentication Functions =============*
import { hitAPIWasmarket } from '../../../helpers/authUtils';

//============ Dispatch Redux Actions directly =============*
function* changePassword({payload: { url, state, method }}) {
    try {
        const responsePassword = yield call(hitAPIWasmarket, url, state, method)
        yield put(setNotif("desc", responsePassword.data))
        yield put(setNotif("notifSuccess", true))
    } catch (error) {
      console.log(error);
      yield put(setNotif("desc", error.data))
      yield put(setNotif("notifFailed", true))
    }
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* changePasswordSaga() {
    yield all([fork(watchChangePassword)]);
}

export default changePasswordSaga;