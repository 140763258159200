import { CHECK_MONITORING, SET_MONITORING, SET_DATA_MONITORING, CREATE_TRANSFER, CHECK_BA_TRANSFER, SET_BA_TRANSFER, EXPORT_BA_TRANSFER } from "./actionTypes"

export const checkMonitoring = (history, state, flag) => {
  return {
    type: CHECK_MONITORING,
    payload: {history, state, flag}
  }
}

export const setMonitoring = (props) => {
  return {
    type: SET_MONITORING,
    payload: props
  }
}

export const setDataMonitoring = (name, data) => {
  return {
    type: SET_DATA_MONITORING,
    payload: {name, data}
  }
}

export const createTransferMonitorBalance = (url, state, method, id) => {
  return {
    type: CREATE_TRANSFER,
    payload: {url, state, method, id}
  }
}

export const checkBATransfer = (state) => {
  return {
    type: CHECK_BA_TRANSFER,
    payload: {state}
  }
}

export const setBATransfer = (props) => {
  return {
    type: SET_BA_TRANSFER,
    payload: props
  }
}

export const exportBATransfer = (state) => {
  return {
    type: EXPORT_BA_TRANSFER,
    payload: {state}
  }
}