import { CHECK_BADGE, SET_BADGE, SET_CHANGE_BADGE } from "./actionTypes"

const initialState = {
  badges: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, toggleAdd: false,
  toggleEdit: false,
}

const badge = (state = initialState, action) =>{
  switch(action.type){
    case CHECK_BADGE:
      state = {
        ...state
      }
      break;
    case SET_BADGE:
      state = {
        ...state,
        badges: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_CHANGE_BADGE:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default badge