import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_SETTING } from '../setting/actionTypes';
import { setSetting } from '../setting/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* settingCUD({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/setting", {})
        yield put(setSetting(response))
    } catch (error) {
      console.log(error);
    }
}

export function* watchCUDSetting() {
    yield takeEvery(ADD_SETTING, settingCUD)
}

function* cudSettingSaga() {
    yield all([fork(watchCUDSetting)]);
}

export default cudSettingSaga;