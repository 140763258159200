import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateNonAuthLayout } from '../../store/actions';

class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

        this.props.activateNonAuthLayout();

        //============ Empty Room ID In Server =============*
        let socket = this.props.socket
        socket.emit("logout", localStorage.getItem("token"))
        
        //============ Empty Local Storage =============*
        localStorage.removeItem('token');
        this.props.history.push('/login');
    }

    render() {
        return (
            <React.Fragment>
                <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { socket } = state.Message
    return { socket };
}

export default withRouter(connect(mapStatetoProps, { activateNonAuthLayout })(Logout));

