import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_CATEGORY } from '../category/actionTypes';
import {  setCategory } from '../category/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/category", {page: 1, size: 10})
        yield put(setCategory(response))
    } catch (error) {
    }
}

export function* watchUserCategory() {
    yield takeEvery(ADD_CATEGORY, category)
}

function* addCategorySaga() {
    yield all([fork(watchUserCategory)]);
}

export default addCategorySaga;