import { CHECK_PRODUCT_MASTER, SET_PRODUCT_MASTER, SET_CHANGE_PRODUCT_MASTER, ADD_PRODUCT_MASTER, IMPORT_PRODUCT_MASTER } from "./actionTypes"

export const checkProductMaster = (history, state, flag) => {
  return {
    type: CHECK_PRODUCT_MASTER,
    payload: {history, state, flag}
  }
}

export const setProductMaster = (props) => {
  return {
    type: SET_PRODUCT_MASTER,
    payload: props
  }
}

export const setNewProductMaster = (name, data) => {
  return {
    type: SET_CHANGE_PRODUCT_MASTER,
    payload: {name, data}
  }
}

export const addNewProductMaster = (url, state, method, id) => {
  return {
    type: ADD_PRODUCT_MASTER,
    payload: {url, state, method, id}
  }
}

export const importNewProduct = (url, state, method, id) => {
  return {
    type: IMPORT_PRODUCT_MASTER,
    payload: {url, state, method, id}
  }
}