import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notification from "react-web-notification"
import { setDataMessage } from "../../Messages/store/actions"
import Ringtone from "../../../assets/ringtone/nokia_tune.mp3"

class NotificationPages extends Component{
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    let socket = this.props.socket
    if(!("Notification" in window)) {
      console.log("Not supported");
    } else if(window.Notification.permission === "denied" || window.Notification.permission === "default") {
      window.Notification.requestPermission()
    }
    
    if(!this.props.alreadyLogin){
      socket.emit("login", {
        token: localStorage.getItem("token")
      })
      this.props.setDataMessage("alreadyLogin", !this.props.alreadyLogin)
    }

    socket.on("showNotif", async (msg) => {
      const {unread, send} = msg
    //   if(!localStorage.getItem("room")){
        await this.props.setDataMessage("unreadMsg", unread)
        await this.props.setDataMessage("name", send)
        // if(this.props.notif === true){
        //   await this.props.setDataMessage("notif", false)
        //   await this.props.setDataMessage("notif", true)
        // } else {
        // }
        await this.props.setDataMessage("notif", true)
        if(!this.props.receiveNewMessage){
          await this.props.setDataMessage("receiveNewMessage", !this.props.receiveNewMessage)
        }
    //   }
    })
  }

  handleNotificationOnShow(e, tag){
    this.playSound();
  }

  playSound(filename){
    document.getElementById('sound').play();
  }

  handleOnClose(e, tag){
    this.props.setDataMessage("notif", false)
  }

  handleNotificationOnClick(e, tag){
    this.props.history.push("/message")
  }

  render(){
    const newOption = {...this.props.option}
    newOption.body = `You have ${this.props.unreadMsg} new messages from ${this.props.nameSender}`
    return (
      <React.Fragment>
        {this.props.notif ? 
            <Notification 
              ignore={false}
              title="New Message"
              timeout={5000}
              options={newOption}
              onClose={this.handleOnClose.bind(this)}
              onShow={this.handleNotificationOnShow.bind(this)}
              onClick={this.handleNotificationOnClick.bind(this)}
            />
                : ""}
                <audio id='sound' preload='auto'>
                  <source src={Ringtone} type='audio/mpeg' />
                  <embed hidden={true} autostart='false' loop={false} src={Ringtone} />
                </audio>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { socket, notif, option, unreadMsg, name:nameSender, alreadyLogin, receiveNewMessage } = state.Message
  return { socket, notif, option, unreadMsg, nameSender, alreadyLogin, receiveNewMessage };
}

export default withRouter(connect(mapStatetoProps, { setDataMessage })(NotificationPages));