import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { setNewBanner, addNewBanner } from "../store/banner/actions"
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"
class ModalEdit extends Component{
  constructor(props){
    super(props)
    this.state = {
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewBanner("toggleEdit", !this.props.toggleEdit)
  }

  async submitForm(){
    try {
      let flag = false

      if(!this.props.data.title){
        await this.setState({
          detail: "Form title is empty"
        })
        flag = true
      } else if(!this.props.data.description){
        await this.setState({
          detail: "Form description is empty"
        })
        flag = true
      } else if(!this.props.data.type){
        await this.setState({
          detail: "Form type is empty"
        })
        flag = true
      } else if(!this.props.data.priority){
        await this.setState({
          detail: "Form priority is empty"
        })
        flag = true
      }

      if(flag) await this.setState({error_dlg: true})
      else {
        await this.setState({
          expRight: true
        })
        let send = {...this.props.data}
        if(!send.photo){
          delete send.photo
        }else{
          var FormData = require('form-data');
          const fd = new FormData();
          fd.append("file", send.photo, send.photo.name)
  
          const config = {
            url: BASE_URL_UPLOAD,
            method: "POST",
            data: fd
          }
          const result = await axios(config)
          send.photo = result.data.imageurl
        }

        delete send.id
        await this.props.addNewBanner("/admin/banner", send, "PUT", this.props.data.id)
        await this.props.setNewBanner("toggleEdit", !this.props.toggleEdit)
        await this.setState({
          expRight: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Banner</ModalHeader>
          <AvForm>
            <FormGroup>
            <Label sm="12">Title</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="title" value={this.props.data.title} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label>Photo</Label>
                <FormGroup>
                  <img src={this.props.data.tmpPhoto} width="150px" height="150px" alt="icon"/>
                </FormGroup>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="photo" onChange={this.props.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="textarea" placeholder="John Doe" name="description" value={this.props.data.description} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Type</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="type" value={this.props.data.type} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Priority</Label>
              <Col sm="12">
                <AvField required type="number" placeholder="John Doe" name="priority" value={this.props.data.priority === 0 ? "0" : this.props.data.priority} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Link</Label>
              <Col sm="12">
                <AvField type="text" placeholder="Insert Link" name="link" value={this.props.data.link} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Status</Label>
                <Col sm="12">
                  <Input type="select" name="status" onChange={this.props.onChangeInput} id="exampleSelect">
                    {this.props.data.status === false ? (
                      <>
                      <option selected value="0">Inactive</option>
                      <option value="1">Active</option>
                      </>
                      
                    ): (
                      <>
                        <option value="0">Inactive</option>
                        <option selected value="1">Active</option> 
                      </>
                    )} 
                  </Input>
                </Col>
              </FormGroup>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          {/* <Button type="button" onClick={this.submitForm} color="primary">Save changes</Button> */}
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit } = state.Banner
  return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewBanner, addNewBanner })(ModalEdit));