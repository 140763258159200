import {CHECK_KOMPLAIN_P2P, SET_KOMPLAIN_P2P, SET_DATA_KOMPLAIN_P2P, UPDATE_DATA_KOMPLAIN_P2P} from "./actionTypes"

export const checkKomplainP2P = (history, state, flag) => {
    return {
      type: CHECK_KOMPLAIN_P2P,
      payload: {history, state, flag}
    }
}

export const setKomplainP2P = (props) => {
    return {
      type: SET_KOMPLAIN_P2P,
      payload: props
    }
  }

export const setDataKomplainP2P = (name, data) => {
    return {
      type: SET_DATA_KOMPLAIN_P2P,
      payload: {name, data}
    }
  }

export const updateDataKomplainP2P = (url, state, method, id) => {

    return {
      type: UPDATE_DATA_KOMPLAIN_P2P,
      payload: {url, state, method, id}
    }
  }