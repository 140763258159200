import axios from 'axios';
import { BASE_URL_WASEMARKET, BASE_URL_UPLOAD } from './constanta'

//============ Save User info in Local Storage =============*
const userSetInLocalStorage = (name, user) => {
    localStorage.setItem(name, user);
}

//============ Gets the logged in user from Local Storage =============*
const userFromLocalStorage = () => {
    const user = localStorage.getItem('token');
    if (user)
        return user
    return null;
}

//============ Check User Authenticated =============*
const isUserAuthenticated = () => {
    return userFromLocalStorage() !== null;
}

const callGetCategory = (url, state) => {
    const token = localStorage.getItem("token")
    const headers = {
        headers: {
            token: token,
        }
    }

    const config = {
        method: 'get',
        ...headers,
        url: BASE_URL_WASEMARKET + url,
        params: state
    };

    return axios(config)
        .then(response => {
            if (response.status === 400 || response.status === 500){
                throw response.data;
            }
            return response.data.data;
            
        }).catch(err => {
            throw err.response.data;
        });
}

//============ Export file to xls =============*
const exportFile = (url, state) => {
    const token = localStorage.getItem("token")
    const headers = {
        headers: {
            token: token,
        }
    }

    const config = {
        method: 'get',
        ...headers,
        url: BASE_URL_WASEMARKET + url,
        params: state,
        responseType: 'blob',
    };

    return axios(config)
        .then(response => {
            if (response.status === 400 || response.status === 500){
                throw response.data;
            }
            return response.data;
            
        }).catch(err => {
            throw err.response.data;
        });
}
//============ Get Access Token =============*
const getToken = (props) => {
    const config = {
        method: 'post',
        url: BASE_URL_WASEMARKET + "/admin/check/login",
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: props.email,
            password: props.password
        }
    }
    return axios(config)
    .then(response => {
        if (response.status === 400 || response.status === 500){
            throw response.data;
        }
        userSetInLocalStorage("token", response.data.data)
        return response.data;
    }).catch(err => {
        throw err.response.data.error_description;
    });
}

//============ Check Token =============*
const checkToken = () => {
    let config = {
        method: "GET",
        url: BASE_URL_WASEMARKET + "/admin/check/token",
        headers: {
            token: localStorage.getItem("token")
        },
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500){
            throw response.data;
        }
        return response.data.data
    }).catch(err => {
        throw err
    })
}

//============ Convert format rupiah =============*
function convertToRupiah(angka) {
    var rupiah = "";
    var angkarev = angka.toString().split("").reverse().join("");
    for (var i = 0; i < angkarev.length; i++)
    if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + ".";
        return (
        "Rp. " +
        rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("")
    );
}

//============ HIT TO API WASMARKET =============*
function hitAPIWasmarket(url, state, method, id){
    let config = null
    if(method === "GET" && id){

    }else if(method === "GET"){
        config = {
            method: method,
            url: BASE_URL_WASEMARKET + url,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token")
            }
        }
    }else if(method === "POST"){
        config = {
            method: method,
            url: BASE_URL_WASEMARKET + url,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token")
            },
            data: state
        }
    }else if(method === 'PUT' || method === "PATCH"){
        if(!id){
            config = {
                method: method,
                url: BASE_URL_WASEMARKET + url,
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem("token")
                },
                data: state
            }
        }else{
            config = {
                method: method,
                url: BASE_URL_WASEMARKET + url + `/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.getItem("token")
                },
                data: state
            }
        }
        
    } else if(method === 'DELETE'){
        config = {
            method: method,
            url: BASE_URL_WASEMARKET + url + `/${id}`,
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token")
            },
        }
    }
    return axios(config)
    .then(response => {
        if (response.status === 400 || response.status === 500){
            throw response.data;
        }
        return response.data;
    }).catch(err => {
        throw err.response.data;
    });
}

async function uploadFoto(data){
  try {
    var FormData = require('form-data');
    const fd = new FormData();
    fd.append("file", data, data.name)

    const config = {
      url: BASE_URL_UPLOAD,
      method: "POST",
      data: fd
    }

    const {data:result} = await axios(config)
    return result.imageurl
  } catch (error) {
    throw error
  }
}

export { userSetInLocalStorage, userFromLocalStorage, isUserAuthenticated, getToken, checkToken, convertToRupiah, callGetCategory, hitAPIWasmarket, exportFile, uploadFoto }