import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { Row, Col, Card, CardBody, Container, Button, Input, Badge } from "reactstrap";
import DataTable from 'react-data-table-component';
import { convertToRupiah } from "../../../helpers/authUtils"
import moment from "moment"
import { setDataTransaction, checkTransaction, checkFindName, updateTransaction, exportTransaction } from "../store/actions"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"
import SweetAlert from 'react-bootstrap-sweetalert';

class TransactionPage extends Component{
  constructor(props){
    const today = moment().format("YYYY-MM-DD")
    super(props)
    this.state = {
      from: today,
      to: today,
      name: "",
      buyerName: "",
      destination: "",
      sellerID: "",
      buyerID: "",
      tmpStatus: "",
      id: "",
      checkId: "",
      status: "",
      modalEdit: false
    }
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.refershPage =this.refershPage.bind(this)
    this.search =this.search.bind(this)
    this.updateTrans =this.updateTrans.bind(this)
    this.exportFile =this.exportFile.bind(this)
    this.handleKeyPress =this.handleKeyPress.bind(this)
  }

  componentDidMount(){
    this.props.activateAuthLayout()
    this.props.checkTransaction(this.props.history, {
      from: this.state.from, to: this.state.to
    }, true)
  }

  handleKeyPress(e){
    if (e.key === "Enter") {
      this.search()
  }
  }

  async search(){
    await this.props.setDataTransaction("size", 10)
    await this.props.setDataTransaction("page", 1)
    if(this.props.names.length !== 0){
      this.setState({
        buyerID: this.props.names[0].id
      })
    } else if(this.props.names.length === 0){
      this.setState({
        buyerID: "",
        buyerName: ""
      })
    }

    let props = this.props
    await this.props.checkTransaction(this.props.history,{
      id: this.state.checkId,
      page: props.page,
      size: props.size,
      from: this.state.from,
      to: this.state.to,
      destination: this.state.destination,
      buyerID: this.state.buyerID,
      sellerID: this.state.sellerID
    }, false)
  }

  async refershPage(){
    const today = moment().format("YYYY-MM-DD")
    await this.setState({
      from: today,
      to: today,
      destination: "",
      buyerID: "",
      sellerID: "",
      buyerName: "",
      tmpStatus: "",
      checkId: ""
    })
    let props = this.props
    await this.props.setDataTransaction("size", 10)
    await this.props.setDataTransaction("page", 1)
    await this.props.setDataTransaction("names", [])
    await this.props.checkTransaction(this.props.history, {
      page: props.page,
      size: props.size,
      from: this.state.from,
      to: this.state.to,
    }, false)
  }

  async onChangeInput(e){
    const {value, name} = e.target
    let props = this.props
    await this.setState({
      [name]: value
    })
    if(name === "from" || name === "to" || name === "tmpStatus"){
      await this.props.checkTransaction(this.props.history, {
        page: props.page,
        size: props.size,
        from: this.state.from,
        to: this.state.to,
        destination: this.state.destination,
        buyerID: this.state.buyerID,
        sellerID: this.state.sellerID,
        status: this.state.tmpStatus
      }, false)
    }else if(name === "buyerName"){
      await this.props.checkFindName({name: value})
    }
  }

  async changeRowsPerPage(size){
    await this.props.setDataTransaction("size", size)
    let props = this.props
    await this.props.checkTransaction(this.props.history, {
      page: props.currentPage,
      size: size,
      from: this.state.from,
      to: this.state.to,
      destination: this.state.destination,
      buyerID: this.state.buyerID,
      sellerID: this.state.sellerID,
      status: this.state.tmpStatus
    }, false)
  }

  async onChangePage(page){
    await this.props.setDataTransaction("currentPage", page)
    let props = this.props
    await this.props.checkTransaction(this.props.history, {
      page: page,
      size: props.size,
      from: this.state.from,
      to: this.state.to,
      destination: this.state.destination,
      buyerID: this.state.buyerID,
      sellerID: this.state.sellerID,
      status: this.state.tmpStatus
    }, false)
  }

  async updateTrans(){
    const payload = {
      status: this.state.status,
      from: this.state.from,
      to: this.state.to,
      page: this.props.currentPage,
      size: this.props.size,
      destination: this.state.destination,
      buyerID: this.state.buyerID,
      sellerID: this.state.sellerID,
      tmpStatus: this.state.tmpStatus,
      callback: false
    }

    if(this.state.status === 3){
      payload.callback = true
    }

    await this.props.updateTransaction("/admin/transaction", payload, "PATCH", this.state.id)
    this.setState({modalEdit: false})
  }

  async exportFile(){
    if(this.props.transactions.length > 0){
        await this.props.exportTransaction({
          from: this.state.from,
          to: this.state.to,
        })
    }
  }

  render(){
    const columns = [
      {
          name: "ID",
          selector: "id",
          width: "100px"
      },
      {
        name: "Type",
        selector: "type",
        width: "100px",
        cell: row => {
            return (
                row.type === "INQ" ? <Badge className="rounded-pill" color="warning">INQ</Badge> :
                <Badge className="rounded-pill" color="success" >PAY</Badge>
            )
        },
      },
      {
        name: "Status",
        selector: "status",
        width: "100px",
        cell: row => {
            return (
                row.status === 0 ? <Badge className="rounded-pill" color="primary">Pending</Badge> :
                row.status === 1 ?  <Badge className="rounded-pill text-dark" color="success" >Success</Badge> :
                <Badge color="danger">Reject</Badge>
            )
        },
      },
      {
        name: "Destination",
        selector: "destination",
        width: "5%"
      },
      {
          name: "Store Name",
          selector: "store_name",
          width: "150px"
      },
      {
        name: "Buyer Name",
        selector: "buyer_name",
        width: "150px"
      },
      {
          name: "Product Name",
          selector: "product_name", 
          width: "150px"
      },
      {
        name: "Reff Client",
        selector: "reff_client",
        width: "5%"
      },
      {
        name: "Seller ID",
        selector: "seller_id",
        width: "100px"
      },
      {
        name: "Store Buy Price",
        selector: "store_buy_price",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.store_buy_price))
          )
        }
      },
      {
        name: "Store Sell Price",
        selector: "store_sell_price",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.store_sell_price))
          )
        }
      },
      {
          name: "Buyer ID",
          selector: "buyer_id",
          width: "100px"
      },
      {
        name: "Price",
        selector: "price",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.price))
          )
        }
      },
      {
        name: "Fee",
        selector: "fee",
        width: "100px",
        cell: row => {
          return(
            convertToRupiah(row.fee)
          )
        }
      },
      {
        name: "Total Price",
        selector: "total_price",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.total_price))
          )
        }
      },
      {
        name: "Serial Number",
        selector: "serial_number",
      },
      {
        name: "Message",
        selector: "message",
        width: "32%"
      },
      {
        name: "Date",
        selector: "date",
        width: "120px",
      },
      {
        name: "Time",
        selector: "time",
        width: "100px"
      },
      {
        name: "Success Time",
        selector: "success_time",
        width: "200px",
        cell: row => {
          return (
            row.success_time ? moment(row.success_time).format('YYYY/MM/DD HH:mm:ss') : "-"
          )
        }
      },
      {
        name: "Last Update",
        selector: "last_update",
        width: "200px",
        cell: row => {
          return (
            row.last_update ? moment(row.last_update).format('YYYY/MM/DD HH:mm:ss') : "-"
          )
        }
      },
      {
        name: "CreatedAt",
        selector: "created_at",
        width: "200px",
        cell: row => {
          return (
            row.created_at ? moment(row.created_at).format('YYYY/MM/DD HH:mm:ss') : "-"
          )
        }
      },
      {
        name: "UpdatedAt",
        selector: "updated_at",
        width: "200px",
        cell: row => {
          return (
            row.updated_at ? moment(row.updated_at).format('YYYY/MM/DD HH:mm:ss') : "-"
          )
        }
      },
      {
        name: "Start Balance",
        selector: "start_balance",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.start_balance))
          )
        }
      },
      {
        name: "Last Balance",
        selector: "last_balance",
        width: "140px",
        cell: row => {
          return(
            convertToRupiah(parseInt(row.last_balance))
          )
        }
      },
      {
        name: "Update",
        cell: row => {
            return (
                this.props.type !== "cs" ?
                row.status === 0 ? <>
                <Badge color="success" className="text-dark mr-2" style={{cursor: "pointer"}}
                  onClick={() => this.setState({ id: row.id, status: 1, modalEdit: true})}
                ><i className="fa fa-check text-dark"></i> Success</Badge>
                <Badge color="danger" style={{cursor: "pointer"}}
                  onClick={() => this.setState({ id: row.id, status: 2, modalEdit: true})}
                ><i className="fa fa-trash-o"></i> Reject</Badge>
              </> :
              row.status === 1 ?  <>
              <Badge color="danger" className="mr-2" style={{cursor: "pointer"}}
                onClick={() => this.setState({ id: row.id, status: 2, modalEdit: true})}
                ><i className="fa fa-trash-o"></i> Reject</Badge> 
                <Badge color="primary" style={{cursor: "pointer"}}
                  onClick={() => this.setState({ id: row.id, status: 3, modalEdit: true})}
                ><i className="fa fa-refresh"></i> Resend Callback</Badge>
                </>
                : <>
              <Badge color="success" className="text-dark mr-2" style={{cursor: "pointer"}}
                onClick={() => this.setState({ id: row.id, status: 1, modalEdit: true})}
                ><i className="fa fa-check text-dark"></i> Success</Badge>
                <Badge color="primary" style={{cursor: "pointer"}}
                  onClick={() => this.setState({ id: row.id, status: 3, modalEdit: true})}
                ><i className="fa fa-refresh"></i> Resend Callback</Badge>
                </>
                : <Badge color="primary" style={{cursor: "pointer"}}
                onClick={() => this.setState({ id: row.id, status: 3, modalEdit: true})}
              ><i className="fa fa-refresh"></i> Resend Callback</Badge>
                
            )
            
        },
        
      },
    ]
    return(
      <React.Fragment>
        <NotificationMessage />
        <h2 className="card_title mb-3">Transaction</h2>
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container className="mt-2">
                  <Row>
                  <Col xl="2" lg="2" md="4" sm="12">
                    <div className="input-group">
                      <Input type="select" name="tmpStatus" value={this.state.tmpStatus} onChange={this.onChangeInput}>
                        <option selected value="">All Status</option>
                        <option value="0">Pending</option>
                        <option value="1">Success</option>
                        <option value="2">Reject</option>
                      </Input>
                    </div>
                    </Col>
                    <Col xl="4" lg="4" md="12" sm="12">
                      <div className="input-group">
                        <p style={{margin: "auto 0"}} className="mr-1"><b>From</b></p>
                        <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                        <p style={{margin: "auto 0"}} className="ml-1 mr-1"><b>To</b></p>
                        <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                      </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12">
                      <div className="input-group">
                        <Input className="mr-1" onKeyPress={this.handleKeyPress} type="text" placeholder="ID or Reff ID" name="checkId" value={this.state.checkId} onChange={this.onChangeInput}/>
                        <Input className="mr-1" onKeyPress={this.handleKeyPress} type="text" placeholder="Destination" name="destination" value={this.state.destination} onChange={this.onChangeInput}/>
                        <Input className="mr-1" onKeyPress={this.handleKeyPress} list="brow" placeholder="Buyer Name" name="buyerName" value={this.state.buyerName} onChange={this.onChangeInput}/>
                        <datalist id="brow" name="buyerName" onChange={this.onChangeInput}>
                          {this.props.names.length === 0 ? "" : this.props.names.map(el => {
                            return <option value={el.name}>{el.name}</option>
                          })}
                        </datalist> 
                        <Input className="mr-1" onKeyPress={this.handleKeyPress} type="text" placeholder="SellerID" name="sellerID" value={this.state.sellerID} onChange={this.onChangeInput}/>
                        <Button onClick={this.search}>Search</Button>
                      </div>
                    </Col>
                    <Col xl="5" lg="5" md="12" sm="12">
                    <div className="input-group mt-2">
                        <Button color="primary" onClick={this.exportFile} className="mr-2"><i className="fa fa-download"></i> DOWNLOAD</Button>
                        <Button color="primary" onClick={this.refershPage}><i className="fa fa-refresh"></i> REFRESH</Button>
                    </div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    noHeader={true}
                    columns={columns}
                    data={this.props.transactions}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.modalEdit ? 
          <SweetAlert
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.updateTrans()}
            onCancel={() => this.setState({ modalEdit: false })} >
              Are you sure to process Transaction with ID: {this.state.id} for {this.state.status === 1 ? <Badge color="success" className="text-dark">SUCCESS</Badge> : this.state.status === 2 ? <Badge color="danger">REJECT</Badge> : <Badge color="primary">RESEND CALLBACK</Badge>}
            </SweetAlert> : null
          }
        
        {this.props.confirm ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.props.setDataTransaction("confirm", false)}  >
            {this.props.msgSuccess}
          </SweetAlert> : null
        }
        {this.props.confirmError ?
          <SweetAlert
            error
            title="FAILED"
            onConfirm={() => this.props.setDataTransaction("confirmError", false)}  >
            {this.props.msgError}
          </SweetAlert> : null
        }
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { transactions, currentPage, totalItems, size, names, confirm, msgSuccess, confirmError, msgError } = state.Transaction
  const { type } = state.Username
  return { transactions, currentPage, totalItems, size, names, confirm, msgSuccess, type, confirmError, msgError };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, setDataTransaction, checkTransaction, checkFindName, updateTransaction, exportTransaction })(TransactionPage));