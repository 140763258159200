import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_BRAND } from '../brand/actionTypes';
import {  setBrand } from '../brand/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/brand", {page: state.currentPage, size: state.size, search: state.nameBrand})
        yield put(setBrand(response))
    } catch (error) {
    }
}

export function* watchUserBrand() {
    yield takeEvery(ADD_BRAND, category)
}

function* addBrandSaga() {
    yield all([fork(watchUserBrand)]);
}

export default addBrandSaga;