import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_STORE_BADGE, } from './actionTypes';
import {  setStoreBadge } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* storeBadge({payload: {history, state, flag}}) {
    try {
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const response = yield call(callGetCategory, "/admin/store-badge", state)
        yield put(setStoreBadge(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setStoreBadge({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
                dataBadges: [],
            }))
        }
    }
}

export function* watchStoreBadge() {
    yield takeEvery(CHECK_STORE_BADGE, storeBadge)
}

function* storeBadgeSaga() {
    yield all([fork(watchStoreBadge)]);
}

export default storeBadgeSaga;