import { takeEvery, fork, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { EXPORT_CASH_OUT_P2P } from '../cashout/actionTypes';
//============ Authentication Functions =============*
import { exportFile } from '../../../../helpers/authUtils';
import fileDownload from 'js-file-download';
import moment from "moment"

//============ Dispatch Redux Actions directly =============*
function* exportCashOutP2P({payload: {state}}) {
    try {
      const methodbank = state.method === "-1" ? "" : state.method
      const statusCash = state.status === "-1" ? "" : state.status
      state.method = methodbank
      state.status = statusCash
      const result = yield call(exportFile, "/admin/cashout/p2p/export", state)
      fileDownload(result, "Cashout P2P (" + moment().format("YYYY-MM-DD") + ").xlsx")
    } catch (error) {
      console.log(error);
    }
}

export function* watchExportCashP2POut() {
    yield takeEvery(EXPORT_CASH_OUT_P2P, exportCashOutP2P)
}

function* exportCashOutP2PSaga() {
    yield all([fork(watchExportCashP2POut)]);
}

export default exportCashOutP2PSaga;