import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { IMPORT_PRODUCT_MASTER } from '../product/actionTypes';
import {  setProductMaster, setNewProductMaster } from '../product/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* importProductMaster({payload: {url, state, method, id}}) {
    try {
        const result = yield call(hitAPIWasmarket, url, state, method, id)
        if(result.success){
          const response = yield call(callGetCategory, "/admin/product-master", {page: state.currentPage, size: state.size, search: state.nameProduct})
          yield put(setProductMaster(response))
          yield put(setNewProductMaster("toggleImport", false))
          yield put(setNewProductMaster("desc", result.data))
          yield put(setNewProductMaster("swSuccess", true))
          yield put(setNewProductMaster("message", result.message))
        }
    } catch (error) {
      console.error(error);
      console.error("error disini!!!")
    }
}

export function* watchUserProductMasterImport() {
    yield takeEvery(IMPORT_PRODUCT_MASTER, importProductMaster)
}

function* importProductMasterSaga() {
    yield all([fork(watchUserProductMasterImport)]);
}

export default importProductMasterSaga;