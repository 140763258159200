import { CHECK_PRODUCT_MASTER, SET_PRODUCT_MASTER, SET_CHANGE_PRODUCT_MASTER } from "./actionTypes"

const initialState = {
  products: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, toggleAdd: false,
  toggleEdit: false, status: "", arrBrand: [], toggleImport: false, swSuccess: false, swFalse: false,
  desc: "", message: []
}

const productMaster = (state = initialState, action) => {
  switch(action.type){
    case CHECK_PRODUCT_MASTER:
      state = {
        ...state
      }
      break;
    case SET_PRODUCT_MASTER:
      state = {
        ...state,
        products: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_CHANGE_PRODUCT_MASTER:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default productMaster