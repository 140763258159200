import { CHECK_CASH_OUT_P2P, SET_CASH_OUT_P2P, SET_DATA_CASH_OUT_P2P, UPDATE_CASH_OUT_P2P, EXPORT_CASH_OUT_P2P } from "./actionTypes"

export const checkCashOutP2P = (history, state, flag) => {
  return {
    type: CHECK_CASH_OUT_P2P,
    payload: {history, state, flag}
  }
}

export const setCashOutP2P = (props) => {
  return {
    type: SET_CASH_OUT_P2P,
    payload: props
  }
}

export const setDataCashOutP2P = (name, data) => {
  return {
    type: SET_DATA_CASH_OUT_P2P,
    payload: {name, data}
  }
}

export const updateCashOutP2P = (url, state, method, id) => {
  return {
    type: UPDATE_CASH_OUT_P2P,
    payload: {url, state, method, id}
  }
}
export const exportCashOutP2P = (state) => {
  return {
    type: EXPORT_CASH_OUT_P2P,
    payload: {state}
  }
}