import { CHECK_POST_TAG, SET_POST_TAG, SET_DATA_POST_TAG, CUD_POST_TAG } from "./actionTypes"

export const checkPostTag = (history, state, flag) => {
  return {
    type: CHECK_POST_TAG,
    payload: {history, state, flag}
  }
}

export const setPostTag = (props) => {
  return {
    type: SET_POST_TAG,
    payload: props
  }
}

export const setDataPostTag = (name, data) => {
  return {
    type: SET_DATA_POST_TAG,
    payload: {name, data}
  }
}

export const cudPostTag = (url, state, method, id) => {
  return {
    type: CUD_POST_TAG,
    payload: {url, state, method, id}
  }
}