import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setNewProductMaster, addNewProductMaster } from "../store/product/actions"
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalAdd extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      description: "",
      code: "",
      productBrandId: "",
      category: "",
      denom: "",
      is_bill: 0,
      isPickCategory: false,
      brands: [],
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  async toggle(){
    await this.props.setNewProductMaster("toggleAdd", !this.props.toggleAdd)
    await this.setState({
      name: "",
      icon: "",
      description: "",
      code: "",
      productBrandId: "",
      isPickCategory: false
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0]
      })
    }else if(name === "category"){
      const data = this.props.arrBrand.filter(el => {return el.categoryId === Number(value)})
      if(data.length > 0){
        await this.setState({
          isPickCategory: true
        })
      }else{
        await this.setState({
          isPickCategory: false
        })
      }
      await this.setState({
        brands: data
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      let flag = false
      if(!this.state.productBrandId){
        await this.setState({
          detail: "Form brand is empty"
        })
        flag = true
      } else if(!this.state.name){
        await this.setState({
          detail: "Form name is empty"
        })
        flag = true
      } else if(!this.state.code){
        await this.setState({
          detail: "Form code is empty"
        })
        flag = true
      } else if(!this.state.icon){
        await this.setState({
          detail: "Form icon is empty"
        })
        flag = true
      } else if(!this.state.description){
        await this.setState({
          detail: "Form description is empty"
        })
        flag = true
      }

      if(flag){
        await this.setState({
          error_dlg: true
        })
      } else {
        await this.setState({
          expRight: true
        })
        var FormData = require('form-data');
        const fd = new FormData();
        fd.append("file", this.state.icon, this.state.icon.name)
  
        const config = {
          url: BASE_URL_UPLOAD,
          method: "POST",
          data: fd
        }
  
        const result = await axios(config)
        let obj = {
          name: this.state.name,
          icon: result.data.imageurl,
          description: this.state.description,
          status: "0",
          productBrandId: this.state.productBrandId,
          code: this.state.code,
          denom: this.state.denom,
          is_bill_payment: parseInt(this.state.is_bill)
        }
        await this.props.addNewProductMaster("/admin/product-master", obj, "POST")
        await this.setState({
          name: "",
          icon: "",
          description: "",
          code: "",
          productBrandId: "",
          isPickCategory: false,
          brands: [],
          denom: "",
        })
        await this.props.setNewProductMaster("toggleAdd", !this.props.toggleAdd)
        await this.setState({
          expRight: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} >Add Product Master</ModalHeader>
            <AvForm>
              <FormGroup>
              <Label sm="12">Category</Label>
                <Col sm="12">
                <Input required type="select" name="category" onChange={this.onChangeInput} id="exampleSelect1">
                  <option selected disabled>----- Select Category -----</option>
                  {this.props.arrCategory.map(el => {
                    return <option key={el.id} value={el.id}>{el.name}</option>
                  })}
                </Input>
                </Col>
              </FormGroup>
              {this.state.isPickCategory ? (
                <>
                  <FormGroup>
                    <Label sm="12">Brand</Label>
                    <Col sm="12">
                      <Input required type="select" name="productBrandId" onChange={this.onChangeInput} id="exampleSelect2">
                        <option selected disabled>----- Select Brand -----</option>
                        {this.state.brands.map(el => {
                          return <option key={el.id} value={el.id}>{el.name}</option>
                        })}
                      </Input>
                        </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Name</Label>
                    <Col sm="12">
                      <AvField required type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Code</Label>
                    <Col sm="12">
                      <AvField required type="text" placeholder="Code" name="code" value={this.state.code} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm="12">
                      <Label>Icon</Label>
                      <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Denom</Label>
                    <Col sm="12">
                      <AvField type="text" placeholder="Input denom" name="denom" value={this.state.denom} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Description</Label>
                    <Col sm="12">
                      <AvField required type="textarea" placeholder="Description" name="description" value={this.state.description} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Bill Payment</Label>
                    <Col sm="12">
                        <Input type="select" name="is_bill" onChange={this.onChangeInput} id="exampleSelect">
                            <option selected value="0">False</option>
                            <option value="1">True</option>
                        </Input>
                    </Col>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup>
                    <Label sm="12">Brand</Label>
                    <Col sm="12">
                      <Input disabled type="select" name="productBrandId" defaultValue="----- Select Brand -----" id="exampleSelect2">
                        <option selected>----- Select Brand -----</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Name</Label>
                    <Col sm="12">
                      <Input disabled type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput} required />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Code</Label>
                    <Col sm="12">
                      <Input disabled type="text" placeholder="Code" name="code" value={this.state.code} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm="12">
                      <Label >Icon</Label>
                      <CustomInput disabled type="file" id="exampleCustomFileBrowser" name="icon" value={this.state.icon} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">denom</Label>
                    <Col sm="12">
                      <Input disabled type="text" placeholder="Input denom" name="denom" value={this.state.denom} onChange={this.onChangeInput} required />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Description</Label>
                    <Col sm="12">
                      <Input disabled type="textarea" placeholder="Description" name="description" value={this.state.description} onChange={this.onChangeInput} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label sm="12">Bill Payment</Label>
                    <Col sm="12">
                        <Input disabled type="select" name="is_bill" onChange={this.onChangeInput} id="exampleSelect">
                            <option value="0">False</option>
                            <option value="1">True</option>
                        </Input>
                    </Col>
                  </FormGroup>
                </>
              )}
            </AvForm>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            {
              this.state.isPickCategory ? 
              <LaddaButton
                loading={this.state.expRight}
                onClick={this.submitForm}
                data-style={EXPAND_RIGHT}
                className="btn btn-primary"
              >Save changes</LaddaButton> : 
              null
            }
            
          </ModalFooter>
        </Modal>

        {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd, arrBrand } = state.ProductMaster
  const { arrCategory } = state.Brand
  return { toggleAdd, arrBrand, arrCategory };
}

export default withRouter(connect(mapStatetoProps, { setNewProductMaster, addNewProductMaster })(ModalAdd));