import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setDataMonitoring, createTransferMonitorBalance } from "../store/monitoring/actions"
import { Modal, ModalHeader, ModalFooter, Button, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { convertToRupiah } from "../../../helpers/authUtils"
import SweetAlert from 'react-bootstrap-sweetalert';

class ModalAdd extends Component{
  constructor(props){
    super(props)
    this.state = {
      sourceID: "",
      destinationID: "",
      isSource: false,
      isDestination: false,
      amount: "",
      confirmSubmit: false,
      error: false,
      dsc: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fillterBank = this.fillterBank.bind(this)
  }

  async toggle(){
    await this.setState({
      sourceID: "",
      destinationID: "",
      isSource: false,
      isDestination: false,
      amount: "",
      confirmSubmit: false
    })
    await this.props.setDataMonitoring("toggleAdd", !this.props.toggleAdd)
  }

  async onChangeInput(e){
    const {name, value} = e.target
    await this.setState({
      [name]: value
    })

    if(name === "sourceID"){
      if(value === ""){
        await this.setState({isSource: false})
      } else {
        await this.setState({isSource: true})
      }
    }
    if(name === "destinationID"){
      if(value === ""){
        await this.setState({isDestination: false})
      } else {
        await this.setState({isDestination: true})
      }
    }
  }

  async handleSubmit(){
    if(parseInt(this.fillterBank(this.state.sourceID)) < parseInt(this.state.amount)){
      this.setState({error: true, dsc: "Your balance is not enough"})
    }else if(parseInt(this.state.amount) < 0){
      this.setState({error: true, dsc: "Canot transfer minus"})
    } else if(this.state.sourceID === this.state.destinationID){ 
      this.setState({error: true, dsc: "Canot transfer to same bank"})
    }else {
      const send = {
        sourceID: this.state.sourceID, 
        destinationID: this.state.destinationID, 
        amount: this.state.amount
      }
      await this.props.createTransferMonitorBalance("/admin/monitoring", send, "POST")
    }
    this.toggle()
  }
  
  fillterBank(id){
    let bank = this.props.banks.filter(el => {return el.id === + id})
    return bank[0].balance
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Transfer Dana Antar Kas</ModalHeader>
          <AvForm onValidSubmit={() => this.setState({confirmSubmit: true})}>
            <FormGroup>
              <Col sm="12">
              <AvField required type="select" label="Asal" name="sourceID" onChange={this.onChangeInput} id="exampleSelect">
                <option value="">----- Pilih Bank Asal -----</option>
                {this.props.banks.map(el => {
                  return <option key={el.id} value={el.id}>{el.name}</option>
                })}
              </AvField>
              </Col>
            </FormGroup>
            {this.state.isSource ? <FormGroup>
              <Col sm="12">
              <AvField required type="select" label="Tujuan" name="destinationID" onChange={this.onChangeInput} id="exampleSelect">
                <option value="">----- Pilih Bank Tujuan -----</option>
                {this.props.banks.map(el => {
                  return <option key={el.id} value={el.id}>{el.name}</option>
                })}
              </AvField>
              </Col>
            </FormGroup> :
            <FormGroup>
              <Col sm="12">
              <AvField disabled type="select" label="Tujuan" name="destinationID" onChange={this.onChangeInput} id="exampleSelect">
                <option value="">----- Pilih Bank Tujuan -----</option>
                {this.props.banks.map(el => {
                  return <option key={el.id} value={el.id}>{el.name}</option>
                })}
              </AvField>
              </Col>
            </FormGroup>}
            {this.state.isDestination ? 
              <FormGroup>
                <Col sm="12">
                  <AvField type="number" label="Jumlah Transfer" placeholder="Type amount" name="amount" value={this.state.amount} onChange={this.onChangeInput} validate={
                    {
                      required: {value: true, errorMessage: 'Please enter a amount'},
                    }
                  }
                  />
                  <div class="form-text" name="test">Balance: {this.state.sourceID ? convertToRupiah(parseInt(this.fillterBank(this.state.sourceID))) : null}</div>
                </Col>
              </FormGroup> :
              <FormGroup>
                <Col sm="12">
                  <AvField disabled type="number" label="Jumlah Transfer" placeholder="Type amount" name="amount" value={this.state.amount} onChange={this.onChangeInput} />
                </Col>
              </FormGroup>
            }
            
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            {this.state.isDestination && this.state.isSource ? 
              <Button color="primary">Transfer</Button>
            : null
            }
            
          </ModalFooter>
        </AvForm>
      </Modal>


      {this.state.confirmSubmit ? 
          <SweetAlert
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.handleSubmit()}
            onCancel={() => this.setState({ confirmSubmit: false })} >
              Are you sure to transfer {convertToRupiah(this.state.amount)}
          </SweetAlert> : null
        }
      
      {this.state.error ?
          <SweetAlert
            error
            title="Error"
            onConfirm={() => this.setState({error: false})}  >
            {this.state.dsc}
            </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd, banks } = state.Monitoring
  return { toggleAdd, banks };
}

export default withRouter(connect(mapStatetoProps, { setDataMonitoring, createTransferMonitorBalance })(ModalAdd));