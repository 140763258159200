import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Container, Input, Tooltip, Badge } from "reactstrap";
import DataTable from 'react-data-table-component';
import moment from "moment"
import { activateAuthLayout } from "../../../store/actions";
import { setP2P, checkP2P, setDataP2P, updateDataP2P, updateDataBillerP2P } from "../store/actions"
import { convertToRupiah } from "../../../helpers/authUtils"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import ModalSee from "./DetailModalP2P"
import SweetAlert from 'react-bootstrap-sweetalert';

class P2PTransaction extends Component {
    constructor(props) {
        const today = moment().format("YYYY-MM-DD")
        super(props)
        this.state = {
            from: today,
            to: today,
            payment_status: "",
            order_status: "",
            name: "",
            buyer_name: "",
            seller_name: "",
            idpelanggan: "",
            expRight: false,
            tooltiprefresh: false,
            id: ""
        }
        this.buttonSeeMU = this.buttonSeeMU.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.search = this.search.bind(this)
        this.refershPage = this.refershPage.bind(this)
        this.toolTipRefresh = this.toolTipRefresh.bind(this)
        this.updateStatusOrder = this.updateStatusOrder.bind(this)
        this.updateStatusOrderBiller = this.updateStatusOrderBiller.bind(this)
    }

    toolTipRefresh = () => {
        this.setState({
            tooltiprefresh: !this.state.tooltiprefresh
        })
    }

    async componentDidMount() {
        this.props.activateAuthLayout()
        if(this.props.p2p.length === 0) {
            await this.props.setDataP2P("expRight", true)
        }
        this.props.checkP2P(this.props.history, {
            from: this.state.from, 
            to: this.state.to,
            buyer_name: this.state.buyer_name,
            seller_name: this.state.seller_name,
            idpelanggan: this.state.idpelanggan,
            status: this.state.order_status,
            payment_status: this.state.payment_status,
            name: this.state.name
        }, true)
    }

    async buttonSeeMU(data){
        await this.props.setDataP2P("dataP2P", data)
        await this.props.setDataP2P("toggleSee", !this.props.toggleSee)
    }

    async refershPage(){
        const today = moment().format("YYYY-MM-DD")
        await this.setState({
            from: today,
            to: today,
            id: "",
            payment_status: "",
            order_status: "",
            name: "",
            buyer_name: "",
            seller_name: "",
            idpelanggan: ""
        })
        this.search()
    }

    async onChangeInput(e){
        const {value, name} = e.target
        await this.setState({
            [name]: value
        })
    }

    async search(){
        await this.props.setDataP2P("expRight", true)
        await this.props.checkP2P(this.props.history,{
            id: this.state.id,
            from: this.state.from, 
            to: this.state.to,
            buyer_name: this.state.buyer_name,
            seller_name: this.state.seller_name,
            idpelanggan: this.state.idpelanggan,
            status: this.state.order_status,
            payment_status: this.state.payment_status,
            name: this.state.name
        }, false)   
    }

    async updateStatusOrder(obj){
        await this.props.updateDataP2P("/admin/p2p", {
            from: this.state.from, 
            to: this.state.to,
            buyer_name: this.state.buyer_name,
            seller_name: this.state.seller_name,
            idpelanggan: this.state.idpelanggan,
            status: this.state.order_status,
            payment_status: this.state.payment_status,
            name: this.state.name, 
            statusOrder: obj.status,
            buyer_id: obj.buyer_id
        }, "PUT", obj.id)
    }

    async updateStatusOrderBiller(obj){
        await this.props.updateDataBillerP2P("/admin/p2p/biller", {
            from: this.state.from, 
            to: this.state.to,
            buyer_name: this.state.buyer_name,
            seller_name: this.state.seller_name,
            idpelanggan: this.state.idpelanggan,
            status: this.state.order_status,
            payment_status: this.state.payment_status,
            name: this.state.name, 
            statusOrder: obj.status,
            buyer_id: obj.buyer_id
        }, "PUT", obj.id)
    }


    render() {
        const columns = [
            {
                name: "",
                cell: row => {
                    return(
                        <Badge className="rounded-pill mr-2" color="primary" style={{cursor: "pointer"}}
                            onClick={() => this.buttonSeeMU(row)}>
                                <i className="fa fa-eye"></i> Detail
                        </Badge>
                    )
                }
            },
            {
                name: "ID",
                selector: "_id",
                width: "10%"
            },
            {
                name: "Payment Status",
                selector: "payment_status",
                cell: row => {
                    return (
                        row.payment_status === "WAITING" ? <Badge className="rounded-pill" color="secondary">{row.payment_status}</Badge> :
                        row.payment_status === "SUCCESS" ?  <Badge className="rounded-pill" color="success">{row.payment_status}</Badge> :
                        row.payment_status === "FAILED" ?  <Badge className="rounded-pill" color="danger">{row.payment_status}</Badge> :
                        <Badge className="rounded-pill" color="dark">{row.payment_status}</Badge>
                    )
                }
            },
            {
                name: "Status Order",
                selector: "status",
                width: "150px",
                cell: row => {
                    return (
                        row.status === "BELUMBAYAR" ? <Badge className="rounded-pill" color="info">BELUMBAYAR</Badge> :
                        row.status === "MENUNGGU" ? <Badge className="rounded-pill" color="warning">MENUNGGU</Badge> :
                        row.status === "DITERIMA" ? <Badge className="rounded-pill" color="secondary">DITERIMA</Badge> :
                        row.status === "DIKIRIM" ?  <Badge className="rounded-pill" color="primary">DIKIRIM</Badge> :
                        row.status === "SUKSES" ?  <Badge className="rounded-pill" color="success">SUKSES</Badge> :
                        <Badge className="rounded-pill" color="danger">GAGAL</Badge>
                    )
                }
            },
            {
                name: "Seller Name",
                selector: "seller_name",
                width: "150px"
            },
            {
                name: "Product Name",
                selector: "products[0].name",
            },
            {
                name: "Product Price",
                selector: "products[0].price",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].price))
                    )
                },
                width: "130px"
            },
            {
                name: "Type",
                selector: "products[0].type",
            },
            {
                name: "Quantity",
                selector: "products[0].qty",
            },
            {
                name: "Discount Price",
                selector: "products[0].discount_price",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].discount_price))
                    )
                }
            },
            {
                name: "Sub Total",
                selector: "products[0].sub_total",
                width: "150px",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.products[0].sub_total))
                    )
                }
            },
            {
                name: "Total Price",
                selector: "total_price",
                width: "150px",
                cell: row => {
                    return(
                        convertToRupiah(parseInt(row.total_price))
                    )
                }
            },
            {
                name: "ID Pelanggan",
                selector: "products[0].idpelanggan",
                width: "150px",
            },
            {
                name: "Created At",
                selector: "created_at",
                cell: row => {
                    return(
                        moment(row.created_at, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
                    )
                },
                width: "200px"
            }
        ]
        const conditionalRowStyles = [
            {
              when: row => row.komplain.status !== 0,
              style: {
                backgroundColor: '#C1D1F4',
                color: 'black'
              },
            },
            // You can also pass a callback to style for additional customization
          ];
        return(
            <React.Fragment>
                <h2 className="card_title mb-3">P2P Transaction</h2>
                <Row>
                    <Col className="stretched_card">
                        <Card>
                        <CardBody>
                            <Container className="mt-2">
                                <Row>
                                    <Col xl="12" lg="12" md="12" sm="12">
                                        <div className="input-group">
                                            <p style={{margin: "auto 0"}} className="mr-1"><b>From</b></p>
                                            <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                                            <p style={{margin: "auto 0"}} className="ml-1 mr-1"><b>To</b></p>
                                            <Input type="date" value={this.state.to} name="to" className="mr-1" onChange={this.onChangeInput}/>
                                            <Input type="select" className="mr-1" name="payment_status" value={this.state.payment_status} onChange={this.onChangeInput}>
                                            <option selected value="">Payment Status</option>
                                            <option value="WAITING">WAITING</option>
                                            <option value="SUCCESS">SUCCESS</option>
                                            <option value="FAILED">FAILED</option>
                                            <option value="EXPIRED">EXPIRED</option>
                                        </Input>
                                        <Input type="select" className="mr-1" name="order_status" value={this.state.order_status} onChange={this.onChangeInput}>
                                            <option selected value="">Order Status</option>
                                            <option value="BELUMBAYAR">BELUMBAYAR</option>
                                            <option value="MENUNGGU">MENUNGGU</option>
                                            <option value="DITERIMA">DITERIMA</option>
                                            <option value="DIKIRIM">DIKIRIM</option>
                                            <option value="SUKSES">SUKSES</option>
                                            <option value="GAGAL">GAGAL</option>
                                        </Input>
                                        <Input className="mr-1" type="text" placeholder="ID Order" name="id" value={this.state.id} onChange={this.onChangeInput}/>
                                        <Input className="mr-1" type="text" placeholder="Product Name" name="name" value={this.state.name} onChange={this.onChangeInput}/>
                                        </div>
                                    </Col>
                                    <Col xl="7" lg="7" md="8" sm="12" className="mt-2">
                                    <div className="input-group">
                                    <Input className="mr-1" type="text" placeholder="ID Pelanggan" name="idpelanggan" value={this.state.idpelanggan} onChange={this.onChangeInput}/>
                                        <Input className="mr-1" type="text" placeholder="Buyer Name" name="buyer_name" value={this.state.buyer_name} onChange={this.onChangeInput}/>
                                        <Input className="mr-1" type="text" placeholder="Seller Name" name="seller_name" value={this.state.seller_name} onChange={this.onChangeInput}/>
                                        <LaddaButton
                                        loading={this.props.expRight}
                                        data-style={EXPAND_RIGHT}
                                        className="btn btn-primary mr-2"
                                        onClick={this.search}
                                        >Search</LaddaButton>
                                        <i style={{cursor: "pointer"}} className="fa fa-refresh mt-2 mr-4" onClick={this.refershPage} id="ToolRefresh"></i>
                                    </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                            <DataTable
                                noHeader={true}
                                columns={columns}
                                data={this.props.p2p}
                                responsive={true}
                                conditionalRowStyles={conditionalRowStyles}
                            />
                            </Container>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ModalSee 
                update={this.updateStatusOrder}
                updateBiller={this.updateStatusOrderBiller}/>

                <Tooltip placement="bottom" isOpen={this.state.tooltiprefresh} target="ToolRefresh" toggle={this.toolTipRefresh}>
                    Refresh
                </Tooltip>

                {this.props.confirm ?
                    <SweetAlert
                        success
                        title="SUCCESS"
                        onConfirm={() => this.props.setDataP2P("confirm", false)}  >
                        {this.props.msgSuccess}
                    </SweetAlert> : null
                }
                {this.props.failedUpdate ?
                    <SweetAlert
                        error
                        title="FAILED"
                        onConfirm={() => this.props.setDataP2P("failedUpdate", false)}  >
                        {this.props.msgFailed}
                    </SweetAlert> : null
                }
                
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { p2p, toggleSee, expRight, confirm, msgSuccess, failedUpdate, msgFailed } = state.P2P
    return { p2p, toggleSee, expRight, confirm, msgSuccess, failedUpdate, msgFailed };
  }

  export default withRouter(connect(mapStatetoProps, { activateAuthLayout, setP2P, checkP2P, setDataP2P, updateDataP2P, updateDataBillerP2P })(P2PTransaction));