import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="footer-area">
                        <p>&copy; Version 1.0.91</p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default Footer;






