import React, { Component } from "react";
import { activateAuthLayout } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkCategory, setNewCategory, addNewCategory } from "../store/category/actions"
import { Badge, Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import DataTable from 'react-data-table-component';
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class CategoryPages extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      id: "",
      name: "",
      icon: "",
      tmpIcon: "",
      description: "",
      status: "",
      priority: "",
      dataDelete: null,
      swal: false, success_dlg: false, dynamic_title: "", 
      dynamic_description: ""
    };
    this.onChangeInput = this.onChangeInput.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onRowClicked = this.onRowClicked.bind(this)
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    this.buttonEdit = this.buttonEdit.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
  }

  componentDidMount() {
    let props = this.props
    this.props.activateAuthLayout();
    this.props.checkCategory(this.props.history, {
      page: props.currentPage,
      size: props.size,
      status: props.status
    }, true)
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  //menampilkan data sebanyak 10/20/50/100
  async changeRowsPerPage(size){
    await this.props.setNewCategory("size", size)
    let props = this.props
    await this.props.checkCategory(this.props.history, {
      page: props.currentPage,
      size: size,
      status: props.status
    }, false)
  }

  async onChangePage(page){
    await this.props.setNewCategory("page", page)
    let props = this.props
    await this.props.checkCategory(this.props.history, {
      page: page,
      size: props.size,
      status: props.status
    }, false)
  }

  onRowClicked(state){
  }

  async onShowModalAdd(){
    await this.props.setNewCategory("toggleAdd", !this.props.toggleAdd)
  }

  async buttonEdit(e, data){
    this.setState({
      id: data.id,
      name: data.name,
      description: data.description,
      status: data.status,
      tmpIcon: data.icon,
      priority: data.priority
    })
    await this.props.setNewCategory("toggleEdit", !this.props.toggleEdit)
  }

  buttonDelete(data){
    
    this.setState({ swal: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'Your file has been deleted.' })
    this.props.addNewCategory("/admin/category", {}, "DELETE", data.id)
  }
  
  render() {
    const columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px"
      },
      {
        name: "Name",
        selector: "name",
        width: "150px"
      },
      {
        name: "Icon",
        selector: "icon",
        width: "70px",
        cell: row => {
          return <img src={row.icon} alt={row.icon} />
        }
      },
      {
        name: "Description",
        selector: "description",
        width: "500px"
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        cell: row => {
          return (
            row.status === 0 || row.status === false ? <Badge color="danger">Inactive</Badge> :
            <Badge color="success" style={{color: "black"}}>Active</Badge> 
          )
        },
        width: "100px"
      },
      {
        name: "Priority",
        selector: "priority",
        center: true,
        width: "100px",
        cell: row => {
          return(
            <b>{row.priority}</b>
          )
        }
      },
      {
        name: "Action",
        center: true,
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(e, row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                swal: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
    ]
    return (
      <React.Fragment>
        <NotificationMessage />
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i>Add Category</Button>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    title="Category"
                    columns={columns}
                    data={this.props.categorys}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    // onRowClicked={this.onRowClicked}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAdd />
        <ModalEdit onChangeInput={this.onChangeInput} 
          data={{name: this.state.name, icon: this.state.icon, description: this.state.description, status: this.state.status, id: this.state.id, tmpIcon: this.state.tmpIcon, priority: this.state.priority}}
        />

        {this.state.swal ? 
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => this.buttonDelete(this.state.dataDelete)}
          onCancel={() => this.setState({ swal: false, error_dlg: true, dynamic_title: 'Cancelled', dynamic_description: 'Your imaginary file is safe :)' })} >
          You won't be able to revert this!
          </SweetAlert> : null
        }

        {this.state.success_dlg ?
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ success_dlg: false })}  >
            {this.state.dynamic_description}
            </SweetAlert> : null}

        {this.state.error_dlg ?
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.dynamic_description}
          </SweetAlert> : null}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { currentPage, size, status, categorys, totalItems, toggleAdd, toggleEdit } = state.Category
  return { currentPage, size, status, categorys, totalItems, toggleAdd, toggleEdit }
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkCategory, setNewCategory, addNewCategory })(CategoryPages));
