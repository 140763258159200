import React from "react"
import ReactPlayer from "react-player"

const VideoPlayer = ( {url} ) => {
  return (
    <div className='relative'>
      <ReactPlayer
        className='absolute top-0 left-0'
        url={url}
        width='100%'
        height='100%'
        controls={true}
      />
    </div>
  )
}

export default VideoPlayer