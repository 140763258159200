import { CHECK_MUTASI_BANK, SET_MUTASI_BANK, SET_DATA_MUTASI_BANK } from "./actionTypes"

const initialState = {
  mutasi: [], currentPage: 1, totalItems: 0, totalPages: 0, size: 10
}

const mutasi = (state = initialState, action) => {
  switch(action.type){
    case CHECK_MUTASI_BANK:
      state = {
        ...state
      }
      break;
    case SET_MUTASI_BANK:
      state = {
        ...state,
        mutasi: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_DATA_MUTASI_BANK:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default mutasi