import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_BRAND, } from './actionTypes';
import {  setBrand, setNewBrand } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* brand({payload: {history, state, flag}}) {
    try {
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const response = yield call(callGetCategory, "/admin/brand", state)
        const respCat = yield call(hitAPIWasmarket, "/admin/category/all", {}, "GET")
        yield put(setNewBrand("arrCategory", respCat))
        yield put(setBrand(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setBrand({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0
            }))
        }
    }
}

export function* watchUserBrand() {
    yield takeEvery(CHECK_BRAND, brand)
}

function* brandSaga() {
    yield all([fork(watchUserBrand)]);
}

export default brandSaga;