import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setNotif, changePassword } from "../store/actions"
import { checkUsername } from "../../Category/store/username/actions"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class ChangePasswordPages extends Component{
  constructor(props){
    super(props)
    this.state = {
      old_pwd: "",
      new_pwd: "",
      repeat_new_pwd: "",
    }
    this.onChangeInput = this.onChangeInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
    this.props.activateAuthLayout()
    if(!this.props.email){
      this.props.checkUsername(this.props.history)
    }
  }

  onChangeInput(e){
    const {name, value} = e.target
    this.setState({
      [name]: value
    })
  }

  async handleSubmit(){
    await this.props.changePassword("/admin/change-password", {
      email: this.props.email,
      old_password: this.state.old_pwd,
      new_password: this.state.new_pwd
    }, "PUT")
  }

  render() {
    return (
        <React.Fragment>
          <NotificationMessage />
            <Row>
              <Col style={{margin: "0 auto"}} className="stretched_card align-self-center" xl="8" lg="8" md="12" sm="12">
                <Card>
                  <CardBody>
                    <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
                      <div className="lock-form-head">
                        <h4>Change Password</h4>
                      </div>
                      <div className="login-form-body text-center">
                        <div className="mb-0 text-left">
                          <AvField name="old_pwd" label="Enter old password" value={this.state.old_pwd} placeholder="Enter Old Password" type="password" onChange={this.onChangeInput} validate={
                            {
                              required: {value: true, errorMessage: "Old password must required"}, 
                              minLength: {value: 4, errorMessage: "Your password must be between 4 and 8 characters"},
                              maxLength: {value: 8, errorMessage: 'Your password must be between 4 and 8 characters'}
                            }
                          }/>
                          <AvField name="new_pwd" label="Enter new password" value={this.state.new_pwd} placeholder="Enter New Password" type="password" onChange={this.onChangeInput} validate={
                            {
                              required: {value: true, errorMessage: "New password must required"}, 
                              minLength: {value: 4, errorMessage: "Your password must be between 4 and 8 characters"},
                              maxLength: {value: 8, errorMessage: 'Your password must be between 4 and 8 characters'}
                            }
                          }/>
                          <AvField name="repeat_new_pwd" label="Confirm new password" value={this.state.repeat_new_pwd} placeholder="Confirmation New Password" type="password" onChange={this.onChangeInput} validate={
                            {
                              required: {value: true, errorMessage: "Confirmation password must required"}, 
                              match:{value: "new_pwd", errorMessage: "Your new password is not same"}
                            }
                          }/>
                          <div className="submit-btn-area">
                            <Button color="primary" className="btn btn-primary" type="submit">Submit <i className="ti-arrow-right"></i></Button>
                          </div>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          {this.props.notifSuccess ?
            <SweetAlert
              success
              title="SUCCESS"
              onConfirm={() => this.props.setNotif("notifSuccess", false)}  >
              {this.props.desc}
            </SweetAlert> : null}
          {this.props.notifFailed ?
            <SweetAlert
              error
              title="FAILED"
              onConfirm={() => this.props.setNotif("notifFailed", false)}  >
              {this.props.desc}
            </SweetAlert> : null}
        </React.Fragment>
    );
}
}

const mapStatetoProps = state => {
  const { notifSuccess, notifFailed, desc } = state.ChangePassword
  const { email } = state.Username
  return { notifSuccess, notifFailed, desc, email };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, setNotif, changePassword, checkUsername })(ChangePasswordPages));