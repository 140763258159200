import { CHECK_LOGS_MONIT, SET_LOGS_MONIT, SET_DATA_LOGS_MONIT } from "./actionTypes"

export const checkLogMonit = (history, state, flag) => {
  return {
    type: CHECK_LOGS_MONIT,
    payload: {history, state, flag}
  }
}

export const setLogMonit = (props) => {
  return {
    type: SET_LOGS_MONIT,
    payload: props
  }
}

export const setDataLogsMonit = (name, data) => {
  return {
    type: SET_DATA_LOGS_MONIT,
    payload: {name, data}
  }
}