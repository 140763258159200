import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment"
import { activateAuthLayout } from "../../../../store/actions";
import { checkTag, setDataTag, cudTag } from "../store/tag/actions"
import NotificationMessage from "../../../NotificationMessage/Pages/Notification"
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"

class TagPages extends Component{
    constructor(props){
        super(props)
        this.state = {
            delTag: false,
            tag: "",
            name: "",
            id: ""
        }
        this.buttonDelete = this.buttonDelete.bind(this)
        this.buttonEdit = this.buttonEdit.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    componentDidMount(){
        this.props.activateAuthLayout()
        this.props.checkTag(this.props.history, {
            page: this.props.currentPage,
            size: this.props.size
        }, true)
    }

    async onChangeInput(e){
        const { name, value } = e.target
        await this.setState({
            [name]: value
        })
    }

    async buttonDelete(data){
        await this.props.cudTag("/admin/tag", {}, "DELETE", data.id)
        await this.setState({ delTag: false })
    }

    async buttonEdit(data){
        await this.setState({ id: data.id, name: data.name })
        await this.props.setDataTag("toggleEdit", !this.props.toggleEdit)
    }

    render(){
        const columns = [
            {
                name: "ID",
                selector: "id",
                width: "70px"
            },
            {
                name: "Name",
                selector: "name",
                width: "200px"
            },
            {
                name: "Created At",
                selector: "created_at",
                cell: (row) => {
                    return row.created_at ? moment(row.created_at).format("YYYY-MM-DD HH:mm:ss") : <span></span>
                },
                width: "200px",
            },
            {
                name: "Updated At",
                selector: "updated_at",
                cell: (row) => {
                    return row.updated_at ? moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss") : <span></span>
                },
                width: "200px",
            },
            {
                name: "Action",
                sortable: true,
                center: true,
                cell: (row) => {
                    return (
                    <>
                        <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={() => this.buttonEdit(row)}></i>
                        <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                            delTag: true,
                            tag: row
                        })}></i>
                    </>
                    )
                }
            },
        ]
        return (
            <React.Fragment>
                <NotificationMessage />
                <Container style={{width: "80%"}}>
                <h2 className="card_title mb-3">Tags</h2>
                    <Row>
                        <Col className="stretched_card">
                            <Card style={{width: "80%"}}>
                                <CardBody>
                                    <Container>
                                        <Row>
                                            <Col xl="2" lg="3" md="3" sm="6">
                                                <Button onClick={() => this.props.setDataTag("toggleAdd", !this.props.toggleAdd)}>
                                                    <i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i> Add Tag
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                    {/* <Container className="mt-2">
                                        <Row>
                                            <Col xl="5" lg="5" md="12" sm="12">
                                                <div className="input-group">
                                                    <p style={{margin: "auto 0"}} className="mr-1">From</p>
                                                    <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                                                    <p style={{margin: "auto 0"}} className="ml-1 mr-1">To</p>
                                                    <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container> */}
                                    <Container>
                                        <DataTable
                                            noHeader
                                            columns={columns}
                                            data={this.props.tags}
                                            pagination
                                            responsive={true}
                                            paginationTotalRows={this.props.totalItems}
                                            paginationRowsPerPageOptions={[10,20,50,100]}
                                            onChangeRowsPerPage={this.changeRowsPerPage}
                                            onChangePage={this.onChangePage}
                                            paginationServer={true}
                                        />
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ModalAdd />
                <ModalEdit 
                    data={{id: this.state.id, name: this.state.name}}
                    onChangeInput={this.onChangeInput}
                />

                {this.props.confirm ?
                    <SweetAlert
                        success
                        title="SUCCESS"
                        onConfirm={() => this.props.setDataTag("confirm", false)}  >
                        {this.props.msgSuccess}
                    </SweetAlert> : null
                }

                {this.state.delTag ? 
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.buttonDelete(this.state.tag)}
                        onCancel={() => this.setState({ delTag: false })} >
                        Are you sure to delete: <b>{this.state.tag.name}</b>?
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { tags, currentPage, totalItems, size, toggleAdd, toggleEdit, confirm, msgSuccess } = state.Tag
    return { tags, currentPage, totalItems, size, toggleAdd, toggleEdit, confirm, msgSuccess };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkTag, setDataTag, cudTag })(TagPages));
