import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_CASH_OUT } from '../cashout/actionTypes';
import {  setCashOut, setDataCashOut } from '../cashout/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updateCashOut({payload: {url, state, method, id}}) {
    try {
        const { name, status, method: methodBank, from, to, tmpStatus, remark } = state
        let sendMethod = methodBank === "-1" ? "" : methodBank
        const responseStatus = yield call(hitAPIWasmarket, url, {status, remark}, method, id)
        const response = yield call(callGetCategory, "/admin/cashout", {page: 1, size: 10, name, method: sendMethod, from, to, status: tmpStatus === "-1" ? "" : tmpStatus })
        yield put(setCashOut(response))
        yield put(setDataCashOut("confirm", true))
        yield put(setDataCashOut("msgSuccess", responseStatus.data))
    } catch (error) {
    }
}

export function* watchUpdateCashOut() {
    yield takeEvery(UPDATE_CASH_OUT, updateCashOut)
}

function* updateCashOutSaga() {
    yield all([fork(watchUpdateCashOut)]);
}

export default updateCashOutSaga;