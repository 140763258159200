import { CHECK_STORE_BADGE, SET_STORE_BADGE, SET_CHANGE_STORE_BADGE } from "./actionTypes"

const initialState = {
  storeBadges: [], badges: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, toggleAdd: false,
  toggleEdit: false,
}

const storeBadge = (state = initialState, action) =>{
  switch(action.type){
    case CHECK_STORE_BADGE:
      state = {
        ...state
      }
      break;
    case SET_STORE_BADGE:
      state = {
        ...state,
        storeBadges: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        badges: action.payload.dataBadges,
      }
      break;
    case SET_CHANGE_STORE_BADGE:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default storeBadge