import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label } from 'reactstrap';
import { setNewBadge, addNewBadge } from "../store/badge/actions"
import { SketchPicker } from 'react-color'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios"
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalAdd extends Component{
  constructor(props){
    super(props)
    this.state = { 
      name: "",
      icon: "",
      description: "",
      color: "#222",
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeComplete = this.handleChangeComplete.bind(this)
  }

  async toggle(){
    await this.props.setNewBadge("toggleAdd", !this.props.toggleAdd)
  }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async handleSubmit(){
    if(!this.state.icon){
      await this.setState({
        error_dlg: true,
        detail: "Icon is empty"
      })
    }else{
      await this.setState({
        expRight: true
      })
      var FormData = require('form-data');
      const fd = new FormData();
      fd.append("file", this.state.icon, this.state.icon.name)

      const config = {
        url: BASE_URL_UPLOAD,
        method: "POST",
        data: fd
      }
      const result = await axios(config)
      let obj = {
        name: this.state.name,
        icon: result.data.imageurl,
        description: this.state.description,
        color: this.state.color,
      }
      await this.props.addNewBadge("/admin/badge", obj, "POST")
      await this.setState({
        name: "",
        description: "",
        icon: "",
        color: "#FFF",
      })
      await this.toggle()
      await this.setState({
        expRight: false
      })
    }
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Badge</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmit}>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter name" name="name" value={this.state.name} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label>Photo</Label>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="textarea" placeholder="Enter description" name="description" value={this.state.description} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <Label sm="12">Color</Label>
            <FormGroup style={{display: "flex", justifyContent: "center"}}>
              <div >
                <Col sm="12">
                    {/* <AvField required type="text" placeholder="Enter type" name="type" value={this.state.type} onChange={this.onChangeInput} /> */}
                  <SketchPicker color={this.state.color} onChangeComplete={this.handleChangeComplete}/>
                </Col>
              </div>
            </FormGroup>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              <LaddaButton
                loading={this.state.expRight}
                data-style={EXPAND_RIGHT}
                className="btn btn-primary"
              >Save changes</LaddaButton>
            </ModalFooter>
          </AvForm>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }

}

const mapStatetoProps = state => {
  const { toggleAdd } = state.Badge
  return { toggleAdd };
}


export default withRouter(connect(mapStatetoProps, { setNewBadge, addNewBadge })(ModalAdd));