import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Row, Col, FormGroup, Label, Card } from 'reactstrap';
import { setNewBrandP2P, addNewBrandP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { uploadFoto } from '../../../helpers/authUtils';

class ModalAddBrandP2P extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      tmpIcon: "",
      status: "0",
      expRight: false,
      error_dlg: false,
      tmpBanner: "",
      customError: "",
      type: "",
      category: "",
      tutorial: "",
      disable: true
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewBrandP2P("toggleAdd", !this.props.toggleAdd)
    this.setState({
      name: "",
      icon: "",
      tmpIcon: "",
      category: "",
      type: "",
      tutorial: "",
      disable: true
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })

      if(this.state.category){
        await this.setState({
          disable: false
        })
      }
    }
  }

  async submitForm(){
    try {

      if(!this.state.icon){
        await this.setState({
          error_dlg: true,
          customError: "Icon is empty!"
        })
      } else{
        await this.setState({
          expRight: true
        })

        const resultIcon = await uploadFoto(this.state.icon)
      
        let obj = {
          name: this.state.name,
          icon: resultIcon,
          type: this.state.type,
          category_id: this.state.category,
          trade_tutorial: this.state.tutorial
        }
      
        await this.props.addNewBrandP2P("/admin/p2p/brand", obj, "POST")
        this.toggle()
      }
        
    } catch (error) {
      console.log(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Brand</ModalHeader>
          <AvForm onValidSubmit={this.submitForm}>
          <FormGroup>
              <Row>
              <Col sm="6">
            <Label sm="12">Type</Label>
              <AvField required type="select" name="type" className="ml-2" onChange={this.onChangeInput} id="exampleSelect">
                <option disabled selected value="">Select type</option>
                <option value="UNIT">UNIT</option>
                <option value="NONUNIT">NONUNIT</option>
              </AvField>
              </Col>
              <Col sm="6">
              <Label sm="12">Category</Label>
                <AvField required type="select" style={{marginLeft: "-10px"}} name="category" value={this.state.category} onChange={this.onChangeInput}>
                  <option disabled selected value="">Select Category</option>
                    {!this.props.categori ? 
                      ""
                      :
                      this.props.categori.map(el => {
                        return <option value={el._id}>{el.name}</option>
                    })
                  }
                </AvField>
              </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
              <Label>Name</Label>
                <AvField required disabled={this.state.disable} type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput}/>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <FormGroup>
                  {this.state.tmpIcon ?
                  <>
                  <Card style={{width: '12rem'}}>
                    <img alt="icon" src={this.state.tmpIcon}/>
                      <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                  </Card>
                  </>
                  :
                  "" }
                </FormGroup>
                <CustomInput disabled={this.state.disable} type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
              <Label>Trade Tutorial</Label>
                <AvField required disabled={this.state.disable} rows={10} type="textarea" placeholder="Type some tutorial..." name="tutorial" value={this.state.tutorial} onChange={this.onChangeInput}/>
              </Col>
            </FormGroup>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
          </AvForm>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd, categori } = state.BrandP2P
  return { toggleAdd, categori };
}

export default withRouter(connect(mapStatetoProps, { setNewBrandP2P, addNewBrandP2P })(ModalAddBrandP2P))