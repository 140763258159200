import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_USERNAME } from './actionTypes'
import {  setUsername } from './actions';
//============ Authentication Functions =============*
import { checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* userName({payload:{history}}) {
    try {
        const response = yield call(checkToken)
        yield put(setUsername(response))
        if(response.type === "cs" || response.type === "Super"){
            history.push("/banner")
        } else {
            history.push("/manage-user")
        }
    } catch (error) {
        localStorage.removeItem('token')
    }
}

export function* watchUsernameOrder() {
    yield takeEvery(CHECK_USERNAME, userName)
}

function* usernameSaga() {
    yield all([fork(watchUsernameOrder)]);
}

export default usernameSaga;