import { CHECK_CATEGORY, SET_CATEGORY, SET_CHANGE_CATEGORY } from "./actionTypes"

const initialState = {
  categorys: [], currentPage: 1, totalItems: 0, totalPages: 0, page: 1, size: 10, toggleAdd: false,
  toggleEdit: false, category: { name: "", icon: "", description: ""}
}

const category = (state = initialState, action) => {
  switch(action.type){
    case CHECK_CATEGORY:
      state = {
        ...state
      }
      break;
    case SET_CATEGORY:
      state = {
        ...state,
        categorys: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_CHANGE_CATEGORY:
      const {name, data} = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default category