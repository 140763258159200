import { CHECK_BRAND_P2P, SET_BRAND_P2P, SET_CHANGE_BRAND_P2P, ADD_BRAND_P2P } from "./actionTypes"

export const checkBrandP2P = (history, state, flag) => {
  return {
    type: CHECK_BRAND_P2P,
    payload: {history, state, flag}
  }
}

export const setBrandP2P = (props) => {
  return {
    type: SET_BRAND_P2P,
    payload: props
  }
}

export const setNewBrandP2P = (name, data) => {
  return {
    type: SET_CHANGE_BRAND_P2P,
    payload: {name, data}
  }
}

export const addNewBrandP2P = (url, state, method, id) => {
  return {
    type: ADD_BRAND_P2P,
    payload: {url, state, method, id}
  }
}