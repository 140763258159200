import { CHECK_CATEGORY_P2P, SET_CATEGORY_P2P, SET_CHANGE_CATEGORY_P2P, ADD_CATEGORY_P2P } from "./actionTypes"

export const checkCategoryP2P = (history, state, flag) => {
  return {
    type: CHECK_CATEGORY_P2P,
    payload: {history, state, flag}
  }
}

export const setCategoryP2P = (props) => {
  return {
    type: SET_CATEGORY_P2P,
    payload: props
  }
}

export const setNewCategoryP2P = (name, data) => {
  return {
    type: SET_CHANGE_CATEGORY_P2P,
    payload: {name, data}
  }
}

export const addNewCategoryP2P = (url, state, method, id) => {
  return {
    type: ADD_CATEGORY_P2P,
    payload: {url, state, method, id}
  }
}