import { CHANGE_PASSWORD, SET_RESPONSE } from "./actionTypes"

const initialState = {
  notifSuccess: false, notifFailed: false, desc: ""
}

const brand = (state = initialState, action) => {
  switch(action.type){
    case CHANGE_PASSWORD:
      state = {
        ...state
      }
      break;
    case SET_RESPONSE:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default brand