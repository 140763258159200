import { CHECK_SETTING, SET_SETTING, SET_CHANGE_SETTING, ADD_SETTING } from "./actionTypes"

export const checkSetting = (history, state, flag) => {
  return {
    type: CHECK_SETTING,
    payload: {history, state, flag}
  }
}

export const setSetting = (props) => {
  return {
    type: SET_SETTING,
    payload: props
  }
}

//edit property di reducer berdasarkan nama initialState
export const setNewSetting = (name, data) => {
  return {
    type: SET_CHANGE_SETTING,
    payload: {name, data}
  }
}

export const addNewSetting = (url, state, method, id) => {
  return {
    type: ADD_SETTING,
    payload: {url, state, method, id}
  }
}