import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setNewProductMaster, addNewProductMaster, importNewProduct } from "../store/product/actions"
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, FormText } from 'reactstrap';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvForm } from "availity-reactstrap-validation";
import * as XLSX from "xlsx"

class ModalImport extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      excel: "",
      isPickCategory: false,
      brands: [],
      json: "",
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.readExcel = this.readExcel.bind(this)
  }

  async toggle(){
    await this.props.setNewProductMaster("toggleImport", !this.props.toggleImport)
    await this.setState({
      excel: "",
      json: ""
    })
  }

  async onChangeInput(e){
    const file = e.target.files[0]
        
        if(file) {
            this.readExcel(file)
        } else {
            console.log("GAGAL")
        }
  }

  readExcel(file){
    const promise = new Promise((resolve, reject) => {

      const fileReader = new FileReader()

      fileReader.readAsArrayBuffer(file)
      console.log(fileReader)
      fileReader.onload = (e) => {
          const bufferArray = e.target.result

          const workbook = XLSX.read(bufferArray, { type: "buffer" })

          const worksheetName = workbook.SheetNames[0]

          const worksheet = workbook.Sheets[worksheetName]

          const data = XLSX.utils.sheet_to_json(worksheet)
          this.setState({
            json: data
          })
          console.log(data)
          console.log("BERHASIL 1")
      }

      fileReader.onerror = (error) => {
          console.log(error, "errere")
          reject(error)
      } 
  })

  promise
  .then((data) => {
      // setJsonFIle(data)
      console.log(data)
      console.log("BERHASIL 2")
  })
  .catch((err) => {
      console.log(err, "error when excel parse excel to json")
  })
  }

  async submitForm(){
    await this.props.importNewProduct("/admin/product-master/import", this.state.json, "POST")
    await this.setState({
      expRight: false
    })
    await this.toggle()
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleImport} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Import Product</ModalHeader>
            <AvForm onValidSubmit={this.submitForm}>
            <FormGroup>
              <Col sm="12">
                <Label >Excel</Label>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.xlsx']} name="excel" value={this.state.excel} onChange={this.onChangeInput} />
                <FormText>
                  Hanya upload file berekstensi .xlsx
                </FormText>
                </Col>
              </FormGroup>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            <LaddaButton
                loading={this.state.expRight}
                data-style={EXPAND_RIGHT}
                className="btn btn-primary"
              >Upload</LaddaButton>
            
          </ModalFooter>
            </AvForm>
        </Modal>

        {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleImport } = state.ProductMaster
  return { toggleImport };
}

export default withRouter(connect(mapStatetoProps, { setNewProductMaster, addNewProductMaster, importNewProduct })(ModalImport));