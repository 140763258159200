import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setDataKYC, updateKYC } from '../../store/kyc/getData/actions'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col, Badge } from 'reactstrap';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';

class ModalEditKYC extends Component{
  constructor(props){
    super(props)
    this.state = {
      seeSL: false,
      status: "",
      remark: "",
      expRight: false,
      complete: false,
      confirm: false
    }
    this.toggle = this.toggle.bind(this)
    this.submitRejectDisband = this.submitRejectDisband.bind(this)
    this.submitApprove = this.submitApprove.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
  }

  toggle(){
    this.props.setDataKYC("toggleEdit", !this.props.toggleEdit)
  }

  onChangeInput(e){
    const {value, name} = e.target
    this.setState({
      [name]: value
    })
  }

  async submitRejectDisband(){
    await this.setState({ seeSL: false})
    let send = {...this.state}
    delete send.seeSL
    delete send.expRight
    delete send.complete
    delete send.confirm
    await this.props.updateKYC("/admin/kyc", send, "PUT", this.props.kyc.id)
    await this.props.setDataKYC("toggleEdit", !this.props.toggleEdit)
    await this.setState({ remark: "", complete: true})
  }

  async submitApprove(){
    await this.setState({
      expRight: true,
      status: "APPROVE"
    })
    let send = {...this.state}
    delete send.seeSL
    delete send.expRight
    delete send.complete
    delete send.confirm
    await this.props.updateKYC("/admin/kyc", send, "PUT", this.props.kyc.id)
    await this.setState({
      expRight: false,
      complete: true,
      confirm: false
    })
    await this.props.setDataKYC("toggleEdit", !this.props.toggleEdit)
  }

  render(){
    return(
      <React.Fragment>
        <Modal className="modal-xl" isOpen={this.props.toggleEdit} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} >KYC {!this.props.kyc ? "" : this.props.kyc.fullname}</ModalHeader>
          <ModalBody  style={{backgroundColor: "#f2f2f2"}}> 
            {!this.props.kyc ? "" : (
              <Row>
                <Col>
                <span><h5>Data KYC</h5></span>
                <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">User ID</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.user_id ? <b>Not Yet</b> : <b>{this.props.kyc.user_id}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">National ID</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.national_id ? <b>Not Yet</b> : <b>{this.props.kyc.national_id}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Full Name</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.fullname ? <b>Not Yet</b> : <b>{this.props.kyc.fullname}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Address Domicile</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.address_domicile ? <b>Not Yet</b> : <b>{this.props.kyc.address_domicile}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Business Address</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.business_address ? <b>Not Yet</b> : <b>{this.props.kyc.business_address}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Business Province</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.business_province ? <b>Not Yet</b> : <b>{this.props.kyc.business_province}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Business City</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.business_city ? <b>Not Yet</b> : <b>{this.props.kyc.business_city}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Business Name</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.business_name ? <b>Not Yet</b> : <b>{this.props.kyc.business_name}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Is Company</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.is_company ? <b>Not Yet</b> : this.props.kyc.is_company === true ? <b>TRUE</b> : <b>FALSE</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Status</span>
                    <span className="traffic-sales-amount">
                      {
                        this.props.kyc.status === "PENDING" ? <Badge color="primary">{this.props.kyc.status}</Badge> :
                        this.props.kyc.status === "APPROVE" ? <Badge color="success" style={{color: "black"}}>{this.props.kyc.status}</Badge> :
                        this.props.kyc.status === "REJECTED" ? <Badge color="danger">{this.props.kyc.status}</Badge> :
                        this.props.kyc.status === "BANNED" ? <Badge color="warning">{this.props.kyc.status}</Badge> :
                        <Badge color="dark">EXPIRED</Badge>
                      }
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                </ul>
                
                <span><h5>Data User</h5></span>
                <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Name User</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.user.name ? <b>Not Yet</b> : <b>{this.props.kyc.user.name}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Email</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.user.email ? <b>Not Yet</b> : <b>{this.props.kyc.user.email}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Phone Number</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.user.phoneNumber ? <b>Not Yet</b> : <b>{this.props.kyc.user.phoneNumber}</b>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                  <li className="traffic-sales-content list-group-item ">
                    <span className="traffic-sales-name">Store Logo</span>
                    <span className="traffic-sales-amount">
                      {!this.props.kyc.user.storeLogo ? <b>Not Yet</b> : <img src={this.props.kyc.user.storeLogo} alt="logoStore" width="100px" height="100px"/>}
                      <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                      </span>
                    </span>
                  </li>
                </ul>
                </Col>
                <Col>
                <span><h5>Data FOTO</h5></span>
                  <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                    <li className="traffic-sales-content list-group-item ">
                      <h3 className="traffic-sales-name">Foto NPWP</h3>
                      <span className="traffic-sales-amount">
                        {!this.props.kyc.photo_npwp ? 
                          <b>Not Yet</b> : 
                          <img src={this.props.kyc.photo_npwp} width="150px" height="300px" alt="npwp"/>
                        }
                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                        </span>
                      </span>
                    </li>
                    <li className="traffic-sales-content list-group-item ">
                      <h3 className="traffic-sales-name">Foto KTP</h3>
                      <span className="traffic-sales-amount">
                        {!this.props.kyc.photo_ktp ? 
                          <b>Not Yet</b> : 
                          <img src={this.props.kyc.photo_ktp} width="150px" height="300px" alt="ktp"/>
                        }
                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                        </span>
                      </span>
                    </li>
                    <li className="traffic-sales-content list-group-item ">
                      <h3 className="traffic-sales-name">Foto SIUP</h3>
                      <span className="traffic-sales-amount">
                        {!this.props.kyc.photo_siup ? 
                          <b>Not Yet</b> : 
                          <img src={this.props.kyc.photo_siup} width="150px" height="300px" alt="siup"/>
                        }
                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                        </span>
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            {this.props.type !== "cs" ? 
                <>
                    <LaddaButton
                        loading={this.state.expRight}
                        onClick={() => this.setState({confirm:true, status: "APPROVE"})}
                        data-style={EXPAND_RIGHT}
                        className="btn btn-primary"
                    >APPROVE</LaddaButton>
                    <Button color="warning" onClick={() => this.setState({status: "REJECT", seeSL: true})}>REJECTED</Button>
                    <Button color="danger" onClick={() => this.setState({status: "BANNED", seeSL: true})}>BANNED</Button>
                </>
            : ""}
          </ModalFooter>
        </Modal>

        {this.state.seeSL ?
          <SweetAlert
            showCancel
            warning
            title={`${this.state.status} : ${this.props.kyc.fullname}`}
            cancelBtnBsStyle="danger"
            confirmBtnBsStyle="success"
            onConfirm={() => this.submitRejectDisband()}
            onCancel={() => this.setState({ seeSL: false })} >
            Input remark for user
            <input type="text" className="form-control" name="remark" value={this.state.remark} placeholder="Input somethink" onChange={this.onChangeInput} />
          </SweetAlert> : null}

          {this.state.confirm ? 
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.submitApprove()}
            onCancel={() => this.setState({ confirm: false })} >
              Are you sure to {this.state.status} with ID: {this.props.kyc.id} and Name: {this.props.kyc.fullname}
            </SweetAlert> : null
          }

          {this.state.complete ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.setState({ complete: false })}  >
            Success update KYC
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit, kyc } = state.KYC
  const { type } = state.Username
  return { toggleEdit, kyc, type };
}

export default withRouter(connect(mapStatetoProps, { setDataKYC, updateKYC })(ModalEditKYC));
