import { CHECK_P2P, SET_P2P, SET_DATA_P2P } from "./actionTypes"

const initialState = {
  p2p: [], dataP2P: "", toggleSee: false, expRight: false, expRightEdit1: false, expRightEdit2: false, confirm: false,
  msgSuccess: "", failedUpdate: false, msgFailed: "", expRightEdit3: false, expRightEdit4: false
}

const p2p = (state = initialState, action) => {
  switch(action.type){
    case CHECK_P2P:
      state = {
        ...state
      }
      break;
    case SET_P2P:
      state = {
        ...state,
        p2p: action.payload
      }
      break;
      case SET_DATA_P2P:
        const { name, data } = action.payload
        state = {
          ...state,
          [name]: data
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default p2p