import { CHECK_LOGS_MONIT, SET_LOGS_MONIT, SET_DATA_LOGS_MONIT } from "./actionTypes"

const initialState = {
  logs: [], detail: "", modalDetail: false
}

const log_monit = (state = initialState, action) =>{
  switch(action.type){
    case CHECK_LOGS_MONIT:
      state = {
        ...state
      }
      break;
    case SET_LOGS_MONIT:
      state = {
        ...state,
        logs: action.payload
      }
      break;
    case SET_DATA_LOGS_MONIT:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default log_monit