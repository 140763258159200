import { CHECK_MANAGEMENT_USER, SET_MANAGEMENT_USER, SET_DATE_MANAGEMENT_USER } from "./actionTypes"

const initialState = {
  users: [], totalItems: 0, totalPages: 0, size: 10, totalSeller: 0, currentPage: 1,
  user: null, toggleEdit: false, totalUser: 0, errorFlag: false, msgError: "", complete: false
}

const managementUser = (state = initialState, action) => {
  switch(action.type){
    case CHECK_MANAGEMENT_USER:
      state = {
        ...state
      }
      break;
    case SET_MANAGEMENT_USER:
      state = {
        ...state,
        users: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        totalSeller: action.payload.totalSeller,
        totalUser: action.payload.totalUser,
      }
      break;
      case SET_DATE_MANAGEMENT_USER:
        const { name, data } = action.payload
        state = {
          ...state,
          [name]: data
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default managementUser