import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_ETALASE_P2P, } from './actionTypes';
import { setEtalaseP2P } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* etalase_p2p({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/p2p/etalase", state)
        yield put(setEtalaseP2P(response))
        // yield put(setNewCategoryP2P("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setEtalaseP2P({
                rows: []
            }))
            // yield put(setNewCategoryP2P("expRight", false))
        }
    }
}

export function* watchEtalaseP2P() {
    yield takeEvery(CHECK_ETALASE_P2P, etalase_p2p)
}

function* etalaseP2PSaga() {
    yield all([fork(watchEtalaseP2P)]);
}

export default etalaseP2PSaga;