import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_DATA_BILLER_P2P } from '../actionTypes';
import {  setP2P, setDataP2P } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updatesDataBillerP2P({payload: {url, state, method, id}}) {
    try {
        const { from, to, buyer_name, seller_name, idpelanggan, status, payment_status, name, statusOrder } = state

        yield call(hitAPIWasmarket, url, {status: statusOrder}, method, id)
        const response = yield call(callGetCategory, "/admin/p2p", {from, to, buyer_name, seller_name, idpelanggan, status, payment_status, name })

        yield put(setP2P(response))
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("confirm", true))
        yield put(setDataP2P("expRightEdit3", false))
        yield put(setDataP2P("expRightEdit4", false))
        let message
        if(statusOrder === "RESEND"){
            message = "Sukses resend callback"
        } else {
            message = "Sukses reversal saldo user"
        }
        yield put(setDataP2P("msgSuccess", message))
    } catch (error) {
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("expRightEdit3", false))
        yield put(setDataP2P("expRightEdit4", false))
        yield put(setDataP2P("failedUpdate", true))
        yield put(setDataP2P("msgFailed", error.message))
    }
}

export function* watchUpdateDataBillerP2P() {
    yield takeEvery(UPDATE_DATA_BILLER_P2P, updatesDataBillerP2P)
}

function* updateDataBillerP2P() {
    yield all([fork(watchUpdateDataBillerP2P)]);
}

export default updateDataBillerP2P;