import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_ETALASE_P2P } from '../actionTypes';
import {  setEtalaseP2P, setNewEtalaseP2P } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* etalaseP2P({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/p2p/etalase", {})
        yield put(setEtalaseP2P(response))
        if(method === "POST"){
          yield put(setNewEtalaseP2P("descSuccess", "Sukses menambah etalase!"))
        }
        if(method === "PUT"){
          yield put(setNewEtalaseP2P("descSuccess", "Sukses mengganti etalase!"))
        }
        if(method === "DELETE"){
          yield put(setNewEtalaseP2P("descSuccess", "Sukses menghapus etalase!"))
        }
        yield put(setNewEtalaseP2P("successDelete", true))
    } catch (error) {
      console.log(error, "saga======")
      yield put(setNewEtalaseP2P("failedDelete", true))
      yield put(setNewEtalaseP2P("descFailed", "Internal server error saga!"))
    }
}

export function* watchUserEtalaseP2P() {
    yield takeEvery(ADD_ETALASE_P2P, etalaseP2P)
}

function* addEtalaseP2PSaga() {
    yield all([fork(watchUserEtalaseP2P)]);
}

export default addEtalaseP2PSaga;