import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_STORE_BADGE } from '../storeBadge/actionTypes';
import { setStoreBadge } from '../storeBadge/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* storeBadgeCUD({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/store-badge", {page: 1, size: 10})
        yield put(setStoreBadge(response))
    } catch (error) {
    }
}

export function* watchCUDStoreBadge() {
    yield takeEvery(ADD_STORE_BADGE, storeBadgeCUD)
}

function* cudStoreBadgeSaga() {
    yield all([fork(watchCUDStoreBadge)]);
}

export default cudStoreBadgeSaga;