import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_LOGS_MONIT, } from './actionTypes';
import { setDataLogsMonit, setLogMonit } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* log_monits({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/logs", state)
        yield put(setLogMonit(response))
        yield put(setDataLogsMonit("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setDataLogsMonit({
                data: []
            }))
            yield put(setDataLogsMonit("expRight", false))
        }
    }
}

export function* watchLogMonit() {
    yield takeEvery(CHECK_LOGS_MONIT, log_monits)
}

function* logMonitSaga() {
    yield all([fork(watchLogMonit)]);
}

export default logMonitSaga;