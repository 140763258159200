import React, { Component } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Input, Label, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import { activateAuthLayout } from "../../../store/actions";
import classnames from 'classnames';
import { checkManagementUser, setDataManagementUser, updateDataManagementUser, exportManagementUser } from "../store/actions"
import { checkKYC, setDataKYC } from "../store/kyc/getData/actions"
import moment from 'moment'
import ModalEditKYC from "./kyc/ModalEdit"
import ModalEditMU from "./managementUser/ModalEdit"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class ManagementUserPages extends Component{
  constructor(props){
    super(props)
    const time = moment().format("YYYY-MM-DD")
    this.state = { 
      withDate: "0",
      activeTab: "1",
      statusMU: "",
      nameMU: "",
      phoneNumberMU: "",
      deleteMU: false,
      descDelete: "",
      confirm: false,
      user: "",
      complete: false,
      from: time,
      to: time,
      failedExport: false
    }
    this.toggleTabs = this.toggleTabs.bind(this)
    this.buttonEditKYC = this.buttonEditKYC.bind(this)
    this.buttonEditMU = this.buttonEditMU.bind(this)
    this.changeRowsPerPageMU = this.changeRowsPerPageMU.bind(this)
    this.changeRowsPerPageKYC = this.changeRowsPerPageKYC.bind(this)
    this.onChangePageMU = this.onChangePageMU.bind(this)
    this.onChangePageKYC = this.onChangePageKYC.bind(this)
    this.changeStatusMU = this.changeStatusMU.bind(this)
    this.searchNameMU = this.searchNameMU.bind(this)
    this.searchPhoneNumberMU = this.searchPhoneNumberMU.bind(this)
    this.buttonDeleteMU = this.buttonDeleteMU.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.exportFile = this.exportFile.bind(this)
    this.refershPageMU = this.refershPageMU.bind(this)
    this.withWithoutDate = this.withWithoutDate.bind(this)
  }

    async componentDidMount(){
      let props = this.props
      await this.props.activateAuthLayout()
      const dateFrom = this.state.from
      const dateTo = this.state.to
      await this.props.checkManagementUser(this.props.history, {
        page: props.currentPageMU,
        size: props.sizeMU,
        from: dateFrom,
        to: dateTo
      }, true)

      await this.props.checkKYC({
        page: props.currentPageKYC,
        size: props.sizeKYC
      })
    }

    async withWithoutDate(e){
        const {value} = e.target
        await this.setState({withDate: value})
        if(value === "0"){
            this.refershPageMU()
        } else {
            await this.props.setDataManagementUser("size", 10)
            await this.props.setDataManagementUser("currentPage", 1)
            await this.props.checkManagementUser(this.props.history, {
                page: this.props.currentPageMU,
                size: this.props.sizeMU,
                status: this.state.statusMU === "2" ? "" : this.state.statusMU,
                name: this.state.nameMU,
                phone_number: this.state.phoneNumberMU
              }, false)
        }
    }

    async exportFile(){
        if(this.props.users.length > 0){
            if(this.state.withDate === "0"){
                await this.props.exportManagementUser({
                    from: this.state.from,
                    to: this.state.to,
                })
            } else {
                await this.props.exportManagementUser({})
            }
        } else {
            await this.setState({failedExport: true})
        }
    }

    async refershPageMU(){
        await this.props.setDataManagementUser("size", 10)
        await this.props.setDataManagementUser("currentPage", 1)
        const time = moment().format("YYYY-MM-DD")
        await this.setState({
            from: time,
            to: time,
            statusMU: "",
            phoneNumberMU: "",
            nameMU: ""
        })
        if(this.state.withDate === "0"){
            await this.props.checkManagementUser(this.props.history, {
                page: this.props.currentPageMU,
                size: this.props.sizeMU,
                from: time,
                to: time
            }, false)
        } else {
            await this.props.checkManagementUser(this.props.history, {}, false)
        }
    }

    async changeDate(e){
        const {value, name} = e.target
        await this.setState({
            [name]: value
        })
        let props = this.props

        await this.props.checkManagementUser(this.props.history, {
            page: props.currentPageMU,
            size: props.sizeMU,
            from: this.state.from,
            to: this.state.to,
            status: this.state.statusMU
        }, true)
    }

    toggleTabs(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    async changeStatusMU(e){
      let result = e.target.value
      let props = this.props
      await this.props.setDataManagementUser("size", 10)
      await this.props.setDataManagementUser("currentPage", 1)
      if(result === "2"){
        await this.setState({
          statusMU: "",
          nameMU: "",
          phoneNumberMU: ""
        })
        if(this.state.withDate === "0"){
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPage,
                size: props.size,
                from: this.state.from,
                to: this.state.to
            }, false)
        } else {
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPage,
                size: props.size
            }, false)
        }
      } else {
        await this.setState({
            statusMU: result,
            nameMU: "",
            phoneNumberMU: ""
        })
        if(this.state.withDate === "0"){
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPage,
                size: props.size,
                status: result,
                from: this.state.from,
                to: this.state.to
            }, false)
        } else {
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPage,
                size: props.size,
                status: result
            }, false)
        }
      }
    }

    async changeRowsPerPageMU(size){
        await this.props.setDataManagementUser("size", size)
        let props = this.props
        if(this.state.withDate === "0"){
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPageMU,
                size: size,
                status: this.state.statusMU,
                name: this.state.nameMU,
                from: this.state.from,
                to: this.state.to
            }, false)
        } else {
            await this.props.checkManagementUser(this.props.history, {
                page: props.currentPageMU,
                size: size,
                status: this.state.statusMU,
                name: this.state.nameMU,
            }, false)
        }
    }

    async changeRowsPerPageKYC(size){
      await this.props.setDataKYC("size", size)
      let props = this.props
      await this.props.checkKYC({
        page: props.currentPageKYC,
        size: size,
      })
    }

    async onChangePageMU(page){
        await this.props.setDataManagementUser("currentPage", page)
        let props = this.props
        if(this.state.withDate === "0"){
            await this.props.checkManagementUser(this.props.history, {
                page: page,
                size: props.sizeMU,
                status: this.state.statusMU,
                name: this.state.nameMU,
                phone_number: this.state.phoneNumberMU,
                from: this.state.from,
                to: this.state.to
            }, false)
        } else {
            await this.props.checkManagementUser(this.props.history, {
                page: page,
                size: props.sizeMU,
                status: this.state.statusMU,
                name: this.state.nameMU,
                phone_number: this.state.phoneNumberMU
            }, false)
        }
    }

    async onChangePageKYC(page){
      await this.props.setDataKYC("currentPage", page)
      let props = this.props
      await this.props.checkKYC({
        page: page,
        size: props.sizeKYC,
      })
    }

    async buttonEditKYC(e, data){
      await this.props.setDataKYC("kyc", data)
      await this.props.setDataKYC("toggleEdit", !this.props.toggleEditKYC)
    }

    async buttonEditMU(data){
      await this.props.setDataManagementUser("user", data)
      await this.props.setDataManagementUser("toggleEdit", !this.props.toggleEditMU)
    }

    async buttonDeleteMU(){
      await this.props.updateDataManagementUser("/admin/user", {}, "DELETE", this.state.user.id)
      await this.setState({complete: true, confirm: false})
    }

    async searchNameMU(){
      await this.setState({
        statusMU: ""
      })
      let props = this.props
      await this.props.setDataManagementUser("size", 10)
      await this.props.setDataManagementUser("currentPage", 1)
      if(this.state.withDate === "0"){
          if(!this.state.nameMU){
            await this.props.checkManagementUser(this.props.history, {
              page: props.currentPage,
              size: props.size,
            }, false)
          } else {
            await this.props.checkManagementUser(this.props.history, {
              from: this.state.from,
              to: this.state.to,
              name: this.state.nameMU
            }, false)
          }
      } else {
        if(!this.state.nameMU){
            await this.props.checkManagementUser(this.props.history, {}, false)
          } else {
            await this.props.checkManagementUser(this.props.history, {name: this.state.nameMU}, false)
          }
      }
    }

    async searchPhoneNumberMU(){
      await this.setState({
        statusMU: "",
        nameMU: ""
      })
      let props = this.props
      await this.props.setDataManagementUser("size", 10)
      await this.props.setDataManagementUser("currentPage", 1)
      if(this.state.withDate === "0"){
          if(!this.state.phoneNumberMU){
            await this.props.checkManagementUser(this.props.history, {
              page: props.currentPage,
              size: props.size,
            }, false)
          } else {
            await this.props.checkManagementUser(this.props.history, {
              page: props.currentPage,
              size: props.size,
              phone_number: this.state.phoneNumberMU,
              from:  this.state.from,
              to: this.state.to,
            }, false)
          }
      } else {
        if(!this.state.phoneNumberMU){
            await this.props.checkManagementUser(this.props.history, {}, false)
          } else {
            await this.props.checkManagementUser(this.props.history, {phone_number: this.state.phoneNumberMU}, false)
          }
      }
    }

  render() {
    const columnsMU = [
        {
            name: "",
            width: "50px",
            cell: (row) => {
                return (
                    <>
                        <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={() => this.buttonEditMU(row)}></i>
                    </>
                )
            }
        },
        {
            name: "Id",
            selector: "id",
            sortable: true,
            width: "80px"
        },
        {
            name: "Name",
            selector: "name",
            sortable: true,
            width: "160px",
        },
        {
            name: "Email",
            selector: "email",
            sortable: true,
            width: "200px"
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            center: true,
            cell: row => {
                return (
                  row.status === 0 || row.status === false ? <Badge className="rounded-pill" color="danger">Non Active</Badge> :
                  <Badge className="rounded-pill" color="success" style={{color: "black"}}>Active</Badge> 
                )
            },
            width: "100px"
        },
        {
            name: "Phone Number",
            selector: "phoneNumber",
            width: "200px",
        },
        {
            name: "CretedAt",
            selector: "createdAt",
            sortable: true,
            center: true,
            width: "180px",
            cell: row => {
              return(
                moment(row.createdAt).format('YYYY/MM/DD HH:mm:ss')
              )
            }
        },
        {
            name: "UpdatedAt",
            selector: "updatedAt",
            sortable: true,
            center: true,
            width: "180px",
            cell: row => {
              return(
                moment(row.updatedAt).format('YYYY/MM/DD HH:mm:ss')
              )
            }
        }
    ]

    const columnsKYC = [
      {
          name: "Id",
          selector: "id",
          sortable: true,
          width: "100px"
      },
      {
          name: "Name",
          selector: "fullname",
          sortable: true,
          width: "100px",
      },
      {
          name: "Email",
          selector: "user.email",
          sortable: true,
          width: "200px"
      },
      {
          name: "Status",
          selector: "status",
          sortable: true,
          center: true,
          cell: row => {
              return (
                <Badge className="rounded-pill" color="danger">{row.status}</Badge>
              )
          },
          width: "100px"
      },
      {
          name: "CretedAt",
          selector: "created_at",
          sortable: true,
          width: "200px",
          cell: row => {
            return(
              moment(row.created_at).format('YYYY/MM/DD HH:mm:ss')
            )
          }
      },
      {
          name: "UpdatedAt",
          selector: "updated_at",
          sortable: true,
          width: "200px",
          cell: row => {
            return(
              moment(row.updated_at).format('YYYY/MM/DD HH:mm:ss')
            )
          }
      },
      {
          name: "Action",
          sortable: true,
          center: true,
          cell: (row) => {
              return (
                  <>
                      <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEditKYC(e, row)}></i>
                  </>
              )
          }
      },
  ]
    return (
      <React.Fragment>
        <NotificationMessage />
        {/* START */}
        <h2 className="card_title mb-3">User Management</h2>
        <Row>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 mt-mob-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="feather ft-users"></i>
                </div>
                <div className="icon_specs">
                  <p>Total Users</p>
                  <span>{this.props.totalUserMU}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="feather ft-activity"></i>
                </div>
                <div className="icon_specs">
                  <p>Total Seller</p>
                  <span>{this.props.totalSeller}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="feather ft-shopping-bag"></i>
                </div>
                <div className="icon_specs">
                  <p>Pengajuan Penjual</p>
                  <span>{this.props.totalItemsKYC}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
      </Row>
      {/* END */}
      <Row>
        <Col className="stretched_card">
          <Card>
            <CardBody>
              <Container className="mt-2">
                <Row>
                  <Col xl="2" lg="3" md="3" sm="6">
                    
                  </Col>
                </Row>
              </Container>
              <Container>
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggleTabs('1'); }} >
                        SEMUA USER
                    </NavLink>
                    </NavItem>


                      <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggleTabs('2'); }} >
                            PENGAJUAN PENJUAL <Badge className="rounded-pill" color="primary" style={{color: "white"}}>{this.props.totalItemsKYC}</Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab} >
                    <TabPane tabId="1" className="p-3">
                    <Row>
                        <Col xl="2" lg="2" md="4" sm="12">
                          <Input type="select" placeholder="write somethink" onChange={this.changeStatusMU}>
                            <option disabled selected value="">Chose Status</option>
                            <option value="0">Not Actived</option>
                            <option value="1">Actived</option>
                            <option value="2">Show All</option>
                          </Input>
                        </Col>
                        <Col xl="5" lg="5" md="12" sm="12">
                          <div className="input-group">
                            <Label className="mt-2 mr-2">Search name:</Label>
                            <Input className="mr-2" type="text" name="nameMU" placeholder="search name" value={this.state.nameMU} onChange={(e) => this.setState({nameMU: e.target.value})}/>
                            <Button color="primary" onClick={this.searchNameMU}>Search</Button>
                          </div>
                        </Col>
                        <Col xl="5" lg="5" md="12" sm="12">
                          <div className="input-group">
                            <Label className="mt-2 mr-2">Phone Number:</Label>
                            <Input className="mr-2" type="text" name="phoneNumberMU" placeholder="search phone number" value={this.state.phoneNumberMU} onChange={(e) => this.setState({phoneNumberMU: e.target.value})}/>
                            <Button color="primary" onClick={this.searchPhoneNumberMU}>Search</Button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xl="2" lg="2" md="12" sm="12">
                        <Input type="select" onChange={this.withWithoutDate}>
                            <option value="0">With date</option>
                            <option value="1">Without date</option>
                          </Input>
                        </Col>
                        {this.state.withDate === "0" ?
                            <Col xl="5" lg="5" md="12" sm="12">
                                <div className="input-group">
                                    <p style={{margin: "auto 0"}} className="mr-1"><b>From</b></p>
                                    <Input type="date" value={this.state.from} name="from" onChange={this.changeDate}/>
                                    <p style={{margin: "auto 0"}} className="ml-1 mr-1"><b>To</b></p>
                                    <Input type="date" value={this.state.to} name="to" onChange={this.changeDate}/>
                                </div>
                            </Col>
                        : ""}
                        <Col xl="4" lg="4" md="12" sm="12">
                            <div className="input-group">
                                <Button color="primary" onClick={this.exportFile} className="mr-2"><i className="fa fa-download"></i> DOWNLOAD</Button>
                                <Button color="primary" onClick={this.refershPageMU}><i className="fa fa-refresh"></i> REFRESH</Button>
                            </div>
                        </Col>
                      </Row>
                      <DataTable
                        noHeader={true}
                        columns={columnsMU}
                        data={this.props.users}
                        pagination
                        responsive={true}
                        paginationTotalRows={this.props.totalItemsMU}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        onChangeRowsPerPage={this.changeRowsPerPageMU}
                        onChangePage={this.onChangePageMU}
                        // onRowClicked={this.onRowClicked}
                        paginationServer={true}
                        className="mt-2"
                      />
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <DataTable
                        noHeader={true}
                        columns={columnsKYC}
                        data={this.props.kycs}
                        pagination
                        responsive={true}
                        paginationTotalRows={this.props.totalItemsKYC}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        onChangeRowsPerPage={this.changeRowsPerPageKYC}
                        onChangePage={this.onChangePageKYC}
                        // onRowClicked={this.onRowClicked}
                        paginationServer={true}
                        className="mt-2"
                      />
                    </TabPane>
                  </TabContent>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalEditKYC />
        <ModalEditMU />
        
        {this.state.confirm ? 
          <SweetAlert
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.buttonDeleteMU()}
            onCancel={() => this.setState({ confirm: false })} >
              Are you sure to <h5>{this.state.descDelete}</h5> ID: <b>{!this.state.user ? "" : this.state.user.id}</b> and Name: <b>{!this.state.user ? "" : this.state.user.name}</b>
          </SweetAlert> : null
        }

        {this.state.complete ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.setState({ complete: false, descDelete: "", user: "" })}  >
            Success {this.state.descDelete} user: {!this.state.user ? "" : this.state.user.name}
          </SweetAlert> : null
        }

        {this.state.failedExport ?
            <SweetAlert
                error
                title="FAILED"
                onConfirm={() => this.setState({failedExport: false})}  >
                Don't have data to download
            </SweetAlert> : null}

        
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { currentPage: currentPageMU, totalItems: totalItemsMU, size: sizeMU, users, totalSeller, toggleEdit: toggleEditMU, totalUser: totalUserMU, errorFlag, msgError} = state.ManagementUser
  const { currentPage: currentPageKYC, totalItems: totalItemsKYC, size: sizeKYC, kycs, toggleEdit: toggleEditKYC } = state.KYC
  const { type } = state.Username
  return { currentPageMU, totalItemsMU, sizeMU, users, totalSeller, currentPageKYC, totalItemsKYC, sizeKYC, kycs, toggleEditKYC, toggleEditMU, totalUserMU, errorFlag, msgError, type };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkManagementUser, checkKYC, setDataKYC, setDataManagementUser, updateDataManagementUser, exportManagementUser })(ManagementUserPages));