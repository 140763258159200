import { CHECK_KOMPLAIN_P2P, SET_KOMPLAIN_P2P, SET_DATA_KOMPLAIN_P2P } from "./actionTypes"

const initialState = {
  komplains: [], dataKomplain: "", toggleSee: false, expRight: false, expRightEdit1: false, expRightEdit2: false
}

const komplain = (state = initialState, action) => {
  switch(action.type){
    case CHECK_KOMPLAIN_P2P:
      state = {
        ...state
      }
      break;
    case SET_KOMPLAIN_P2P:
      state = {
        ...state,
        komplains: action.payload
      }
      break;
      case SET_DATA_KOMPLAIN_P2P:
        const { name, data } = action.payload
        state = {
          ...state,
          [name]: data
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default komplain