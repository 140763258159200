import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_CATEGORY, } from './actionTypes';
import {  setCategory } from './actions';
import {  setUsername } from '../username/actions';
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category({payload: {history, state, flag}}) {
    try {
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const response = yield call(callGetCategory, "/admin/category", state)
        yield put(setCategory(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setCategory({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0
            }))
        }
    }
}

export function* watchUserCategory() {
    yield takeEvery(CHECK_CATEGORY, category)
}

function* categorySaga() {
    yield all([fork(watchUserCategory)]);
}

export default categorySaga;