import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_TRANSACTION } from '../actionTypes';
import {  setTransaction, setDataTransaction } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updateTransaction({payload: {url, state, method, id}}) {
    try {
        const { from, to, page, size, destination, sellerID, buyerID, status, tmpStatus, callback } = state
        const responseStatus = yield call(hitAPIWasmarket, url, {status, callback}, method, id)
        const response = yield call(callGetCategory, "/admin/transaction", {from, to, page, size, destination, sellerID, buyerID, status: tmpStatus })
        yield put(setTransaction(response))
        yield put(setDataTransaction("confirm", true))
        yield put(setDataTransaction("msgSuccess", responseStatus.data))
    } catch (error) {
      console.log(error);
      yield put(setDataTransaction("confirmError", true))
      yield put(setDataTransaction("msgError", error.data))
    }
}

export function* watchUpdateTransaction() {
    yield takeEvery(UPDATE_TRANSACTION, updateTransaction)
}

function* updateTransactionSaga() {
    yield all([fork(watchUpdateTransaction)]);
}

export default updateTransactionSaga;