import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_KYC } from '../getData/actionTypes';
import {  setKYC } from '../getData/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/kyc", {page: 1, size: 10})
        yield put(setKYC(response))
    } catch (error) {
      if(error.responseCode === 404){
        yield put(setKYC({
          rows: [],
          currentPage: 1,
          totalItems: 0,
          totalPages: 0,
      }))
      }
    }
}

export function* watchEditStatusKYC() {
    yield takeEvery(UPDATE_KYC, category)
}

function* editStatusKYCSaga() {
    yield all([fork(watchEditStatusKYC)]);
}

export default editStatusKYCSaga;