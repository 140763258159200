import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { activateAuthLayout } from "../../../store/actions";
import { Container, Button, Row, Col, Card, CardBody, Badge } from "reactstrap";
import { checkBadge, setNewBadge, addNewBadge } from "../store/badge/actions"
import moment from "moment"
import SweetAlert from 'react-bootstrap-sweetalert';
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class BadgePages extends Component {

  constructor(props){
    super(props)
    this.state = {
      deleteConf: false,
      success: false,
      msgSuccess: "",
      dataDelete: "",
      id: "",
      name: "",
      icon: "",
      tmpIcon: "",
      description: "",
      color: ""
    }
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    this.buttonEdit = this.buttonEdit.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
  }

  async componentDidMount(){
    let props = this.props
    await this.props.activateAuthLayout()
    await this.props.checkBadge(this.props.history, {
      page: props.currentPage,
      size: props.size,
    }, true)
  }

  async changeRowsPerPage(size){
    let props = this.props
    await this.props.setNewBadge("size", size)
    await this.props.checkBadge(this.props.history, {
      page: props.currentPage,
      size: size,
    }, false)
  }

  async onChangePage(page){
    await this.props.setNewBadge("page", page)
    let props = this.props
    await this.props.checkBadge(this.props.history, {
      page: page,
      size: props.size,
    }, false)
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  handleChangeComplete = async (color) => {
    await this.setState({ color: color.hex });
  };

  async onShowModalAdd(){
    await this.props.setNewBadge("toggleAdd", !this.props.toggleAdd)
  }

  async buttonEdit(data){
    await this.setState({
      id: data.id,
      name: data.name,
      description: data.description,
      color: data.color,
      tmpIcon: data.icon,
    })
    await this.props.setNewBadge("toggleEdit", !this.props.toggleEdit)
  }

  async buttonDelete(data){
    await this.props.addNewBadge("/admin/badge", {}, "DELETE", data.id)
    await this.setState({ deleteConf: false, msgSuccess: `Success delete ${data.name}`, success: true })
  }

  render() {
    const columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        center: true,
        width: "50px"
      },
      {
        name: "Name",
        selector: "name",
        width: "150px"
      },
      {
        name: "Icon",
        selector: "icon",
        center: true,
        width: "100px",
        cell: row => {
          return <img src={row.icon} alt={row.icon} />
        }
      },
      {
        name: "Description",
        selector: "description",
        width: "350px"
      },
      {
        name: "Color",
        selector: "color",
        center: true,
        width: "100px",
        cell: row => {
          return(
            <Badge style={{backgroundColor: row.color, width: "80px"}}>&nbsp;</Badge>
          )
        }
      },
      {
          name: "CreatedAt",
          selector: "created_at",
          width: "120px",
          center: true,
          cell: row => {
            return (
              moment(row.created_at).format('YYYY/MM/DD HH:mm:ss')
            )
          }
      },
      {
          name: "UpdatedAt",
          selector: "updated_at",
          width: "120px",
          center: true,
          cell: row => {
            return (
              row.updated_at === null ? "" : moment(row.updated).format('YYYY/MM/DD HH:mm:ss')
            )
          }
      },
      {
        name: "Action",
        center: true,
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={() => this.buttonEdit(row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                deleteConf: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
    ]

    return (
      <React.Fragment>
        <NotificationMessage />
        
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i> Add Badge</Button>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    title="Badge"
                    columns={columns}
                    data={this.props.badges}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalAdd />
        <ModalEdit 
          onChangeInput={this.onChangeInput} 
          handleChangeComplete={this.handleChangeComplete}
          data={{id: this.state.id, name: this.state.name, description: this.state.description, icon: this.state.icon, tmpIcon: this.state.tmpIcon, color: this.state.color}}
        />

        {this.state.deleteConf ? 
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => this.buttonDelete(this.state.dataDelete)}
          onCancel={() => this.setState({ deleteConf: false })} >
          Are you sure to delete ID: <b>{this.state.dataDelete.id}</b>, name: <b>{this.state.dataDelete.name}</b>?
          </SweetAlert> : null
        }

        {this.state.success ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.setState({ success: false })}  >
            {this.state.msgSuccess}
          </SweetAlert> : null}

          
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { currentPage, totalItems, size, badges, toggleAdd, toggleEdit } = state.Badge
  return { currentPage, totalItems, size, badges, toggleAdd, toggleEdit, };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkBadge, setNewBadge, addNewBadge })(BadgePages));