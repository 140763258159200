import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, Badge, Row} from 'reactstrap';
import { setDataLogsMonit } from "../store/actions"

class ModalDetailLogsMonit extends Component{
    constructor(props){
        super(props)
        this.state = {}
        this.toggle = this.toggle.bind(this)
    }

    async toggle(){
        await this.props.setDataLogsMonit("modalDetail", !this.props.modalDetail)
    }

    render(){
        return(
            <React.Fragment>
                <Modal className="modal-lg" isOpen={this.props.modalDetail} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Detail Logs</ModalHeader>
                    <ModalBody  style={{backgroundColor: "#f2f2f2"}}>
                        {this.props.detail ?
                            <Row>
                                <Col>
                                    <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">ID</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.detail._id}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span> 
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item" style={{overflow: 'auto', textOverflow: "ellipsis", whiteSpace: 'pre-line'}}>
                                          <ul>
                                            <li className="traffic-sales-name">REQUEST</li>
                                            <li><b>{this.props.detail.request}</b></li>
                                          </ul>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">REQUEST TIME</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.detail.requestTime}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item" style={{overflow: 'auto', textOverflow: "ellipsis", whiteSpace: 'pre-line'}}>
                                          <ul>
                                            <li className="traffic-sales-name">RESPONSE</li>
                                            <li><b>{this.props.detail.response}</b></li>
                                          </ul>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">RESPONSE TIME</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.detail.responseTime}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">STATUS</span>
                                            <span className="traffic-sales-amount">
                                            {this.props.detail.status === 1 ? <Badge color="success">SUCCESS</Badge>: 
                                              <Badge color="danger">FAILED</Badge>
                                            }
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>: ""
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { modalDetail, detail } = state.LogMonit
    return { modalDetail, detail };
}

export default withRouter(connect(mapStatetoProps, { setDataLogsMonit })(ModalDetailLogsMonit));