import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_FIND_NAME, } from '../actionTypes';
import {  setDataTransaction } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* findName({payload: {state}}) {
    try {
      let response = null
      response = yield call(callGetCategory, "/admin/transaction/name", state)
      yield put(setDataTransaction("names",response))
    } catch (error) {
      if(error.responseCode === 404){
        yield put(setDataTransaction("names",[]))
      }
    }
}

export function* watchFindName() {
    yield takeEvery(CHECK_FIND_NAME, findName)
}

function* findNameSaga() {
    yield all([fork(watchFindName)]);
}

export default findNameSaga;