import { NOTIFICATION } from "./actionTypes"
import { BASE_URL_SOCKET } from "../../../helpers/constanta";
import moment from "moment"
import {io} from 'socket.io-client'
import ringtone from "../../../assets/ringtone/nokia_tune.mp3"

const initialState = {
  socket: io.connect(BASE_URL_SOCKET,{ reconnectionDelayMax: 10000}),
  notif: false,
  option: {
    body: `You have new message`,
    icon: "https://hay-images.sgp1.digitaloceanspaces.com/hayumarket-logo.png",
    tag: moment().format("YYYY-MM-DD"),
    sound: ringtone
  },
  unreadMsg: 0,
  name: '',
  alreadyLogin: false,
  receiveNewMessage: false,
}

const message = (state = initialState, action) => {
  switch(action.type){
    case NOTIFICATION:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default message