import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input, Badge, Row } from 'reactstrap';
import { setNewBrand, addNewBrand } from "../store/brand/actions"
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalAdd extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      description: "",
      productCategoryId: "",
      status: "0",
      priority: "",
      prefix: "",
      inputPrefix: [],
      expRight: false,
      error_dlg: false,
      detail: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.inputPrefix = this.inputPrefix.bind(this)
    this.deletePrefix = this.deletePrefix.bind(this)
  }

  async toggle(){
    await this.props.setNewBrand("toggleAdd", !this.props.toggleAdd)
    this.setState({
      name: "",
      icon: "",
      description: "",
      productCategoryId: "",
      priority: ""
    })
  }

  async deletePrefix(idx){
    const newFilter = this.state.inputPrefix.filter((_, index) => index !== idx)
    await this.setState({
        inputPrefix: newFilter
    })
  }

  async inputPrefix(){
    const newArray = [...this.state.inputPrefix, this.state.prefix]
    await this.setState({
        inputPrefix: newArray,
        prefix: ""
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0]
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      let flag = false
      
      if(!this.state.name){
        await this.setState({
          detail: "Form name is empty"
        })
        flag = true
      }else if(!this.state.icon){
        await this.setState({
          detail: "Form icon is empty"
        })
        flag = true
      }else if(!this.state.description){
        await this.setState({
          detail: "Form description is empty"
        })
        flag = true
      }else if(!this.state.productCategoryId){
        await this.setState({
          detail: "Form category is empty"
        })
        flag = true
      }else if(!this.state.priority){
        await this.setState({
          detail: "Form priority is empty"
        })
        flag = true
      } else if(this.state.inputPrefix.length === 0){
        await this.setState({
            detail: "Prefix canot be empty"
          })
          flag = true
      }

      if(flag){
        await this.setState({
          error_dlg: true
        })
      } else {
        await this.setState({
          expRight: true
        })
        var FormData = require('form-data');
        const fd = new FormData();
        fd.append("file", this.state.icon, this.state.icon.name)
  
        const config = {
          url: BASE_URL_UPLOAD,
          method: "POST",
          data: fd
        }
  
        const result = await axios(config)
        let obj = {
          name: this.state.name,
          icon: result.data.imageurl,
          description: this.state.description,
          status: this.state.status,
          productCategoryId: this.state.productCategoryId,
          priority: this.state.priority,
          prefix: this.state.inputPrefix
        }
        await this.props.addNewBrand("/admin/brand", obj, "POST")
        await this.setState({
          name: "",
          icon: "",
          description: "",
          productCategoryId: "",
          priority: "",
          inputPrefix: []
        })
        await this.props.setNewBrand("toggleAdd", !this.props.toggleAdd)
        await this.setState({
          expRight: false
        })
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Brand</ModalHeader>
          <AvForm>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter name brand" name="name" value={this.state.name} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label>Icon</Label>
                <CustomInput required type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="textarea" placeholder="Enter description" name="description" value={this.state.description} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Priority</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Enter priority" name="priority" value={this.state.priority} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Prefix</Label>
              <Col sm="12">
                  <Row>
                  <Col xl="10" lg="10" md="10" sm="10">
                    <AvField  type="text" placeholder="Enter prefix" name="prefix" value={this.state.prefix} onChange={this.onChangeInput} validate={
                        {maxLength: {value: 25, errorMessage: 'Max length 25'}}
                    }/>
                  </Col>
                  <Col xl="1" lg="1" md="1" sm="2">
                    <Button style={{marginLeft: "-20px"}} onClick={() => this.inputPrefix()}>Input</Button>
                  </Col>
                  </Row>
              </Col>
              <Col sm="12">
                {this.state.inputPrefix.map((el, idx) => {
                    return <Badge className="mr-1 mt-2" color="primary">{el} <i class="fa fa-times" style={{cursor: "pointer"}} onClick={() => this.deletePrefix(idx)}></i></Badge>
                })}
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Category</Label>
              <Col sm="12">
              <Input required type="select" name="productCategoryId" onChange={this.onChangeInput} id="exampleSelect">
                <option selected disabled>----- Select Category -----</option>
                {this.props.arrCategory.map(el => {
                  return <option key={el.id} value={el.id}>{el.name}</option>
                })}
              </Input>
              </Col>
            </FormGroup>
          </AvForm>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          {/* <Button type="button" onClick={this.submitForm} color="primary">Save changes</Button> */}
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty Form"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.detail}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd, arrCategory } = state.Brand
  return { toggleAdd, arrCategory };
}

export default withRouter(connect(mapStatetoProps, { setNewBrand, addNewBrand })(ModalAdd));