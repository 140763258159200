import { CHECK_KYC, SET_KYC, UPDATE_KYC, SET_DATA_KYC } from "./actionTypes"

export const checkKYC = (state) => {
  return {
    type: CHECK_KYC,
    payload: {state}
  }
}

export const setKYC = (props) => {
  return {
    type: SET_KYC,
    payload: props
  }
}

export const setDataKYC = (name, data) => {
  return {
    type: SET_DATA_KYC,
    payload: {name, data}
  }
}

export const updateKYC = (url, state, method, id) => {
  return {
    type: UPDATE_KYC,
    payload: {url, state, method, id}
  }
}