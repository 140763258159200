import { CHECK_USER_COUNT, SET_USER_COUNT } from "./actionTypes"

const initialState = {
  user: 0, timestamp: ""
}

const userCount = (state = initialState, action) =>{
  switch(action.type){
    case CHECK_USER_COUNT:
      state = {
        ...state
      }
      break;
    case SET_USER_COUNT:
      state = {
        ...state,
        user: action.payload,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default userCount