import { takeEvery, fork, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { EXPORT_MUTASI_BANK } from '../actionTypes';
//============ Authentication Functions =============*
import { exportFile } from '../../../../helpers/authUtils';
import fileDownload from 'js-file-download';
import moment from "moment"

//============ Dispatch Redux Actions directly =============*
function* exportMutasiBank({payload: {state}}) {
    try {
      const result = yield call(exportFile, "/admin/mutasi-bank/export", state)
      fileDownload(result, "mutasi-bank (" + moment().format("YYYY-MM-DD") + ").xlsx")
    } catch (error) {
      console.log(error);
    }
}

export function* watchExportMutasiBank() {
    yield takeEvery(EXPORT_MUTASI_BANK, exportMutasiBank)
}

function* exportMutasiBankSaga() {
    yield all([fork(watchExportMutasiBank)]);
}

export default exportMutasiBankSaga;