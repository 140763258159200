import { CHECK_CASH_IN, SET_CASH_IN, SET_DATA_CASH_IN, UPDATE_CASH_IN, EXPORT_CASH_IN } from "./actionTypes"

export const checkCashIn = (history, state, flag) => {
  return {
    type: CHECK_CASH_IN,
    payload: {history, state, flag}
  }
}

export const setCashIn = (props) => {
  return {
    type: SET_CASH_IN,
    payload: props
  }
}

export const setDataCashIn = (name, data) => {
  return {
    type: SET_DATA_CASH_IN,
    payload: {name, data}
  }
}

export const updateCashIn = (url, state, method, id) => {
  return {
    type: UPDATE_CASH_IN,
    payload: {url, state, method, id}
  }
}
export const exportCashIn = (state) => {
  return {
    type: EXPORT_CASH_IN,
    payload: {state}
  }
}