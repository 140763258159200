import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col, Input, Label } from 'reactstrap';
import { setDataPostTag, cudPostTag } from "../store/postTag/actions"

class ModalEdit extends Component{
    constructor(props){
        super(props)
        this.state = {
            tags: []
        }
        this.toggle = this.toggle.bind(this)
        this.checkTag = this.checkTag.bind(this)
        this.submitAction = this.submitAction.bind(this)
    }

    async toggle(){
        await this.props.setDataPostTag("toggleEdit", !this.props.toggleEdit)
    }

    async submitAction(){
        const payload = {
            tags: this.props.data.tags
        }
        await this.props.cudPostTag("/admin/post-tag", payload, "PUT", this.props.data.id)
        await this.toggle()
    }

    checkTag(id){
        for(let i = 0; i < this.props.data.tags.length; i++){
            let el = this.props.data.tags[i]
            // let el = this.props.data.tags[i].id
            if(el === id){
                return 1
            }
        }
        return 0
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Edit Tag: {this.props.data.title}</ModalHeader>
                    <ModalBody>
                    <Label for="checkbox2">Tags</Label>
                            <Row>
                                {this.props.tags.length === 0 ? "" : this.props.tags.map((el) => {
                                    return (
                                        this.checkTag(el.id) ? 
                                            <Col xl="4" lg="4" md="6" sm="6" key={el.id}>
                                                <Label check className="ml-3">
                                                    <Input type="checkbox" checked value={el.id} name="tags" onChange={this.props.onChangeInput}/>
                                                        {el.name}
                                                </Label>
                                            </Col> :
                                            <Col xl="4" lg="4" md="6" sm="6" key={el.id}>
                                                <Label check className="ml-3">
                                                    <Input type="checkbox" value={el.id} name="tags" onChange={this.props.onChangeInput}/>
                                                        {el.name}
                                                </Label>
                                            </Col>
                                    )
                                })}
                            </Row>
                            <ModalFooter>
                    <Button color="secondary" className="text-dark" onClick={this.toggle}>Cancel</Button>
                    <Button color="success" className="text-dark" onClick={this.submitAction}>Submit</Button>
                </ModalFooter>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { toggleEdit, tags } = state.PostTag
    return { toggleEdit, tags };
}

export default withRouter(connect(mapStatetoProps, { setDataPostTag, cudPostTag })(ModalEdit));