import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Col, FormGroup, Label, Row, Badge, CustomInput } from 'reactstrap'
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import SweetAlert from 'react-bootstrap-sweetalert';
import VideoPlayer from "./VideoPlayer"
import { cudPost } from "../../store/post/actions"
import {BASE_URL_UPLOAD} from "../../../../../helpers/constanta"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import axios from 'axios'

class FormAddComponent extends Component{
    constructor(props){
        super(props)
        this.state = { 
            video_url: "",
            image_url: "",
            status: "",
            chose_input: "",
            error_dlg: false,
            detail: "",
            confirmDelete: false,
            data: "",
            expRight: false
        }
        this.submitForm = this.submitForm.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.buttonDelete = this.buttonDelete.bind(this)
    }

    async submitForm(){
        if(!this.state.image_url && !this.state.video_url){
            await this.setState({
                error_dlg: true,
                detail: "Image is empty"
            })
        } else {
            await this.setState({expRight: true})
            if(this.state.image_url){
                var FormData = require('form-data');
                const fd = new FormData();
                fd.append("file", this.state.image_url, this.state.image_url.name)

                const config = {
                    url: BASE_URL_UPLOAD,
                    method: "POST",
                    data: fd
                }
                const result = await axios(config)
                let obj = {
                    post_id: this.props.data.id,
                    status: this.state.status,
                    image_url: result.data.imageurl,
                }
                await this.props.cudPost("/admin/post-content", obj, "POST")
                await this.setState({
                    status: "",
                    image_url: "",
                    chose_input: "",
                    video_url: "",
                    expRight: false
                })
            } else if(this.state.video_url) {
                let obj = {
                    post_id: this.props.data.id,
                    status: this.state.status,
                    video_url: this.state.video_url,
                }
                await this.props.cudPost("/admin/post-content", obj, "POST")
                await this.setState({
                    status: "",
                    image_url: "",
                    chose_input: "",
                    video_url: "",
                    expRight: false
                })
            }
        }
    }

    onChangeInput(e){
        const {name, value} = e.target
        if(name === "image_url"){
            this.setState({
                image_url: e.target.files[0],
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    async buttonDelete(data){
        await this.props.cudPost("/admin/post-content", {}, "DELETE", data.id)
        await this.setState({
            data: "",
            confirmDelete: false
        })
    }

    render() {
        const { post_contents: PostContent } = this.props.data
        return (
            <React.Fragment>
                <div className="mt-3">
                    <h3>Post Content</h3>
                    <Row className="mb-5">
                    {PostContent.length === 0 ? <span className="ml-4">Not yet have post content</span> : 
                        PostContent.map(content => {
                            return(
                                <>
                                <Col xl="3" lg="3" md="4" sm="6">
                                    {content.status === "0" ? <Badge key={content.id} className="bg-primary mb-1 text-center">HEADER</Badge>:
                                    <Badge key={content.id} className="bg-danger mb-1 text-center">CONTENT</Badge>
                                    }
                                    {content.image_url ? 
                                        <Row>
                                            <img src={content.image_url} alt={content.image_url} width="260px"/> 
                                        </Row>
                                        :
                                        <VideoPlayer url={content.video_url}/>
                                    }
                                    <FormGroup className="mt-2">
                                        <Button className="ml-1 bg-danger border-0" onClick={() => this.setState({ confirmDelete: true, data: content})}><i className="fa fa-trash-o ml-1"></i></Button>
                                    </FormGroup>
                                </Col>
                            </>
                            )
                        })
                    }
                    </Row>
                    <AvForm
                        onValidSubmit={this.submitForm}
                    >
                        <AvRadioGroup inline name="status" label="HEADER/CONTENT" required>
                            <AvRadio label="HEADER" value="0" onChange={() => this.setState({status: "0"})} />
                            <AvRadio label="CONTENT" value="1" onChange={() => this.setState({status: "1"})} />
                        </AvRadioGroup>
                        {!this.state.status ? 
                            <AvRadioGroup inline name="chose_input" label="Chose Input Content" required>
                                <AvRadio label="Foto" value="foto" onChange={() => this.setState({chose_input: "foto", video_url: ""})} disabled/>
                                <AvRadio label="Video" value="video" onChange={() => this.setState({chose_input: "video", image_url: ""})} disabled/>
                            </AvRadioGroup> :
                            <AvRadioGroup inline name="chose_input" label="Chose Input Content" required>
                                <AvRadio label="Foto" value="foto" onChange={() => this.setState({chose_input: "foto"})}/>
                                <AvRadio label="Video" value="video" onChange={() => this.setState({chose_input: "video"})}/>
                            </AvRadioGroup>
                        }
                        
                        {this.state.chose_input === "video" ? 
                        <FormGroup>
                            <Label sm="12">Link youtube<span className="text-danger">*</span></Label>
                            <Col sm="12">
                                <AvField type="text" placeholder="Input link youtube" name="video_url" value={this.state.video_url} className="w-25" required onChange={this.onChangeInput}/>
                            </Col>
                        </FormGroup> : this.state.chose_input === "foto" ?
                        <FormGroup>
                            <Label sm="12">Upload foto<span className="text-danger">*</span></Label>
                            <Col sm="12">
                                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="image_url" className="w-25" onChange={this.onChangeInput} required/>
                            </Col>
                        </FormGroup> : <span></span>
                        }
                        
                        <FormGroup>
                            <Col>
                                <LaddaButton
                                    loading={this.state.expRight}
                                    data-style={EXPAND_RIGHT}
                                    className="btn btn-primary"
                                >Save changes</LaddaButton>
                            </Col>
                        </FormGroup>
                    </AvForm>
                </div>

                {this.state.error_dlg ?
                    <SweetAlert
                        error
                        title="Empty Form"
                        onConfirm={() => this.setState({ error_dlg: false })}  >
                        {this.state.detail}
                    </SweetAlert> : null}
                
                    {this.state.confirmDelete ? 
                    <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.buttonDelete(this.state.data)}
                    onCancel={() => this.setState({ confirmDelete: false })} >
                    Are you sure to delete: {this.state.data.status === "0" ? "HEADER" : "CONTENT"} with ID: {this.state.data.id}?
                    </SweetAlert> : null
                }
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    // const { toggleAdd } = state.Post
    return {  };
}

export default withRouter(connect(mapStatetoProps, { cudPost })(FormAddComponent))