import { CHECK_USERNAME, SET_USERNAME} from './actionTypes'

export const checkUsername = (history) => {
  return {
    type: CHECK_USERNAME,
    payload: {history}
  }
}

export const setUsername = (username) => {
  return {
    type: SET_USERNAME,
    payload: username
  }
}