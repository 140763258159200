import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_CASH_OUT, } from './actionTypes';
import {  setCashOut } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* cashOut({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const methodbank = state.method === "-1" ? "" : state.method
        const statusCash = state.status === "-1" ? "" : state.status
        state.method = methodbank
        state.status = statusCash
        response = yield call(callGetCategory, "/admin/cashout", state)
        yield put(setCashOut(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setCashOut({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
                amount: error.data.amount,
                pending: error.data.pending,
                total_cashout: error.data.total_cashout,
                nameBank: error.data.nameBank,
            }))
        }
    }
}

export function* watchCashOut() {
    yield takeEvery(CHECK_CASH_OUT, cashOut)
}

function* cashOutSaga() {
    yield all([fork(watchCashOut)]);
}

export default cashOutSaga;