import { all } from 'redux-saga/effects'
import loginSaga from './auth/login/saga';
import userameSaga from '../modules/Category/store/username/saga'
import categorySaga from '../modules/Category/store/category/saga'
import addCategorySage from "../modules/Category/store/addCategory/saga"
import brandSaga from "../modules/Brand/store/brand/saga"
import addBrandSaga from "../modules/Brand/store/addBrand/saga"
import productMasterSaga from "../modules/ProductMaster/store/product/saga"
import addProductMaster from "../modules/ProductMaster/store/addProduct/saga"
import bannerSaga from "../modules/Banner/store/banner/saga"
import cudBannerSaga from "../modules/Banner/store/cudBanner/saga"
import changePasswordSaga from "../modules/ChangePassword/store/saga"
import managementUser from "../modules/ManagementUser/store/saga"
import KYCSaga from "../modules/ManagementUser/store/kyc/getData/saga"
import updateStatusKYC from "../modules/ManagementUser/store/kyc/updateData/saga"
import updateManagement from "../modules/ManagementUser/store/managementUser/updateUser"
import cashOutSaga from "../modules/CashOut/store/cashout/saga"
import updateCashOutSaga from "../modules/CashOut/store/updateCashOut/saga"
import cashInSaga from "../modules/CashIn/store/cashin/saga"
import updateCashInSage from "../modules/CashIn/store/updateCashIn/saga"
import monitoringSaga from "../modules/MonitoringBalance/store/monitoring/saga"
import createTransferSaga from "../modules/MonitoringBalance/store/createTransfer/saga"
import mutasiBankSaga from "../modules/MutasiBank/store/saga"
import transactionSaga from "../modules/Transaction/store/saga"
import findNameSaga from "../modules/Transaction/store/username/saga"
import exportCashIN from "../modules/CashIn/store/export/saga"
import exportCashOUT from "../modules/CashOut/store/export/saga"
import exportMutasiBank from "../modules/MutasiBank/store/export/saga"
import badgeSaga from "../modules/Badges/store/badge/saga"
import cudBadge from "../modules/Badges/store/cudBadge/saga"
import storeBadgeSaga from "../modules/StoreBadge/store/storeBadge/saga"
import cudStoreBadgeSaga from "../modules/StoreBadge/store/cudStoreBadge/saga"
import settingSaga from "../modules/Setting/store/setting/saga"
import cudSettingSaga from "../modules/Setting/store/updateSetting/saga"
import BATransferSaga from "../modules/MonitoringBalance/store/BATransfer/saga"
import exportBATransferSaga from "../modules/MonitoringBalance/store/exportBATransfer/saga"
import postSaga from "../modules/Content/Post/store/post/saga"
import updateStatus from "../modules/Transaction/store/update/saga"
import cudPostSaga from "../modules/Content/Post/store/cudPost/saga"
import tagSaga from "../modules/Content/Tag/store/tag/saga"
import cudTagSaga from "../modules/Content/Tag/store/cudTag/saga"
import postTagSaga from "../modules/Content/PostTag/store/postTag/saga"
import cudPostTagSaga from "../modules/Content/PostTag/store/cudPostTag/saga"
import messageSaga from "../modules/Messages/store/saga"
import countUserSaga from "../modules/CountUsers/store/saga"
import exportTransaction from "../modules/Transaction/store/export/saga"
import exportManagementUserSaga from '../modules/ManagementUser/store/managementUser/export';
import exportP2PSaga from '../modules/P2P/store/saga'
import CashOutP2PSaga from '../modules/CashOutP2P/store/cashout/saga';
import updateCashOutP2PSaga from "../modules/CashOutP2P/store/updateCashOut/saga"
import exportCashOutP2PSaga from "../modules/CashOutP2P/store/export/saga"
import KomplainP2PSaga from "../modules/P2PKomplain/store/saga"
import UpdateP2PSaga from "../modules/P2P/store/update/saga"
import UpdateKomplainP2PSaga from "../modules/P2PKomplain/store/update/saga"
import UpdateP2PBiller from "../modules/P2P/store/updateBiller/saga"
import LogMonitSaga from "../modules/MonitLogs/store/saga"
import CategoryP2PSaga from "../modules/CategoryP2P/store/saga"
import CategoryP2PActionSaga from "../modules/CategoryP2P/store/actionCategory/saga"
import EtalaseP2PSaga from "../modules/EtalaseP2P/store/saga"
import EtalaseP2PActionSaga from "../modules/EtalaseP2P/store/actionEtalase/saga"
import BrandP2PSaga from "../modules/BrandP2P/store/saga"
import BrandP2PActionSaga from "../modules/BrandP2P/store/actionBrand/saga"
import ProductMasterImport from "../modules/ProductMaster/store/importProduct/saga"

export default function* rootSaga() {
    yield all([ loginSaga(), userameSaga(), categorySaga(), addCategorySage(), brandSaga(), 
        addBrandSaga(), productMasterSaga(), addProductMaster(), bannerSaga(), cudBannerSaga(), 
        changePasswordSaga(), managementUser(), KYCSaga(), updateStatusKYC(), updateManagement(),
        cashOutSaga(), updateCashOutSaga(), cashInSaga(), updateCashInSage(), monitoringSaga(),
        createTransferSaga(), mutasiBankSaga(), transactionSaga(), findNameSaga(), exportCashIN(),
        exportCashOUT(), exportMutasiBank(), badgeSaga(), cudBadge(), storeBadgeSaga(), cudStoreBadgeSaga(),
        settingSaga(), cudSettingSaga(), BATransferSaga(), exportBATransferSaga(), postSaga(),
        updateStatus(), cudPostSaga(), tagSaga(), cudTagSaga(), postTagSaga(), cudPostTagSaga(),
        messageSaga(), countUserSaga(), exportTransaction(), exportManagementUserSaga(), exportP2PSaga(),
        CashOutP2PSaga(), updateCashOutP2PSaga(), exportCashOutP2PSaga(), KomplainP2PSaga(), UpdateP2PSaga(),
        UpdateKomplainP2PSaga(), UpdateP2PBiller(), LogMonitSaga(), CategoryP2PSaga(), CategoryP2PActionSaga(),
        EtalaseP2PSaga(), EtalaseP2PActionSaga(), BrandP2PSaga(), BrandP2PActionSaga(), ProductMasterImport()
    ]);
}