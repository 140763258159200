import { CHECK_MONITORING, SET_MONITORING, SET_DATA_MONITORING, SET_BA_TRANSFER } from "./actionTypes"

const initialState = {
  banks: [], BATransfer: [], currentPage: 1, totalItems: 0, totalPages: 0, size: 10, saldoBank: 0,
  saldoMember: 0, saldoCashOut: 0, balance: 0, toggleAdd: false, confirm:false, msgSuccess: "",
  revenue: 0
}

const monitoring = (state = initialState, action) => {
  switch(action.type){
    case CHECK_MONITORING:
      state = {
        ...state
      }
      break;
    case SET_MONITORING:
      state = {
        ...state,
        banks: action.payload.dataBank,
        saldoBank: action.payload.saldoBank,
        saldoMember: action.payload.saldoMember,
        saldoCashOut: action.payload.saldoCashOut,
        balance: action.payload.balance,
        revenue: action.payload.revenue
      }
      break;
    case SET_BA_TRANSFER:
      state = {
        ...state,
        BATransfer: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      }
      break;
    case SET_DATA_MONITORING:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default monitoring