import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CUD_TAG } from '../tag/actionTypes';
import { setTag, setDataTag } from '../tag/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* contentCUDTag({payload: {url, state, method, id}}) {
    try {
        const responseStatus = yield call(hitAPIWasmarket, url, state, method, id)
        yield put(setDataTag("confirm", true))
        yield put(setDataTag("msgSuccess", responseStatus.data))
        const response = yield call(callGetCategory, "/admin/tag", {page: 1, size: 10})
        yield put(setTag(response))
    } catch (error) {
        if(error.responseCode === 404){
            yield put(setTag({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
        }
    }
}

export function* watchContentTag() {
    yield takeEvery(CUD_TAG, contentCUDTag)
}

function* cudTagSaga() {
    yield all([fork(watchContentTag)]);
}

export default cudTagSaga;