import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label } from 'reactstrap';
import { setNewBadge, addNewBadge } from "../store/badge/actions"  
import axios from 'axios'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { AvField, AvForm } from "availity-reactstrap-validation";
import { SketchPicker } from 'react-color'
import {BASE_URL_UPLOAD} from "../../../helpers/constanta"

class ModalEdit extends Component{
  constructor(props){
    super(props)
    this.state = {
      expRight: false
    }
    this.toggle = this.toggle.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewBadge("toggleEdit", !this.props.toggleEdit)
  }

  async submitForm(){
    // console.log(this.props.data);
    await this.setState({
      expRight: true
    })
    let send = {...this.props.data}
    if(!send.icon){
      console.log("masuk gak ada photo");
      // delete send.photo
    }else{
      var FormData = require('form-data');
      const fd = new FormData();
      fd.append("file", send.icon, send.icon.name)
  
      const config = {
        url: BASE_URL_UPLOAD,
        method: "POST",
        data: fd
      }
      const result = await axios(config)
      send.icon = result.data.imageurl
    }
    delete send.id
    await this.props.addNewBadge("/admin/badge", send, "PUT", this.props.data.id)
    await this.setState({
      expRight: false
    })
    await this.toggle()
  }

  render(){
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Badge</ModalHeader>
          <AvForm onValidSubmit={this.submitForm}>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="Input name" name="title" value={this.props.data.name} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label>Icon</Label>
                <FormGroup>
                  <img src={this.props.data.tmpIcon} width="150px" height="150px" alt="icon"/>
                </FormGroup>
                <CustomInput type="file" id="exampleCustomFileBrowser" accept={['.png', '.jpg']} name="icon" onChange={this.props.onChangeInput} />
              </Col>
              </FormGroup>
            <FormGroup>
              <Label sm="12">Description</Label>
              <Col sm="12">
                <AvField required type="textarea" placeholder="Input description" name="description" value={this.props.data.description} onChange={this.props.onChangeInput} />
              </Col>
            </FormGroup>
            <Label sm="12">Color</Label>
            <FormGroup style={{display: "flex", justifyContent: "center"}}>
              <div >
                <Col sm="12">
                  <SketchPicker color={this.props.data.color} onChangeComplete={this.props.handleChangeComplete}/>
                </Col>
              </div>
            </FormGroup>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
          
        </ModalFooter>
          </AvForm>
      </Modal>
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit } = state.Badge
  return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewBadge, addNewBadge })(ModalEdit));