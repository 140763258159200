import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input, Row, Card } from 'reactstrap';
import { setNewCategoryP2P, addNewCategoryP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { callGetCategory, uploadFoto } from '../../../helpers/authUtils';
import "./style.css"

class ModalEditCategoryP2P extends Component{
  constructor(props){
    super(props)
    this.state = {
      icon: "",
      tmpIcon: "",
      banner: "",
      tmpBanner: "",
      border: "",
      tmpBorder: "",
      expRight: false,
      error_dlg: false,
      type: "",
      etalase: "",
      etalases: "",
      customError: "",
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    // this.deleteBannerIdx = this.deleteBannerIdx.bind(this)
  }

  toggle(){
    this.props.setNewCategoryP2P("toggleEdit", !this.props.toggleEdit)
    this.setState({
      name: "",
      description: "",
      icon: "",
      tmpIcon: "",
      banner: "",
      tmpBanner: "",
      border: "",
      tmpBorder: "",
      expRight: false
    })
  }

  async componentDidMount(){
    const etalase = await callGetCategory("/admin/p2p/category/etalase")
    await this.setState({
      etalases: etalase,
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon" || name === "border" || name === "banner"){
      const tmpName = "tmp"+name.charAt(0).toUpperCase() + name.slice(1);
      await this.setState({
        [name]: e.target.files[0],
        [tmpName]: URL.createObjectURL(e.target.files[0])
      })
    }else if(name === "banner"){
      const dataParse = JSON.parse(value)
      delete dataParse.id
      let tmpArr = []
      if(this.state.banners.length !== 0){
        tmpArr = [...this.state.banners]
      }
      tmpArr.push(dataParse)
      await this.setState({
        banners: tmpArr,
        banner: ""
      })

    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      await this.setState({
        expRight: true
      })
      let resultIcon, resultBanner, resultBorder
      if(this.state.icon){
        resultIcon = await uploadFoto(this.state.icon)
        await this.props.onChangeInput({target:{name: "icon",value: resultIcon}})
      }
      if(this.state.banner) resultBanner = await uploadFoto(this.state.banner)
      if(this.state.border) resultBorder = await uploadFoto(this.state.border)

      const tmpArr = []
      if(resultBanner && resultBorder){
        tmpArr.push({name: "banner", icon: resultBanner})
        tmpArr.push({name: "border", icon: resultBorder})
      } else if(resultBanner){
        tmpArr.push({name: "banner", icon: resultBanner})
        this.props.detail.banners.forEach(el => {
          if(el.name === "border") tmpArr.push(el)
        })
      } else if(resultBorder){
        tmpArr.push({name: "border", icon: resultBorder})
        this.props.detail.banners.forEach(el => {
          if(el.name === "banner") tmpArr.push(el)
        })
      } else {
        this.props.detail.banners.forEach(el => {
          tmpArr.push(el)
        })
      }

      await this.props.onChangeInput({target:{name: "banner",value: tmpArr}})
  
      await this.props.addNewCategoryP2P("/admin/p2p/category", this.props.detail, "PUT", this.props.detail._id)

      this.toggle()
    } catch (error) {
      console.log(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
      // console.log(this.props.detail.banners)
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Category</ModalHeader>
        {this.props.detail ? 
          <AvForm onValidSubmit={this.submitForm}>
          <FormGroup>
          <Label sm="12">Name</Label>
            <Col sm="12">
              <AvField required type="text" placeholder="John Doe" name="name" value={this.props.detail.name} onChange={this.props.onChangeInput}/>
            </Col>
          </FormGroup>
          <FormGroup>
            <Row>
            <Col sm="6">
            <Label sm="12">Type</Label>
            <Input required type="select" className="ml-2" name="type" onChange={this.props.onChangeInput} id="exampleSelect">
              {this.props.detail.type === "P2P" ? (
                    <>
                      <option selected value="P2P">P2P</option>
                      <option value="P2B">P2B</option>
                    </>
                    
                  ): (
                    <>
                      <option value="P2P">P2P</option>
                      <option selected value="P2B">P2B</option>
                    </>
                  )} 
            </Input>
            </Col>
            <Col sm={{ offset: 0, size: 6 }} >
            <Label sm="12">Etalase</Label>
            <Input required type="select" style={{marginLeft: "-10px"}} name="etalase" onChange={this.props.onChangeInput} id="exampleSelect">
              <option selected disabled>Select Etalase</option>
              {this.state.etalases && this.state.etalases.map(el => {
                if(el.name === this.props.detail.etalase.name) return <option selected key={el._id} value={el._id}>{el.name}</option>
                else return <option key={el._id} value={el._id}>{el.name}</option>
              })}
            </Input>
            </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Col sm="12">
              <Label >Icon</Label>
              <FormGroup>
                {this.state.tmpIcon ?
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.state.tmpIcon}/>
                    <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                </Card>
                </>
                :
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.props.detail.icon}/>
                </Card> }
              </FormGroup>
              <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
            </Col>
          </FormGroup>
          
          <FormGroup>
            <Col sm="12">
              <Label >Banner</Label>
              <FormGroup>
                {this.state.tmpBanner ?
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.state.tmpBanner}/>
                    <Button onClick={() => this.setState({banner: "", tmpBanner: ""})}> X </Button>
                </Card>
                </>
                :
                this.props.detail.banners.map(el => {
                  if(el.name === "banner"){
                    return <Card style={{width: '12rem'}}>
                        <img alt="icon" src={el.icon}/>
                      </Card>
                  } else return ""
                })
                }
              </FormGroup>
              <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="banner" onChange={this.onChangeInput} />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm="12">
              <Label >Border</Label>
              <FormGroup>
                {this.state.tmpBorder ?
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.state.tmpBorder}/>
                    <Button onClick={() => this.setState({border: "", tmpBorder: ""})}> X </Button>
                </Card>
                </>
                :
                this.props.detail.banners.map(el => {
                  if(el.name === "border"){
                    return <Card style={{width: '12rem'}}>
                        <img alt="icon" src={el.icon}/>
                      </Card>
                    
                  } else return ""
                })
                }
              </FormGroup>
              <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="border" onChange={this.onChangeInput} />
            </Col>
          </FormGroup>

          <FormGroup>
            <Label sm="12">Priority</Label>
            <Col sm="12">
              <AvField required type="text" placeholder="Enter priority" name="priority" value={this.props.detail.priority} onChange={this.props.onChangeInput} />
            </Col>
          </FormGroup>
          <FormGroup>
          <Label sm="12">Status</Label>
            <Col sm="12">
            <Input type="select" name="active" onChange={this.props.onChangeInput} id="exampleSelect">
              {this.props.detail.active === true ? (
                    <>
                      <option value="0">Inactive</option>
                      <option selected value="1">Active</option>
                    </>
                    
                  ): (
                    <>
                      <option selected value="0">Inactive</option>
                      <option value="1">Active</option>
                    </>
                  )} 
            </Input>
            </Col>
          </FormGroup>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        <LaddaButton
          loading={this.state.expRight}
          data-style={EXPAND_RIGHT}
          className="btn btn-primary"
        >Save changes</LaddaButton>
      </ModalFooter>
        </AvForm>
        : 
        ""}
          
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit } = state.CategoryP2P
  return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewCategoryP2P, addNewCategoryP2P })(ModalEditCategoryP2P))