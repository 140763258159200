import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { activateAuthLayout } from "../../../store/actions";
import { checkCountUser, setCountUser } from "../store/actions"
import { Row, Col, Card, CardBody } from 'reactstrap';
import moment from "moment"

class CountUserPage extends Component{
    constructor(props){
        const time = moment().format("YYYY-MM-DD HH:mm:ss")
        super(props)
        this.state = {
            count: 1,
            time: time
        }
    }

    async componentDidMount(){
        this.props.activateAuthLayout(this.props.type)
        await this.props.checkCountUser(this.props.history, {}, true)
        setInterval(async () => {
            const time = moment().format("YYYY-MM-DD HH:mm:ss")
            await this.setState({time: time})
        }, 1000);
        setInterval(async () => {
            await this.setState({count: this.state.count + 1})
            await this.props.checkCountUser(this.props.history, {}, false)
        }, 5000);
    }

    render(){
        
        return(
            <React.Fragment>
            <Row>
                <Col style={{margin: "0 auto"}} className="align-self-center" xl="8" lg="8" md="12" sm="12">
                    <Card>
                        <CardBody>
                                <img src="https://hay-images.sgp1.digitaloceanspaces.com/hayumarket-logo.png" alt="Logo" style={{marginTop: "-30px"}}/>
                                <div className="card-icon text-center ">
                                    <div style={{marginTop: "-50px"}}>
                                        <i className="ti-user fs-1" style={{fontSize: "80px"}}><b>{this.props.user}</b></i>
                                        <p></p>
                                        <span className="text-center">{this.state.time}</span>
                                    </div>
                                </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { user } = state.CountUser
    return { user };
  }
  
  export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkCountUser, setCountUser })(CountUserPage));


