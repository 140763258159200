import {CHECK_P2P, SET_P2P,SET_DATA_P2P, UPDATE_DATA_P2P, UPDATE_DATA_BILLER_P2P} from "./actionTypes"

export const checkP2P = (history, state, flag) => {
    return {
      type: CHECK_P2P,
      payload: {history, state, flag}
    }
}

export const setP2P = (props) => {
    return {
      type: SET_P2P,
      payload: props
    }
  }

export const setDataP2P = (name, data) => {
    return {
      type: SET_DATA_P2P,
      payload: {name, data}
    }
  }

export const updateDataP2P = (url, state, method, id) => {
    return {
      type: UPDATE_DATA_P2P,
      payload: {url, state, method, id}
    }
  }

  export const updateDataBillerP2P = (url, state, method, id) => {
    return {
      type: UPDATE_DATA_BILLER_P2P,
      payload: {url, state, method, id}
    }
  }