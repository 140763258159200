import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input, Card } from 'reactstrap';
import { setNewEtalaseP2P, addNewEtalaseP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { uploadFoto } from '../../../helpers/authUtils';

class ModalAddEtalaseP2P extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      tmpIcon: "",
      status: "0",
      priority: "",
      expRight: false,
      error_dlg: false,
      tmpBanner: "",
      customError: ""
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewEtalaseP2P("toggleAdd", !this.props.toggleAdd)
    this.setState({
      name: "",
      banner: "",
      icon: "",
      tmpIcon: ""
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      if(!this.state.icon){
        await this.setState({
          error_dlg: true,
          customError: "Must input all data form!"
        })
      } else{
        await this.setState({
          expRight: true
        })

      const resultIcon = await uploadFoto(this.state.icon)

      let obj = {
        name: this.state.name,
        icon: resultIcon,
        status: this.state.status === "1" ? 1 : 0,
      }

        await this.props.addNewEtalaseP2P("/admin/p2p/etalase", obj, "POST")
        await this.setState({
          name: "",
          icon: "",
          status: "0",
        })
        await this.setState({
          expRight: false
        })
        this.toggle()
      }
        
    } catch (error) {
      console.log(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Category</ModalHeader>
          <AvForm onValidSubmit={this.submitForm}>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField required type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput}/>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <FormGroup>
                  {this.state.tmpIcon ?
                  <>
                  <Card style={{width: '12rem'}}>
                    <img alt="icon" src={this.state.tmpIcon}/>
                      <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                  </Card>
                  </>
                  :
                  "" }
                </FormGroup>
                <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Status</Label>
              <Col sm="12">
              <Input type="select" name="status" onChange={this.onChangeInput} id="exampleSelect">
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </Input>
              </Col>
            </FormGroup>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
          </AvForm>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd } = state.EtalaseP2P
  return { toggleAdd };
}

export default withRouter(connect(mapStatetoProps, { setNewEtalaseP2P, addNewEtalaseP2P })(ModalAddEtalaseP2P))