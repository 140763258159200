import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, Badge, Row} from 'reactstrap';
import { setDataCashOutP2P } from "../store/cashout/actions"
import { convertToRupiah } from "../../../helpers/authUtils"

class ModalDetailCashOut extends Component{
    constructor(props){
        super(props)
        this.state = {}
        this.toggle = this.toggle.bind(this)
    }

    async toggle(){
        await this.props.setDataCashOutP2P("modalDetail", !this.props.modalDetail)
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.modalDetail} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Detail Cash Out</ModalHeader>
                    <ModalBody  style={{backgroundColor: "#f2f2f2"}}>
                        {this.props.user ?
                            <Row>
                                <Col>
                                    <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">ID</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.id}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">USER ID</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_id}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">USER NAME</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_bank.user.name}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">USER BANK ID</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_bank_id}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">BANK NUMBER</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_bank.bank_number}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">BANK NAME</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_bank.bank_account.bankName}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">BANK HOLDER NAME</span>
                                            <span className="traffic-sales-amount">
                                            <b>{this.props.user.user_bank.bank_holder_name}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">AMOUNT</span>
                                            <span className="traffic-sales-amount">
                                            <b>{convertToRupiah(this.props.user.amount)}</b>
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">APPROVAL BY</span>
                                            <span className="traffic-sales-amount">
                                            {this.props.user.approval_by ? <b>{this.props.user.approval_by}</b> : <b>-</b>}
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">REMARK</span>
                                            <span className="traffic-sales-amount">
                                            {this.props.user.remark ? <b>{this.props.user.remark}</b> : <b>-</b>}
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                        <li className="traffic-sales-content list-group-item ">
                                            <span className="traffic-sales-name">STATUS</span>
                                            <span className="traffic-sales-amount">
                                            {this.props.user.status === 0 ? <Badge color="primary">PENDING</Badge>: 
                                                this.props.user.status === 1 ? <Badge color="success">APPROVE</Badge>:
                                                <Badge color="danger">REJECT</Badge>
                                            }
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                            </span>
                                            </span>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>: ""
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { modalDetail } = state.CashOutP2P
    return { modalDetail };
}

export default withRouter(connect(mapStatetoProps, { setDataCashOutP2P })(ModalDetailCashOut));