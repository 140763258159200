import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { activateAuthLayout } from "../../../store/actions";
import { checkBrand, setNewBrand, addNewBrand } from "../store/brand/actions"
import DataTable from 'react-data-table-component';
import { Badge, Container, Button, Row, Col, Card, CardBody, Label, Input } from "reactstrap";
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class BrandPages extends Component {
  constructor(props){
    super(props)
    this.state = { 
      id: "",
      name: "",
      nameBrand: "",
      icon: "",
      tmpIcon: "",
      description: "",
      productCategoryId: "",
      priority: "",
      status: "",
      prefix: "",
      inputPrefix: [],
      dataDelete: null,
      swal: false, success_dlg: false, dynamic_title: "", 
      dynamic_description: ""
    }
    this.buttonEdit = this.buttonEdit.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.deletePrefix = this.deletePrefix.bind(this)
    this.inputPrefix = this.inputPrefix.bind(this)
    this.searchName = this.searchName.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  async componentDidMount(){
    let props = this.props
    await this.props.activateAuthLayout();
    await this.props.checkBrand(this.props.history, {
      page: props.currentPage,
      size: props.size,
    }, true)
    
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  handleKeyPress(e){
    if (e.key === "Enter") {
        this.searchName()
    }
}

  async deletePrefix(idx){
    const newFilter = this.state.inputPrefix.filter((_, index) => index !== idx)
    await this.setState({
        inputPrefix: newFilter
    })
  }

  async inputPrefix(){
    const newArray = [...this.state.inputPrefix, this.state.prefix]
    await this.setState({
        inputPrefix: newArray,
        prefix: ""
    })
  }

  //menampilkan data sebanyak 10/20/50/100
  async changeRowsPerPage(size){
    await this.props.setNewBrand("size", size)
    let props = this.props
    await this.props.checkBrand(this.props.history, {
      page: props.currentPage,
      size: size,
      search: this.state.nameBrand
    }, false)
  }

  async onChangePage(page){
    await this.props.setNewBrand("page", page)
    let props = this.props
    await this.props.checkBrand(this.props.history, {
      page: page,
      size: props.size,
      search: this.state.nameBrand
    }, false)
  }

  async searchName(){
    await this.props.setNewBrand("size", 10)
    await this.props.setNewBrand("page", 1)
    let props = this.props
    await this.props.checkBrand(this.props.history, {
        page: props.page,
        size: props.size,
        search: this.state.nameBrand
    }, false)
  }

  async buttonEdit(e, data){
    let newData = []
    data.digital_prefix.forEach(el => {
        newData.push(el.prefix)
    })
    await this.setState({
        id: data.id,
        name: data.name,
        description: data.description,
        status: data.status,
        productCategoryId: data.product_digital_category_id,
        tmpIcon: data.icon,
        priority: data.priority,
        inputPrefix: newData
    })
    await this.props.setNewBrand("toggleEdit", !this.props.toggleEdit)
  }

  buttonDelete(data){
    this.setState({ swal: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'Your file has been deleted.' })
    this.props.addNewBrand("/admin/brand", {}, "DELETE", data.id)
  }

  async onShowModalAdd(){
    await this.props.setNewBrand("toggleAdd", !this.props.toggleAdd)
  }

  render() {
    const columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px"
      },
      {
        name: "Name",
        selector: "name",
        width: "150px"
      },
      {
        name: "Icon",
        selector: "icon",
        width: "70px",
        cell: row => {
          return <img src={row.icon} alt={row.icon} />
        }
      },
      {
        name: "Description",
        selector: "description",
        width: "350px"
      },
      {
        name: "Category",
        selector: "category.name",
        center: true,
        width: "250px"
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        cell: row => {
          return (
            row.status === 0 || row.status === false ? <Badge color="danger">Inactive</Badge> :
            <Badge color="success" style={{color: "black"}}>Active</Badge> 
          )
        },
        width: "100px"
      },
      {
        name: "Priority",
        selector: "priority",
        center: true,
        width: "70px",
        cell: row => {
          return(
            <b>{row.priority}</b>
          )
        }
      },
      {
        name: "Action",
        center: true,
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(e, row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                swal: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
    ]

    return(
      <React.Fragment>
        <NotificationMessage />
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="3">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i> Add Brand</Button>
                    </Col>
                     
                    <Col xl={{ offset: 5, size: 5 }} 
                        lg={{ offset: 4, size: 5 }} 
                        md="5" 
                        sm="12">
                          <div className="input-group">
                            <Label className="mt-2 mr-2">Search:</Label>
                            <Input className="mr-2" type="text" onKeyPress={this.handleKeyPress} name="nameBrand" placeholder="search name" value={this.state.nameBrand} onChange={(e) => this.setState({nameBrand: e.target.value})}/>
                            <Button color="primary" onClick={this.searchName}>Search</Button>
                          </div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    title="Brands"
                    columns={columns}
                    data={this.props.brands}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAdd />
        <ModalEdit 
            onChangeInput={this.onChangeInput} 
            data={{id: this.state.id, name: this.state.name, description: this.state.description, icon: this.state.icon, status: this.state.status, tmpIcon: this.state.tmpIcon, priority: this.state.priority, inputPrefix: this.state.inputPrefix, prefix: this.state.prefix, nameBrand: this.state.nameBrand}}
            deletePrefix={this.deletePrefix}
            inputPrefix={this.inputPrefix}
        />

        {this.state.swal ? 
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => this.buttonDelete(this.state.dataDelete)}
          onCancel={() => this.setState({ swal: false, error_dlg: true, dynamic_title: 'Cancelled', dynamic_description: 'Your imaginary file is safe :)' })} >
          You won't be able to revert this!
          </SweetAlert> : null
        }

        {this.state.success_dlg ?
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ success_dlg: false })}  >
            {this.state.dynamic_description}
            </SweetAlert> : null}

        {this.state.error_dlg ?
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.dynamic_description}
          </SweetAlert> : null}
        
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { currentPage, totalItems, size, status, brands, toggleAdd, toggleEdit, arrCategory } = state.Brand
  return { currentPage, totalItems, size, status, brands, toggleAdd, toggleEdit, arrCategory };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkBrand, setNewBrand, addNewBrand })(BrandPages));