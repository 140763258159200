import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input, Card } from 'reactstrap';
import { setNewEtalaseP2P, addNewEtalaseP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { uploadFoto } from '../../../helpers/authUtils';

class ModalAddEtalaseP2P extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      tmpIcon: "",
      status: "0",
      priority: "",
      expRight: false,
      error_dlg: false,
      tmpBanner: "",
      customError: "",
      count: 0
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewEtalaseP2P("toggleEdit", !this.props.toggleEdit)
    this.setState({
      name: "",
      banner: "",
      icon: "",
      tmpIcon: ""
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      if(this.state.count === 0){
        this.setState({
          count: 1
        })
      } else {
        let resultIcon
        if(this.state.icon){
          resultIcon = await uploadFoto(this.state.icon)
          await this.props.onChangeInput({target:{name: "icon",value: resultIcon}})
        }

        await this.props.addNewEtalaseP2P("/admin/p2p/etalase", this.props.detail, "PUT", this.props.detail._id)
        await this.setState({
          expRight: false,
          count: 0
        })
        this.toggle()
      }
        
    } catch (error) {
      console.log(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Edit Etalase</ModalHeader>
        {this.props.detail ? 
          <AvForm onValidSubmit={this.submitForm}>
          <FormGroup>
          <Label sm="12">Name</Label>
            <Col sm="12">
              <AvField required type="text" placeholder="John Doe" name="name" value={this.props.detail.name} onChange={this.props.onChangeInput}/>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm="12">
              <Label >Icon</Label>
              <FormGroup>
                {this.state.tmpIcon ?
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.state.tmpIcon}/>
                    <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                </Card>
                </>
                :
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.props.detail.icon}/>
                </Card>
                </> }
              </FormGroup>
              <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
            </Col>
          </FormGroup>
          <FormGroup>
          <Label sm="12">Status</Label>
            <Col sm="12">
            <Input type="select" name="status" onChange={this.props.onChangeInput} id="exampleSelect">
              {this.props.detail.status === 1 ?
              <>
                <option value="0">Inactive</option>
                <option selected value="1">Active</option>
              </>
              :
              <>
                <option selected value="0">Inactive</option>
                <option value="1">Active</option>
              </>
            }
            </Input>
            </Col>
          </FormGroup>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        <LaddaButton
          loading={this.state.expRight}
          onClick={this.submitForm}
          data-style={EXPAND_RIGHT}
          className="btn btn-primary"
        >Save changes</LaddaButton>
      </ModalFooter>
        </AvForm>
        : ""}
          
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit } = state.EtalaseP2P
  return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewEtalaseP2P, addNewEtalaseP2P })(ModalAddEtalaseP2P))