import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_MANAGEMENT_USER } from '../actionTypes';
import {  setManagementUser, setDataManagementUser } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updateManagement({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/user", {page: 1, size: 10})
        yield put(setManagementUser(response))
        yield put(setDataManagementUser("complete", true))
    } catch (error) {
        if(error.responseCode === 400){
            if(error.data === "Otp Whatsapp gagal!"){
                yield put(setDataManagementUser("errorFlag", true))
                yield put(setDataManagementUser("msgError", error.data))
            }else{
                yield put(setDataManagementUser("errorFlag", true))
                yield put(setDataManagementUser("msgError", error.data[0]))
            }
        } else {
            if(error.data === "Otp Whatsapp gagal!"){
                yield put(setDataManagementUser("errorFlag", true))
                yield put(setDataManagementUser("msgError", error.data))
            }
        }
    }
}

export function* watchUpdateManagementUser() {
    yield takeEvery(UPDATE_MANAGEMENT_USER, updateManagement)
}

function* updateManagementUser() {
    yield all([fork(watchUpdateManagementUser)]);
}

export default updateManagementUser;