import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Input, Row, Card } from 'reactstrap';
import { setNewCategoryP2P, addNewCategoryP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { callGetCategory, uploadFoto } from '../../../helpers/authUtils';
import "./style.css"

class ModalAddCategory extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      tmpIcon: "",
      banner: "",
      tmpBanner: "",
      border: "",
      tmpBorder: "",
      status: "0",
      priority: "",
      expRight: false,
      error_dlg: false,
      type: "",
      etalase: "",
      etalases: "",
      customError: "",
      disable: true
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.deleteBannerIdx = this.deleteBannerIdx.bind(this)
  }

  toggle(){
    this.props.setNewCategoryP2P("toggleAdd", !this.props.toggleAdd)
    this.setState({
      name: "",
      description: "",
      icon: "",
      tmpIcon: "",
      banner: "",
      tmpBanner: "",
      border: "",
      tmpBorder: "",
      disable: true
    })
  }

  async componentDidMount(){
    const etalase = await callGetCategory("/admin/p2p/category/etalase")
    await this.setState({
      etalases: etalase,
    })
  }

  deleteBannerIdx(data){
    let tmpArr = []
    for(let i = 0; i < this.state.banners.length; i++){
      if(i !== data){
        tmpArr.push(this.state.banners[i])
      }
    }

    this.setState({
      banners: tmpArr
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon" || name === "border" || name === "banner"){
      const tmpName = "tmp"+name.charAt(0).toUpperCase() + name.slice(1);
      await this.setState({
        [name]: e.target.files[0],
        [tmpName]: URL.createObjectURL(e.target.files[0])
      })
    }else if(name === "banner"){
      const dataParse = JSON.parse(value)
      delete dataParse.id
      let tmpArr = []
      if(this.state.banners.length !== 0){
        tmpArr = [...this.state.banners]
      }
      tmpArr.push(dataParse)
      await this.setState({
        banners: tmpArr,
        banner: ""
      })

    }else{
      const filter = {
        [name]: value
      }
      if(name === "etalase") filter.disable = false
      await this.setState(filter)
    }
  }

  async submitForm(){
    try {
      if(!this.state.icon || !this.state.priority || !this.state.banner || !this.state.border){
        await this.setState({
          error_dlg: true,
          customError: "Must input all data form!"
        })
      } else{
        await this.setState({
          expRight: true
        })

      const resultIcon = await uploadFoto(this.state.icon)
      const tmpArr = []
      const resultBanner = await uploadFoto(this.state.banner)
      const resultBorder = await uploadFoto(this.state.border)
      tmpArr.push({name: "banner", icon: resultBanner})
      tmpArr.push({name: "border", icon: resultBorder})

      let obj = {
        name: this.state.name,
        type: this.state.type,
        icon: resultIcon,
        banners: tmpArr,
        active: this.state.status === "1" ? 1 : 0,
        priority: this.state.priority, 
        etalase: this.state.etalase
      }

        await this.props.addNewCategoryP2P("/admin/p2p/category", obj, "POST")
        await this.setState({
          name: "",
          icon: "",
          status: "0",
          priority: ""
        })
        await this.setState({
          expRight: false
        })
        this.toggle()
      }
        
    } catch (error) {
      console.log(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal isOpen={this.props.toggleAdd} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} >Add Category</ModalHeader>
          <AvForm onValidSubmit={this.submitForm}>
            <FormGroup>
              <Row>
              <Col sm="6">
              <Label sm="12">Type</Label>
              <Input required type="select" className="ml-2" name="type" onChange={this.onChangeInput} id="exampleSelect">
                <option selected disabled>Select Type</option>
                <option value="P2P">P2P</option>
                <option value="P2B">P2B</option>
              </Input>
              </Col>
              <Col sm={{ offset: 0, size: 6 }} >
                <Label sm="12">Etalase</Label>
              <Input required type="select" style={{marginLeft: "-10px"}} name="etalase" onChange={this.onChangeInput} id="exampleSelect">
                <option selected disabled>Select Etalase</option>
                {this.state.etalases && this.state.etalases.map(el => {
                  return <option key={el._id} value={el._id}>{el.name}</option>
                })}
              </Input>
              </Col>
              </Row>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Name</Label>
              <Col sm="12">
                <AvField disabled={this.state.disable} required type="text" placeholder="John Doe" name="name" value={this.state.name} onChange={this.onChangeInput}/>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Icon</Label>
                <FormGroup>
                  {this.state.tmpIcon ?
                  <>
                  <Card style={{width: '12rem'}}>
                    <img alt="icon" src={this.state.tmpIcon}/>
                      <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                  </Card>
                  </>
                  :
                  "" }
                </FormGroup>
                <CustomInput disabled={this.state.disable} type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Banner</Label>
                <FormGroup>
                  {this.state.tmpBanner ?
                  <>
                  <Card style={{width: '12rem'}}>
                    <img alt="icon" src={this.state.tmpBanner}/>
                      <Button onClick={() => this.setState({banner: "", tmpBanner: ""})}> X </Button>
                  </Card>
                  </>
                  :
                  "" }
                </FormGroup>
                <CustomInput disabled={this.state.disable} type="file" id="exampleFile" accept={['.png', '.jpg']} name="banner" onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm="12">
                <Label >Border</Label>
                <FormGroup>
                  {this.state.tmpBorder ?
                  <>
                  <Card style={{width: '12rem'}}>
                    <img alt="icon" src={this.state.tmpBorder}/>
                      <Button onClick={() => this.setState({border: "", tmpBorder: ""})}> X </Button>
                  </Card>
                  </>
                  :
                  "" }
                </FormGroup>
                <CustomInput disabled={this.state.disable} type="file" id="exampleFile" accept={['.png', '.jpg']} name="border" onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label sm="12">Priority</Label>
              <Col sm="12">
                <AvField disabled={this.state.disable} required type="text" placeholder="Enter priority" name="priority" value={this.state.priority} onChange={this.onChangeInput} />
              </Col>
            </FormGroup>
            <FormGroup>
            <Label sm="12">Status</Label>
              <Col sm="12">
              <Input disabled={this.state.disable} type="select" name="status" onChange={this.onChangeInput} id="exampleSelect">
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </Input>
              </Col>
            </FormGroup>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          <LaddaButton
            loading={this.state.expRight}
            onClick={this.submitForm}
            data-style={EXPAND_RIGHT}
            className="btn btn-primary"
          >Save changes</LaddaButton>
        </ModalFooter>
          </AvForm>
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleAdd } = state.CategoryP2P
  return { toggleAdd };
}

export default withRouter(connect(mapStatetoProps, { setNewCategoryP2P, addNewCategoryP2P })(ModalAddCategory))