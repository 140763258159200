import { CHECK_BANNER, SET_BANNER, SET_CHANGE_BANNER, ADD_BANNER } from "./actionTypes"

export const checkBanner = (history, state, flag) => {
  return {
    type: CHECK_BANNER,
    payload: {history, state, flag}
  }
}

export const setBanner = (props) => {
  return {
    type: SET_BANNER,
    payload: props
  }
}

//edit property di reducer berdasarkan nama initialState
export const setNewBanner = (name, data) => {
  return {
    type: SET_CHANGE_BANNER,
    payload: {name, data}
  }
}

export const addNewBanner = (url, state, method, id) => {
  return {
    type: ADD_BANNER,
    payload: {url, state, method, id}
  }
}