import { CHECK_CATEGORY, SET_CATEGORY, SET_CHANGE_CATEGORY, ADD_CATEGORY } from "./actionTypes"

export const checkCategory = (history, state, flag) => {
  return {
    type: CHECK_CATEGORY,
    payload: {history, state, flag}
  }
}

export const setCategory = (props) => {
  return {
    type: SET_CATEGORY,
    payload: props
  }
}

export const setNewCategory = (name, data) => {
  return {
    type: SET_CHANGE_CATEGORY,
    payload: {name, data}
  }
}

export const addNewCategory = (url, state, method, id) => {
  return {
    type: ADD_CATEGORY,
    payload: {url, state, method, id}
  }
}