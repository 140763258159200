import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CUD_POST } from '../post/actionTypes';
import { setPost, setDataPost } from '../post/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* contentPost({payload: {url, state, method, id}}) {
    try {
        const responseStatus = yield call(hitAPIWasmarket, url, state, method, id)
        yield put(setDataPost("msgSuccess", responseStatus.data))
        yield put(setDataPost("confirm", true))
        const response = yield call(callGetCategory, "/admin/post", {page: 1, size: 10 })
        yield put(setPost(response))
        
    } catch (error) {
        if(error.responseCode === 404){
            yield put(setPost({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
        } else {
            const response = yield call(callGetCategory, "/admin/post", {page: 1, size: 10 })
            yield put(setPost(response))
            yield put(setDataPost("confirmError", true))
            yield put(setDataPost("msgError", error.message))
        }
    }
}

export function* watchContentPost() {
    yield takeEvery(CUD_POST, contentPost)
}

function* cudPostSaga() {
    yield all([fork(watchContentPost)]);
}

export default cudPostSaga;