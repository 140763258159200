import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, CustomInput, ModalFooter, Button, Col, FormGroup, Label, Row, Card, Input } from 'reactstrap';
import { setNewBrandP2P, addNewBrandP2P } from "../store/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { uploadFoto } from '../../../helpers/authUtils';

class ModalEditBrandP2P extends Component{
  constructor(props){
    super(props)
    this.state = {
      name: "",
      icon: "",
      tmpIcon: "",
      status: "0",
      priority: "",
      expRight: false,
      error_dlg: false,
      tmpBanner: "",
      customError: "",
      count: 0
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  toggle(){
    this.props.setNewBrandP2P("toggleEdit", !this.props.toggleEdit)
    this.setState({
      name: "",
      banner: "",
      icon: "",
      tmpIcon: ""
    })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "icon"){
      await this.setState({
        icon: e.target.files[0],
        tmpIcon: URL.createObjectURL(e.target.files[0])
      })
    }else{
      await this.setState({
        [name]: value
      })
    }
  }

  async submitForm(){
    try {
      let resultIcon
      if(this.state.icon){
        resultIcon = await uploadFoto(this.state.icon)
        await this.props.onChangeInput({target:{name: "icon",value: resultIcon}})
      }

      const obj = {
        name: this.props.detail.name, 
        icon: resultIcon ? resultIcon : this.props.detail.icon, 
        type: this.props.detail.type, 
        category_id: this.props.detail.categori._id, 
        trade_tutorial: this.props.detail.trade_tutorial, 
        banners: this.props.detail.banners, 
      }
      await this.props.addNewBrandP2P("/admin/p2p/brand", obj, "PUT", this.props.detail._id)
      await this.setState({
        expRight: false,
        count: 0
      })
      this.toggle()
        
    } catch (error) {
      console.error(error)
      this.toggle()
      await this.setState({
        error_dlg: true,
        customError: error.response.message ? error.response.message : "Internal server Error when submit"
      })
    }
    
  }

  render() {
    return(
      <React.Fragment>
        <Modal className="modal-lg" isOpen={this.props.toggleEdit} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Edit Brand</ModalHeader>
        {this.props.detail ? 
          <AvForm onValidSubmit={this.submitForm}>
            <FormGroup>
              <Row>
              <Col sm="6">
            <Label sm="12">Type</Label>
              <Input required type="select" name="type" className="ml-2" onChange={this.props.onChangeInput} id="exampleSelect">
                <option disabled value="">Select type</option>
                {this.props.detail.type === "UNIT" ?
                <>
                <option selected value="UNIT">UNIT</option>
                <option value="NONUNIT">NONUNIT</option>
                </>
                :
                <>
                <option value="UNIT">UNIT</option>
                <option selected value="NONUNIT">NONUNIT</option>
                </>
                }
              </Input>
              </Col>
              <Col sm="6">
              <Label sm="12">Category</Label>
                <Input required type="select" style={{marginLeft: "-10px"}} name="category" onChange={this.props.onChangeInput}>
                  <option disabled value="">Select Category</option>
                    {
                      this.props.categori && this.props.categori.map(el => {
                        if(el._id === this.props.detail.categori._id) return <option selected value={el._id}>{el.name}</option>
                        else return <option value={el._id}>{el.name}</option>
                    })
                  }
                </Input>
              </Col>
              </Row>
            </FormGroup>
          <FormGroup>
          <Label sm="12">Name</Label>
            <Col sm="12">
              <AvField required type="text" placeholder="John Doe" name="name" value={this.props.detail.name} onChange={this.props.onChangeInput}/>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm="12">
              <Label >Icon</Label>
              <FormGroup>
                {this.state.tmpIcon ?
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.state.tmpIcon}/>
                    <Button onClick={() => this.setState({icon: "", tmpIcon: ""})}> X </Button>
                </Card>
                </>
                :
                <>
                <Card style={{width: '12rem'}}>
                  <img alt="icon" src={this.props.detail.icon}/>
                </Card>
                </> }
              </FormGroup>
              <CustomInput type="file" id="exampleFile" accept={['.png', '.jpg']} name="icon" onChange={this.onChangeInput} />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm="12">
              <Label>Trade Tutorial</Label>
              <AvField required type="textarea" rows={10} placeholder="Type some tutorial..." name="trade_tutorial" value={this.props.detail.trade_tutorial} onChange={this.props.onChangeInput}/>
            </Col>
            </FormGroup>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        <LaddaButton
          loading={this.state.expRight}
          data-style={EXPAND_RIGHT}
          className="btn btn-primary"
        >Save changes</LaddaButton>
      </ModalFooter>
        </AvForm>
        : ""}
          
      </Modal>

      {this.state.error_dlg ?
          <SweetAlert
            error
            title="Empty icon"
            onConfirm={() => this.setState({ error_dlg: false })}  >
            {this.state.customError}
          </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { toggleEdit, categori } = state.BrandP2P
  return { toggleEdit, categori };
}

export default withRouter(connect(mapStatetoProps, { setNewBrandP2P, addNewBrandP2P })(ModalEditBrandP2P))