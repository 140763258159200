import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col, Input, Label } from 'reactstrap';
import { setNewStoreBadge, addNewStoreBadge } from "../store/storeBadge/actions"

class ModalEditStoreBadge extends Component{
    constructor(props){
        super(props)
        this.state = {
            badges: []
        }
        this.toggle = this.toggle.bind(this)
        this.submitAction = this.submitAction.bind(this)
        this.checkBadge = this.checkBadge.bind(this)
    }

    toggle = async () => {
        await this.props.setNewStoreBadge("toggleEdit", !this.props.toggleEdit)
    }

    submitAction = async () => {
        const result = {
            badges: this.props.data.badges
        }
        this.props.addNewStoreBadge("/admin/store-badge", result, "PUT", this.props.data.id)
        await this.toggle()
    }

    checkBadge = (id) => {
        for(let i = 0; i < this.props.data.badges.length; i++){
            let el = this.props.data.badges[i].id
            if(el === id){
                return 1
            }
        }
        return 0
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} >Store Badge</ModalHeader>
                    <ModalBody  style={{backgroundColor: "#f2f2f2"}}>
                    {!this.props.data.id ? <p></p> :
                        <Row>
                            <Col>
                                <ul className="traffic-sales list-group list-group-flush mb-3 border-bottom rounded" >
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">User ID</span>
                                        <span className="traffic-sales-amount">
                                        {!this.props.data.id ? <b>Not Yet</b> : <b>{this.props.data.id}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Name</span>
                                        <span className="traffic-sales-amount">
                                        {!this.props.data.name ? <b>Not Yet</b> : <b>{this.props.data.name}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Email</span>
                                        <span className="traffic-sales-amount">
                                        {!this.props.data.email ? <b>Not Yet</b> : <b>{this.props.data.email}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Store Ratting</span>
                                        <span className="traffic-sales-amount">
                                        { <b>{this.props.data.storeRating !== 0 ? this.props.data.storeRating : 0}</b>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Store Logo</span>
                                        <span className="traffic-sales-amount">
                                        {!this.props.data.storeLogo ? <b>Not Yet</b> : <img src={this.props.data.storeLogo} alt="logoStore" width="200px" height="80px"/>}
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                        </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <Label for="checkbox2">Badge</Label>
                                        <Row>
                                            {this.props.badges.map((el) => {
                                                return (
                                                    this.checkBadge(el.id) ? 
                                                    <Col xl="4" lg="4" md="6" sm="6" key={el.id}>
                                                        <Label check className="ml-3">
                                                        <Input type="checkbox" checked value={el.id} name="badges" onChange={this.props.onChangeInput}/>
                                                            {el.name}
                                                        </Label>
                                                    </Col> :
                                                    <Col xl="4" lg="4" md="6" sm="6" key={el.id}>
                                                        <Label check className="ml-3">
                                                        <Input type="checkbox" value={el.id} name="badges" onChange={this.props.onChangeInput}/>
                                                            {el.name}
                                                        </Label>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    }
                    </ModalBody>
                    <ModalFooter>
                    <Button color="secondary" className="text-dark" onClick={this.toggle}>Cancel</Button>
                    <Button color="success" className="text-dark" onClick={this.submitAction}>Submit</Button>
                </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { toggleEdit, badges } = state.StoreBadge
    return { toggleEdit, badges };
}

export default withRouter(connect(mapStatetoProps, { setNewStoreBadge, addNewStoreBadge })(ModalEditStoreBadge));