import { combineReducers  } from 'redux';
import Layout from './layout/reducer';

//============ Auth Reducers =============*
import Login from './auth/login/reducer';
import Username from "../modules/Category/store/username/reducer"
import Category from "../modules/Category/store/category/reducer"
import Brand from "../modules/Brand/store/brand/reducer"
import ProductMaster from "../modules/ProductMaster/store/product/reducer"
import Banner from "../modules/Banner/store/banner/reducer"
import ChangePassword from "../modules/ChangePassword/store/reducer"
import ManagementUser from "../modules/ManagementUser/store/reducer"
import KYC from "../modules/ManagementUser/store/kyc/getData/reducer"
import CashOut from "../modules/CashOut/store/cashout/reducer"
import CashIn from "../modules/CashIn/store/cashin/reduce"
import Monitoring from "../modules/MonitoringBalance/store/monitoring/reducer"
import MutasiBank from "../modules/MutasiBank/store/reducer"
import Transaction from "../modules/Transaction/store/reducer"
import Badge from "../modules/Badges/store/badge/reducer"
import StoreBadge from "../modules/StoreBadge/store/storeBadge/reducer"
import Message from "../modules/Messages/store/reducer"
import Setting from "../modules/Setting/store/setting/reducer"
import Post from "../modules/Content/Post/store/post/reducer"
import Tag from "../modules/Content/Tag/store/tag/reducer"
import PostTag from "../modules/Content/PostTag/store/postTag/reducer"
import CountUser from "../modules/CountUsers/store/reducer"
import P2P from "../modules/P2P/store/reducer"
import CashOutP2P from "../modules/CashOutP2P/store/cashout/reducer"
import Komplain from "../modules/P2PKomplain/store/reducer"
import LogMonit from "../modules/MonitLogs/store/reducer"
import CategoryP2P from "../modules/CategoryP2P/store/reducer"
import EtalaseP2P from "../modules/EtalaseP2P/store/reducer"
import BrandP2P from "../modules/BrandP2P/store/reducer"

const rootReducer = combineReducers({ Layout, Login, Username, Category, Brand, ProductMaster, 
  Banner, ChangePassword, ManagementUser, KYC, CashOut, CashIn , Monitoring, MutasiBank,
  Transaction, Badge, StoreBadge, Message, Setting, Post, Tag, PostTag, CountUser,
  P2P, CashOutP2P, Komplain, LogMonit, CategoryP2P, EtalaseP2P, BrandP2P
});

export default rootReducer;