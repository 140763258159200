import { CHECK_BADGE, SET_BADGE, SET_CHANGE_BADGE, ADD_BADGE } from "./actionTypes"

export const checkBadge = (history, state, flag) => {
  return {
    type: CHECK_BADGE,
    payload: {history, state, flag}
  }
}

export const setBadge = (props) => {
  return {
    type: SET_BADGE,
    payload: props
  }
}

//edit property di reducer berdasarkan nama initialState
export const setNewBadge = (name, data) => {
  return {
    type: SET_CHANGE_BADGE,
    payload: {name, data}
  }
}

export const addNewBadge = (url, state, method, id) => {
  return {
    type: ADD_BADGE,
    payload: {url, state, method, id}
  }
}