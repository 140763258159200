import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_USER_COUNT, } from './actionTypes';
import {  setCountUser } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';

//============ Dispatch Redux Actions directly =============*
function* countUser({payload: {history, state, flag}}) {
    try {
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        const response = yield call(callGetCategory, "/admin/bo/count-user", state)
        yield put(setCountUser(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setCountUser({
                timestamp: "",
                user: 0,
            }))
        }
    }
}

export function* watchCountUser() {
    yield takeEvery(CHECK_USER_COUNT, countUser)
}

function* countUserSage() {
    yield all([fork(watchCountUser)]);
}

export default countUserSage;