import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_MONITORING, } from './actionTypes';
import {  setMonitoring } from './actions';
import { setUsername } from "../../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* monitoring({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/monitoring", state)
        yield put(setMonitoring(response))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setMonitoring({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
                saldoAkun: error.data.saldoAkun,
                saldoMember: error.data.saldoMember,
                saldoCashOut: error.data.saldoCashOut,
                balance: error.data.balance,
            }))
        }
    }
}

export function* watchMonitoring() {
    yield takeEvery(CHECK_MONITORING, monitoring)
}

function* monitoringSaga() {
    yield all([fork(watchMonitoring)]);
}

export default monitoringSaga;