import { CHECK_MUTASI_BANK, SET_MUTASI_BANK, SET_DATA_MUTASI_BANK, EXPORT_MUTASI_BANK } from "./actionTypes"

export const checkMutasiBank = (history, state, flag) => {
  return {
    type: CHECK_MUTASI_BANK,
    payload: {history, state, flag}
  }
}

export const setMutasiBank = (props) => {
  return {
    type: SET_MUTASI_BANK,
    payload: props
  }
}

export const setDataMutasiBank = (name, data) => {
  return {
    type: SET_DATA_MUTASI_BANK,
    payload: {name, data}
  }
}

export const exportMutasiBank = (state) => {
  return {
    type: EXPORT_MUTASI_BANK,
    payload: {state}
  }
}