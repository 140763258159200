import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge, Container, Row, Col, Card, CardBody, Input, Button, Tooltip } from "reactstrap";
import DataTable from 'react-data-table-component';
import { activateAuthLayout } from "../../../store/actions";
import { convertToRupiah } from "../../../helpers/authUtils"
import { checkCashOutP2P, setDataCashOutP2P, updateCashOutP2P, exportCashOutP2P } from "../store/cashout/actions"
import moment from "moment"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"
import ModalDetail from "./ModalDetail"

class CashOutPage extends Component{
  constructor(props){
    const today = moment().format("YYYY-MM-DD")
    super(props)
    this.state = {
      name: "",
      from: today,
      to: today,
      status: "-1",
      method: "-1",
      msgStatus: "",
      nameUser: "",
      tmpStatus: "",
      remark: "",
      confirm: false,
      id: "",
      errorExp: false,
      tooltiprefresh: false,
      tooltipdownload: false,
      detailCashOut: ""
    }
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.searchName = this.searchName.bind(this)
    this.updateCashOutP2P = this.updateCashOutP2P.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.refreshPage = this.refreshPage.bind(this)
    this.exportFile = this.exportFile.bind(this)
    this.toolTipRefresh = this.toolTipRefresh.bind(this)
    this.toolTipDownload = this.toolTipDownload.bind(this)
    this.showModalDetail = this.showModalDetail.bind(this)
  }

  async componentDidMount(){
    let props = this.props
    await this.props.activateAuthLayout()
    await this.props.checkCashOutP2P(this.props.history, {
      page: props.currentPage,
      size: props.size,
      from: this.state.from,
      to: this.state.to
    }, true)
  }

  toolTipRefresh = () => {
    this.setState({
      tooltiprefresh: !this.state.tooltiprefresh
    })
  }

  toolTipDownload = () => {
    this.setState({
      tooltipdownload: !this.state.tooltipdownload
    })
  }

  async onChangeInput(e){
    const { name, value } = e.target
    let props = this.props
    await this.setState({
      [name]: value
    })

    if(name !== "name"){
      await this.props.setDataCashOutP2P("size", 10)
      await this.props.setDataCashOutP2P("page", 1)
      await this.props.checkCashOutP2P(this.props.history, {
        page: props.page,
        size: props.size,
        from: this.state.from,
        to: this.state.to,
        status: this.state.status,
        method: this.state.method,
        name: this.state.name
      }, false)
    }
  }

  async refreshPage(){
    let props = this.props
    const today = moment().format("YYYY-MM-DD")
    await this.setState({
      from: today,
      to: today,
      status: "-1",
      method: "-1",
      name: ""
    })
    await this.props.checkCashOutP2P(this.props.history, {
      page: props.currentPage,
      size: props.size,
      from: this.state.from,
      to: this.state.to
    }, false)
  }

  async changeRowsPerPage(size){
    let props = this.props
    await this.props.setDataCashOutP2P("size", size)
    await this.props.checkCashOutP2P(this.props.history, {
      from: this.state.from,
      to: this.state.to,
      page: props.page,
      size: size,
      status: this.state.status,
      method: this.state.method,
      name: this.state.name
    }, false)
  }

  async onChangePage(page){
    await this.props.setDataCashOutP2P("page", page)
    let props = this.props
    await this.props.checkCashOutP2P(this.props.history, {
      from: this.state.from,
      to: this.state.to,
      page: page,
      size: props.size,
      status: this.state.status,
      method: this.state.method,
      name: this.state.name
    }, false)
  }

  async updateCashOutP2P(){
    await this.setState({ confirm: false, })
    await this.props.updateCashOutP2P("/admin/cashout/p2p", {
      status: this.state.tmpStatus,
      from: this.state.from,
      to: this.state.to,
      method: this.state.method ? this.state.method : "",
      name: this.state.name ? this.state.name : "",
      tmpStatus: this.state.status,
      remark: this.state.remark
    }, "PUT", this.state.id)
    await this.setState({ id: "", msgStatus: "", nameUser: "" })
  }

  async searchName(){
    await this.props.setDataCashOutP2P("page", 1)
    await this.props.setDataCashOutP2P("size", 10)
    await this.props.checkCashOutP2P(this.props.history, {
      from: this.state.from,
      to: this.state.to,
      status: this.state.status,
      method: this.state.method,
      page: this.props.page,
      size: this.props.size,
      name: this.state.name
    }, false)
  }

  async exportFile(){
    if(this.props.cashs.length === 0){
      this.setState({errorExp: true})
    } else {
        console.log("MASUKEXPORT")
      await this.props.exportCashOutP2P({
        from: this.state.from,
        to: this.state.to,
        status: this.state.status,
        method: this.state.method,
        name: this.state.name
      })
    }
  }

    async showModalDetail(data){
        console.log(data)
        await this.setState({detailCashOut: data})
        await this.props.setDataCashOutP2P("modalDetail", !this.props.modalDetail)
    }

  render() {
    const columns = [
        {
            name: "TrxId",
            selector: "id",
            sortable: true,
            width: "50px"
        },
        {
            name: "Waktu",
            selector: "created_at",
            width: "140px",
            sortable: true,
            cell: row => {
              return (
                moment(row.createdAt).format('YYYY/MM/DD HH:mm:ss')
                
              )
            }
        },
        {
            name: "Member",
            selector: "user_bank.user.name",
            sortable: true,
            width: "150px"
        },
        {
            name: "Method",
            selector: "user_bank.bank_account.bankName",
            sortable: true,
            width: "150px"
        },
        {
            name: "Nominal",
            selector: "amount",
            sortable: true,
            width: "150px",
            cell: row => {
              return(
                convertToRupiah(row.amount)
              )
            }
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            center: true,
            width: "100px",
            cell: row => {
                return (
                    row.status === 0 ? <Badge className="rounded-pill" color="primary">Pending</Badge> :
                    row.status === 1 ?  <Badge className="rounded-pill text-dark" color="success" >Approve</Badge> :
                    row.status === 2 ? <Badge color="danger">Reject</Badge> :
                    <Badge color="warning">Cancel</Badge>
                )
            },
            
        },
        {
            name: "Remark",
            selector: "remark",
            sortable: true,
            center: true,
            width: "200px",
        },
        {
            name: "Action",
            sortable: true,
            cell: (row) => {
                return (
                    row.status === 0 ? (
                        <>
                            <Badge className="rounded-pill mr-2" color="primary" style={{cursor: "pointer"}}
                            onClick={() => this.showModalDetail(row)}>
                                <i className="fa fa-eye"></i> Detail
                            </Badge>
                            <Badge className="rounded-pill mr-2 text-dark" color="success" style={{cursor: "pointer"}} 
                            onClick={() => this.setState({nameUser: row.user_bank.user.name, confirm: true, tmpStatus: 1, msgStatus: "Approve", id: row.id })}>
                                <i className="fa fa-check text-dark"></i> Approve
                            </Badge>
                            
                            <Badge className="rounded-pill" color="danger" style={{cursor: "pointer"}}
                            onClick={() => this.setState({nameUser: row.user_bank.user.name, confirm: true, tmpStatus: 2, msgStatus: "Reject", id: row.id })}>
                                <i className="fa fa-trash-o"></i> Reject
                            </Badge>
                        </>
                    ) :  
                    <Badge className="rounded-pill" color="primary" style={{cursor: "pointer"}}
                    onClick={() => this.showModalDetail(row)}>
                        <i className="fa fa-eye"></i> Detail
                    </Badge>
                )
            }
        },
    ]

    
    return (
      <React.Fragment>
        <NotificationMessage />
        {/* START */}
        <h2 className="card_title mb-3">Cash Out</h2>
        <Row>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 mt-mob-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="feather ft-users"></i>
                </div>
                <div className="icon_specs">
                  <p>Jumlah</p>
                  <span>{this.props.total_cashout}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="fa fa-money"></i>
                </div>
                <div className="icon_specs">
                  <p>Amount</p>
                  <span>{!this.props.amount ? 0 : convertToRupiah(this.props.amount)}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="fa fa-clock-o"></i>
                </div>
                <div className="icon_specs">
                  <p>Pending</p>
                  <span>{this.props.pending}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
      </Row>
      {/* END */}
      <Row>
        <Col className="stretched_card">
          <Card>
            <CardBody>
              <Container className="mt-2">
                <Row>
                  <Col xl="2" lg="2" md="4" sm="12">
                    <Input type="select" name="status" value={this.state.status} onChange={this.onChangeInput}>
                      <option selected disabled value="-1">Status Transaksi</option>
                      <option value="0">Pending</option>
                      <option value="1">Approve</option>
                      <option value="2">Reject</option>
                    </Input>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="12">
                    <Input type="select" name="method" value={this.state.method} onChange={this.onChangeInput}>
                      <option selected disabled value="-1">Payment Method</option>
                      {!this.props.banks ? "" : 
                        this.props.banks.map(el => {
                          return <option key={el.id} value={el.id}>{el.name}</option>
                        })  
                    }
                    </Input>
                  </Col>
                  <Col xl="4" lg="4" md="12" sm="12">
                  <div className="input-group">
                    <p style={{margin: "auto 0"}} className="mr-1">From</p>
                    <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                    <p style={{margin: "auto 0"}} className="ml-1 mr-1">To</p>
                    <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                  </div>
                  </Col>
                  <Col xl="3" lg="3" md="4" sm="12">
                  <div className="input-group">
                    <Input className="mr-1" type="text" placeholder="type some name" name="name" value={this.state.name} onChange={this.onChangeInput}/>
                    <Button onClick={this.searchName}>Search</Button>
                  </div>
                  </Col>
                  <Col xl="1" lg="1" md="4" sm="12">
                  <i style={{cursor: "pointer"}} className="fa fa-refresh mt-2 mr-4" onClick={this.refreshPage} id="ToolRefresh"></i>
                  <i style={{cursor: "pointer"}} className="fa fa-download mt-2" onClick={this.exportFile} id="ToolDownload"></i>
                  </Col>
                </Row>
              </Container>
              <Container>
              <DataTable
                noHeader={true}
                columns={columns}
                data={this.props.cashs}
                pagination
                responsive={true}
                paginationTotalRows={this.props.totalItems}
                paginationRowsPerPageOptions={[10,20,50,100]}
                onChangeRowsPerPage={this.changeRowsPerPage}
                onChangePage={this.onChangePage}
                paginationServer={true}
              />
                
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.props.confirm ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.props.setDataCashOutP2P("confirm", false)}  >
            {this.props.msgSuccess}
          </SweetAlert> : null
        }

        {this.state.errorExp ?
          <SweetAlert
            error
            title="FAILED"
            onConfirm={() => this.setState({errorExp: false})}  >
            Don't have data to download
          </SweetAlert> : null
        }

        {this.state.confirm ? 
          <SweetAlert
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.updateCashOutP2P()}
            onCancel={() => this.setState({ confirm: false })} >
              Are you sure to <h5>{this.state.msgStatus}</h5> {this.state.nameUser}
              <Input type="text" placeholder="Input remark" onChange={(e) => this.setState({remark: e.target.value})}></Input>
            </SweetAlert> : null
          }

          <Tooltip placement="bottom" isOpen={this.state.tooltiprefresh} target="ToolRefresh" toggle={this.toolTipRefresh}>
            Refresh
          </Tooltip>
          <Tooltip placement="top" isOpen={this.state.tooltipdownload} target="ToolDownload" toggle={this.toolTipDownload}>
            Download
          </Tooltip>

            <ModalDetail user={this.state.detailCashOut} />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { currentPage, totalItems, size, cashs, amount, pending, total_cashout, banks, confirm, msgSuccess, modalDetail } = state.CashOutP2P
  return { currentPage, totalItems, size, cashs, amount, pending, total_cashout, banks, confirm, msgSuccess, modalDetail };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkCashOutP2P, setDataCashOutP2P, updateCashOutP2P, exportCashOutP2P })(CashOutPage));