import { CHECK_CASH_IN, SET_CASH_IN, SET_DATA_CASH_IN } from "./actionTypes"

const initialState = {
  cashsin: [], currentPage: 1, totalItems: 0, totalPages: 0, size: 10, amount: 0,
  pending: 0, total_cashin: 0, banks: [], confirm: false, msgSuccess: ""
}

const cashIn = (state = initialState, action) => {
  switch(action.type){
    case CHECK_CASH_IN:
      state = {
        ...state
      }
      break;
    case SET_CASH_IN:
      state = {
        ...state,
        cashsin: action.payload.rows,
        currentPage: action.payload.currentPage,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
        pending: action.payload.pending,
        amount: action.payload.amount,
        total_cashin: action.payload.total_cashin,
        banks: action.payload.nameBank
      }
      break;
      case SET_DATA_CASH_IN:
        const { name, data } = action.payload
        state = {
          ...state,
          [name]: data
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default cashIn