import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_BRAND_P2P } from './actionTypes';
import { setBrandP2P } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category_p2p({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/p2p/brand", state)
        yield put(setBrandP2P(response))
        // yield put(setNewCategoryP2P("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setBrandP2P({
                data: [],
            }))
            // yield put(setNewCategoryP2P("expRight", false))
        }
    }
}

export function* watchBrandP2P() {
    yield takeEvery(CHECK_BRAND_P2P, category_p2p)
}

function* brandP2PSaga() {
    yield all([fork(watchBrandP2P)]);
}

export default brandP2PSaga;