import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { Row, Col, Card, CardBody, Container, Input, Badge } from "reactstrap";
import DataTable from 'react-data-table-component';
import moment from "moment"
import { checkLogMonit, setLogMonit, setDataLogsMonit } from "../store/actions"
import ModalDetail from "./DetailMonitLog"

class LogMonitPage extends Component{
  constructor(props){
    const today = moment().format("YYYY-MM-DD")
    super(props)
    this.state = {
      from: today,
      to: today,
      tmpStatus: ""
    }
    this.onChangeInput = this.onChangeInput.bind(this)
    this.buttonSee = this.buttonSee.bind(this)
  }

  componentDidMount(){
    this.props.activateAuthLayout()
    this.props.checkLogMonit(this.props.history, {
      from: this.state.from, to: this.state.to
    }, true)
  }

  async onChangeInput(e){
    const {value, name} = e.target
    await this.setState({
      [name]: value
    })

    this.props.checkLogMonit(this.props.history, {
      from: this.state.from, to: this.state.to, status: this.state.tmpStatus
    }, true)
  }

  async buttonSee(data){
    await this.props.setDataLogsMonit("modalDetail", !this.props.modalDetail)
    await this.props.setDataLogsMonit("detail", data)
  }

  render(){
    const columns = [
      {
        name: "",
        cell: row => {
            return(
                <Badge className="rounded-pill mr-2" color="primary" style={{cursor: "pointer"}}
                    onClick={() => this.buttonSee(row)}>
                        <i className="fa fa-eye"></i> Detail
                </Badge>
            )
        }
      },
      {
        name: "ID",
        selector: "_id",
        width: "280px"
      },
      {
        name: "Status",
        selector: "status",
        cell: row => {
          return (
              row.status === 1 ? <Badge className="rounded-pill" color="success" >Success</Badge> :
              <Badge className="rounded-pill" color="danger" >Failed</Badge>
          )
        },
      },
      {
        name: "Request",
        selector: "request",
        width: "200px"
      },
      {
        name: "Request Time",
        selector: "requestTime",
        width: "200px",
        cell: row => {
          return moment(row.requestTime).utcOffset("+0800").format("YYYY-MM-DD HH:mm:ss")
        }
      },
      {
        name: "Response",
        selector: "response",
        width: "200px"
      },
      {
        name: "Response Time",
        selector: "responseTime",
        width: "200px",
        cell: row => {
          return moment(row.responseTime).utcOffset("+0800").format("YYYY-MM-DD HH:mm:ss")
        }
      }
    ]
    return(
      <React.Fragment>
        <h2 className="card_title mb-3">Monitoring Log Callback</h2>
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container className="mt-2">
                  <Row>
                  <Col xl="2" lg="2" md="4" sm="12">
                    <div className="input-group">
                      <Input type="select" name="tmpStatus" value={this.state.tmpStatus} onChange={this.onChangeInput}>
                        <option value="0">All Status</option>
                        <option value="1">Success</option>
                        <option value="2">Failed</option>
                      </Input>
                    </div>
                    </Col>
                    <Col xl="4" lg="4" md="12" sm="12">
                      <div className="input-group">
                        <p style={{margin: "auto 0"}} className="mr-1"><b>From</b></p>
                        <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                        <p style={{margin: "auto 0"}} className="ml-1 mr-1"><b>To</b></p>
                        <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    noHeader={true}
                    columns={columns}
                    data={this.props.logs}
                    responsive={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalDetail />
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { logs, modalDetail } = state.LogMonit
  return { logs, modalDetail };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkLogMonit, setLogMonit, setDataLogsMonit })(LogMonitPage));