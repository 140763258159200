import { CHECK_ETALASE_P2P, SET_ETALASE_P2P, SET_CHANGE_ETALASE_P2P } from "./actionTypes"

const initialState = {
  etalases: [], toggleAdd: false, toggleEdit: false, categoryP2P: "", successDelete: false,
  descSuccess: "", failedDelete: false, descFailed: ""
}

const etalaseP2P = (state = initialState, action) => {
  switch(action.type){
    case CHECK_ETALASE_P2P:
      state = {
        ...state
      }
      break;
    case SET_ETALASE_P2P:
      state = {
        ...state,
        etalases: action.payload
      }
      break;
    case SET_CHANGE_ETALASE_P2P:
      const {name, data} = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default etalaseP2P