import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_P2P, } from './actionTypes';
import { setP2P, setDataP2P } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* p2p({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/p2p", state)
        yield put(setP2P(response))
        yield put(setDataP2P("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setP2P({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
            yield put(setDataP2P("expRight", false))
        }
    }
}

export function* watchP2P() {
    yield takeEvery(CHECK_P2P, p2p)
}

function* p2pSaga() {
    yield all([fork(watchP2P)]);
}

export default p2pSaga;