import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_DATA_KOMPLAIN_P2P } from '../actionTypes';
import {  setKomplainP2P } from '../actions';
import { setDataP2P } from '../../../P2P/store/actions'
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updatesDataKomplainP2P({payload: {url, state, method, id}}) {
    try {

        const { statusOrder, buyer_id } = state

        yield call(hitAPIWasmarket, url, {status: statusOrder, buyer_id}, method, id)
        const response = yield call(callGetCategory, "/admin/p2p/komplain", { id: "" })

        yield put(setKomplainP2P(response))
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("confirm", true))
        yield put(setDataP2P("expRightEdit1", false))
        yield put(setDataP2P("expRightEdit2", false))
        yield put(setDataP2P("msgSuccess", "Sukses update transaction P2P"))
    } catch (error) {
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("expRightEdit1", false))
        yield put(setDataP2P("expRightEdit2", false))
        yield put(setDataP2P("failedUpdate", true))
        yield put(setDataP2P("msgFailed", error.message))
        console.log(error);
    }
}

export function* watchUpdateDataKomplainP2P() {
    yield takeEvery(UPDATE_DATA_KOMPLAIN_P2P, updatesDataKomplainP2P)
}

function* updateDataKomplainP2PSaga() {
    yield all([fork(watchUpdateDataKomplainP2P)]);
}

export default updateDataKomplainP2PSaga;