import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_PRODUCT_MASTER } from '../product/actionTypes';
import {  setProductMaster } from '../product/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* productMaster({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/product-master", {page: state.currentPage, size: state.size, search: state.nameProduct})
        yield put(setProductMaster(response))
    } catch (error) {
      console.log(error);
    }
}

export function* watchUserProductMaster() {
    yield takeEvery(ADD_PRODUCT_MASTER, productMaster)
}

function* addProductMasterSaga() {
    yield all([fork(watchUserProductMaster)]);
}

export default addProductMasterSaga;