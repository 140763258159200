import { CHECK_CATEGORY_P2P, SET_CATEGORY_P2P, SET_CHANGE_CATEGORY_P2P } from "./actionTypes"

const initialState = {
  categorysP2P: [], toggleAdd: false, toggleEdit: false, categoryP2P: "", successDelete: false,
  descSuccess: "", failedDelete: false, descFailed: ""
}

const categoryP2P = (state = initialState, action) => {
  switch(action.type){
    case CHECK_CATEGORY_P2P:
      state = {
        ...state
      }
      break;
    case SET_CATEGORY_P2P:
      state = {
        ...state,
        categorysP2P: action.payload
      }
      break;
    case SET_CHANGE_CATEGORY_P2P:
      const {name, data} = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default categoryP2P