import { CHECK_SETTING, SET_SETTING, SET_CHANGE_SETTING } from "./actionTypes"

const initialState = {
  settings: [], toggleEdit: false,
}

const setting = (state = initialState, action) =>{
  switch(action.type){
    case CHECK_SETTING:
      state = {
        ...state
      }
      break;
    case SET_SETTING:
      state = {
        ...state,
        settings: action.payload,
      }
      break;
    case SET_CHANGE_SETTING:
      const { name, data } = action.payload
      state = {
        ...state,
        [name]: data
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default setting