import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_BANNER } from '../banner/actionTypes';
import { setBanner } from '../banner/actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* banner({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/banner", {page: 1, size: 10})
        yield put(setBanner(response))
    } catch (error) {
    }
}

export function* watchUserBanner() {
    yield takeEvery(ADD_BANNER, banner)
}

function* cudBannerSaga() {
    yield all([fork(watchUserBanner)]);
}

export default cudBannerSaga;