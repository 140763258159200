import React, { Component } from "react";
import { activateAuthLayout } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkBrandP2P, setNewBrandP2P, addNewBrandP2P } from "../store/actions"
import { Container, Button, Row, Col, Card, CardBody, Label, Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import ModalAdd from "./ModalAdd"
import ModalEdit from "./ModalEdit"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"
import { callGetCategory } from '../../../helpers/authUtils';

class BrandP2PPages extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      id: "",
      name: "",
      icon: "",
      tmpIcon: "",
      categori: "",
      category: "",
      dataDelete: null,
      swal: false, success_dlg: false, dynamic_title: "", 
      dynamic_description: "",
      search: "",
      detail: ""
    };
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onShowModalAdd = this.onShowModalAdd.bind(this)
    // this.buttonEdit = this.buttonEdit.bind(this)
    this.buttonDelete = this.buttonDelete.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.searchName = this.searchName.bind(this)
    // this.deleteBannerIdx = this.deleteBannerIdx.bind(this)
  }

  async componentDidMount() {
    this.props.activateAuthLayout();
    this.props.checkBrandP2P(this.props.history, {}, true)
    const response = await callGetCategory("/admin/p2p/brand/categori")
    await this.props.setNewBrandP2P("categori", response)
  }

  handleKeyPress(e){
    if (e.key === "Enter") {
        this.searchName()
    }
  }

  async searchName(){
    await this.props.checkBrandP2P(this.props.history, {
      name: this.state.search,
      category: this.state.category
    }, false)
  }

  async onShowModalAdd(){
    await this.props.setNewBrandP2P("toggleAdd", !this.props.toggleAdd)
  }

  async buttonEdit(e, data){
    await this.setState({
      detail: data
    })
    await this.props.setNewBrandP2P("toggleEdit", !this.props.toggleEdit)
  }

  buttonDelete(data){
    this.props.addNewBrandP2P("/admin/p2p/brand", {}, "DELETE", data._id)
    this.setState({ swal: false, success_dlg: true, dynamic_title: 'Deleted', dynamic_description: 'Your file has been deleted.' })
  }

  async onChangeInput(e){
    const {name, value} = e.target
    if(name === "category"){
      await this.setState({
        [name]: value
      })
      this.props.checkBrandP2P(this.props.history, 
        { category: this.state.category, name: this.state.search }, false
      )
    }else if(name === "detail"){
      await this.setState({
        detail: ""
      })
    }else if(name === "banner"){
      const banners = this.state.detail.banners
      const dataParse = JSON.parse(value)
      delete dataParse.id
      let tmpArr = []
      if(banners.length !== 0){
        tmpArr = [...banners]
      }
      tmpArr.push(dataParse)
      const detail = {...this.state.detail, banners: tmpArr}
      await this.setState({
        detail: detail,
        banner: ""
      })

    }else{
      const detail = {...this.state.detail, [name]: value}
      await this.setState({
        detail: detail
      })
    }
  }
  
  render() {
    const columns = [
      {
        name: "ID",
        selector: "_id",
        width: "230px"
      },
      {
        name: "Name",
        selector: "name",
        width: "150px"
      },
      {
        name: "Type",
        selector: "type",
        width: "80px"
      },
      {
        name: "Icon",
        selector: "icon",
        width: "70px",
        cell: row => {
          return <img src={row.icon} alt={row.icon} />
        }
      },
      {
        name: "Category",
        selector: "categori.name",
        width: "150px"
      },
      {
        name: "Action",
        cell: (row) => {
          return (
            <>
              <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={(e) => this.buttonEdit(e, row)}></i>
              <i className="fa fa-trash text-danger" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="delete, trash, kill, x" onClick={() => this.setState({
                swal: true,
                dataDelete: row
              })}></i>
            </>
          )
        }
      },
    ]
    return (
      <React.Fragment>
        <NotificationMessage />
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col xl="2" lg="3" md="3" sm="6">
                      <Button onClick={this.onShowModalAdd}><i className="ion-plus-round" data-pack="default" data-tags="add, include, new, invite, +"></i>Add Brand</Button>
                    </Col>
                    <Col xl="3" lg="3" md="4" sm="12">
                      <Input type="select" name="category" value={this.state.category} onChange={this.onChangeInput}>
                        <option disabled selected value="">Select Category</option>
                        {!this.props.categori ? 
                        ""
                        :
                        this.props.categori.map(el => {
                          return <option value={el._id}>{el.name}</option>
                        })
                        }
                      </Input>
                    </Col>
                    <Col xl={{ offset: 2, size: 5 }} 
                        lg={{ offset: 1, size: 5 }} 
                        md="5" 
                        sm="12">
                          <div className="input-group">
                            <Label className="mt-2 mr-2">Search:</Label>
                            <Input className="mr-2" type="text" onKeyPress={this.handleKeyPress} name="search" placeholder="search name" value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
                            <Button color="primary" onClick={this.searchName}>Search</Button>
                          </div>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    title="Brand P2P"
                    columns={columns}
                    data={this.props.brandP2P}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalAdd />
        <ModalEdit onChangeInput={this.onChangeInput} 
          detail={this.state.detail}
        />

        {this.state.swal ? 
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.buttonDelete(this.state.dataDelete)}
            onCancel={() => this.setState({ swal: false, error_dlg: true, dynamic_title: 'Cancelled', dynamic_description: 'Your file is safe :)' })} >
            You won't be able to revert this!
            </SweetAlert> : null
          }

          {this.props.successDelete ?
            <SweetAlert
              success
              title={this.state.dynamic_title}
              onConfirm={() => this.props.setNewBrandP2P("successDelete", false)}  >
              {this.props.descSuccess}
            </SweetAlert> : null}
            
            {this.state.error_dlg ?
              <SweetAlert
                error
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({error_dlg: false})}  >
                {this.state.dynamic_description}
              </SweetAlert> : null}
        
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { brandP2P, toggleAdd, successDelete, descSuccess, failedDelete, descFailed, toggleEdit, categori } = state.BrandP2P
  return { brandP2P, toggleAdd, successDelete, descSuccess, failedDelete, descFailed, toggleEdit, categori }
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkBrandP2P, setNewBrandP2P, addNewBrandP2P })(BrandP2PPages));
