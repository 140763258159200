import { takeEvery, fork, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { EXPORT_TRANSACTION } from '../actionTypes';
//============ Authentication Functions =============*
import { exportFile } from '../../../../helpers/authUtils';
import fileDownload from 'js-file-download';
import moment from "moment"
//============ Dispatch Redux Actions directly =============*
function* exportTransaction({payload}) {
    try {
      const result = yield call(exportFile, "/admin/transaction/export", payload.state)
      fileDownload(result, "transaction (" + moment().format("YYYY-MM-DD") + ").xlsx")
    } catch (error) {
      console.log(error);
    }
}

export function* watchExportTransaction() {
    yield takeEvery(EXPORT_TRANSACTION, exportTransaction)
}

function* exportTransactionSaga() {
    yield all([fork(watchExportTransaction)]);
}

export default exportTransactionSaga;