import { CHANGE_PASSWORD, SET_RESPONSE } from "./actionTypes"

export const changePassword = (url, state, method) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {url, state, method}
  }
}

export const setNotif = (name, data) => {
  return {
    type: SET_RESPONSE,
    payload: {name, data}
  }
}