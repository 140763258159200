import { CHECK_BRAND, SET_BRAND, SET_CHANGE_BRAND, ADD_BRAND } from "./actionTypes"

export const checkBrand = (history, state, flag) => {
  return {
    type: CHECK_BRAND,
    payload: {history, state, flag}
  }
}

export const setBrand = (props) => {
  return {
    type: SET_BRAND,
    payload: props
  }
}

export const setNewBrand = (name, data) => {
  return {
    type: SET_CHANGE_BRAND,
    payload: {name, data}
  }
}

export const addNewBrand = (url, state, method, id) => {
  return {
    type: ADD_BRAND,
    payload: {url, state, method, id}
  }
}