import React, { Component } from "react"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { activateAuthLayout } from "../../../store/actions";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { convertToRupiah } from "../../../helpers/authUtils"
import { checkMonitoring, setDataMonitoring, checkBATransfer, exportBATransfer } from "../store/monitoring/actions"
import DataTable from 'react-data-table-component';
import moment from "moment"
import ModalAdd from "./ModalAdd"
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationMessage from "../../NotificationMessage/Pages/Notification"
import {Input} from "reactstrap"

class MonitoringBalancePage extends Component{
  constructor(props){
    const today = moment().format("YYYY-MM-DD")
    super(props)
    this.state = {
      from: today,
      to: today,
      failedExport: false
    }
    this.timeFormat = this.timeFormat.bind(this)
    this.refreshPage = this.refreshPage.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.exportData = this.exportData.bind(this)
    this.refreshBATF = this.refreshBATF.bind(this)
  }

  componentDidMount(){
    this.props.activateAuthLayout()
    this.props.checkMonitoring(this.props.history, {}, true)
    this.props.checkBATransfer({
      from: this.state.from,
      to: this.state.to,
      page: this.props.currentPage,
      size: this.props.size
    })
  }

  async onChangeInput(e){
    const {value, name} = e.target
    // let props = this.props
    await this.setState({
      [name]: value
    })

    await this.props.setDataMonitoring("currentPage", 1)
    await this.props.setDataMonitoring("size", 10)

    await this.props.checkBATransfer({
      from: this.state.from,
      to: this.state.to,
      page: this.props.currentPage,
      size: this.props.size
    })
  }

  async changeRowsPerPage(size){
    await this.props.setDataMonitoring("size", size)
    await this.props.checkBATransfer({
      from: this.state.from,
      to: this.state.to,
      page: this.props.currentPage,
      size: size
    })
  }

  async onChangePage(page){
    await this.props.setDataMonitoring("currentPage", page)
    await this.props.checkBATransfer({
      from: this.state.from,
      to: this.state.to,
      page: page,
      size: this.props.size
    })
  }

  timeFormat(data){
    if(!data){
      return
    }else{
      let date = new Date(data)
      let month = null
      if(date.getUTCMonth() < 10){
        month = `0${date.getUTCMonth() + 1}`
      }else{
        month = date.getUTCMonth() + 1
      }
      return moment().format(`${date.getFullYear()}/${month}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds()}`)
    }
  }

  refreshPage(){
    this.props.checkMonitoring(this.props.history, {}, false)
  }

  async toggleModal(){
    await this.props.setDataMonitoring("toggleAdd", !this.props.toggleAdd)
  }

  async exportData(){
    if(this.props.BATransfer.length === 0){
      await this.setState({failedExport: true})
    } else {
      await this.props.exportBATransfer({
        from: this.state.from,
        to: this.state.to
      })
    }
  }
  async refreshBATF(){
    const today = moment().format("YYYY-MM-DD")
    await this.setState({
      from: today,
      to: today
    })
    await this.props.setDataMonitoring("currentPage", 1)
    await this.props.setDataMonitoring("size", 10)
    await this.props.checkBATransfer({
      from: this.state.from,
      to: this.state.to,
      page: this.props.currentPage,
      size: this.props.size
    })
  }

  render() {
    const columns = [
      {
          name: "Code",
          selector: "id",
          sortable: true,
      },
      {
          name: "Name",
          selector: "name",
          sortable: true,
      },
      {
          name: "Balance",
          selector: "amount",
          sortable: true,
          cell: row => {
            return(
              convertToRupiah(row.balance)
            )
          }
      },
      {
          name: "UpdateAt",
          selector: "updated_at",
          sortable: true,
          cell: row => {
            return (
              this.timeFormat(row.updated_at)
            )
          }
      },
    ]
    const columnsBATransfer = [
      {
          name: "ID",
          selector: "id",
          sortable: true,
      },
      {
          name: "Amount",
          selector: "amount",
          cell: row => {
            return(
              convertToRupiah(row.amount)
            )
          }
      },
      {
          name: "Destination",
          selector: "destination",
      },
      {
          name: "Description",
          selector: "description",
      },
      {
          name: "CreatedAt",
          selector: "created_at",
          sortable: true,
          cell: row => {
            return (
              moment(row.createdAt).format('YYYY/MM/DD HH:mm:ss')
            )
          }
      },
      {
          name: "Created BY",
          selector: "created_by",
      },
    ]
    return (
      <React.Fragment>
        <NotificationMessage />
        <h2 className="card_title mb-3">Monitoring Balance</h2>
        <Row>
          <Col xl="4" lg="4" md="6" sm="12">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="fa fa-bank"></i>
                </div>
                <div className="icon_specs">
                  <p>Saldo Bank</p>
                  {!this.props.saldoBank ? <span>0</span> : convertToRupiah(this.props.saldoBank).substring(4, 5) === "-" ? 
                  <span className="text-danger">Rp. -{convertToRupiah(this.props.saldoBank).substring(5)}</span> :
                  <span className="h6">{convertToRupiah(this.props.saldoBank)}</span>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="ti-user"></i>
                </div>
                <div className="icon_specs">
                  <p>Saldo Member</p>
                  {!this.props.saldoMember ? <span>0</span> : convertToRupiah(this.props.saldoMember).substring(4, 5) === "-" ? 
                  <span className="text-danger">Rp. -{convertToRupiah(this.props.saldoMember).substring(5)}</span> :
                  <span className="h6">{convertToRupiah(this.props.saldoMember)}</span>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                    <i className="ti-stats-up"></i>
                </div>
                <div className="icon_specs">
                  <p>Revenue</p>
                  {!this.props.revenue ? <span>0</span> : convertToRupiah(this.props.revenue).substring(4, 5) === "-" ? 
                  <span className="text-danger">Rp. -{convertToRupiah(this.props.revenue).substring(5)}</span> :
                  <span className="h6">{convertToRupiah(this.props.revenue)}</span>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <Row>
        <Col xl={{ offset: 2, size: 4 }} lg={{ offset: 2, size: 4 }} md="6" sm="12">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="fa fa-money"></i>
                </div>
                <div className="icon_specs">
                  <p>Cash Out</p>
                  {!this.props.saldoCashOut ? <span>0</span> : convertToRupiah(this.props.saldoCashOut).substring(4, 5) === "-" ? 
                  <span className="text-danger">Rp. -{convertToRupiah(this.props.saldoCashOut).substring(5)}</span> :
                  <span className="h6">{convertToRupiah(this.props.saldoCashOut)}</span>
                  }
                </div>
              </CardBody>
            </Card>
        </Col>
        <Col xl="4" lg="4" md="6" sm="12">
            <Card className="card-icon rt_icon_card mb-4 text-center">
              <CardBody>
                <div className="icon_rt">
                  <i className="fa fa-balance-scale"></i>
                </div>
                <div className="icon_specs">
                  <p>Balance</p>
                  {!this.props.balance ? <span className="h6">0</span> : convertToRupiah(this.props.balance).substring(4, 5) === "-" ? 
                  <span className="text-danger h6">Rp. -{convertToRupiah(this.props.balance).substring(5)}</span> :
                  <span className="h6">{convertToRupiah(this.props.balance)}</span>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="stretched_card">
            <Card>
              <CardBody>
                <Container className="mt-2">
                  <Row>
                  <Col xl="2" lg="2" md="4" sm="4">
                    <Button color="primary" onClick={this.toggleModal}><i className="fa fa-paper-plane"></i> TRANSFER KAS</Button>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="4">
                    <Button color="primary" onClick={this.refreshPage}><i className="fa fa-refresh"></i> REFRESH</Button>
                  </Col>
                  </Row>
                </Container>
                <Container>
                  <DataTable
                    noHeader={true}
                    columns={columns}
                    data={this.props.banks}
                    responsive={true}
                  />
                  
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="stretched_card mt-4">
            <Card>
              <CardBody>
              <Container>
                  <Row className="mt-2">
                    <Col xl="5" lg="5" md="12" sm="12">
                      <div className="input-group">
                        <p style={{margin: "auto 0"}} className="mr-1">From</p>
                        <Input type="date" value={this.state.from} name="from" onChange={this.onChangeInput}/>
                        <p style={{margin: "auto 0"}} className="ml-1 mr-1">To</p>
                        <Input type="date" value={this.state.to} name="to" onChange={this.onChangeInput}/>
                      </div>
                    </Col>
                    <Col xl="5" lg="5" md="12" sm="12">
                      <Button color="primary" onClick={this.exportData}><i className="fa fa-download"></i> DOWNLOAD</Button>
                      <Button color="primary" onClick={this.refreshBATF} className="ml-2"><i className="fa fa-refresh"></i> REFRESH</Button>
                    </Col>
                  </Row>
                </Container>
                <Container>
                <DataTable
                    title="Bank Account Transfer"
                    columns={columnsBATransfer}
                    data={this.props.BATransfer}
                    pagination
                    responsive={true}
                    paginationTotalRows={this.props.totalItems}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                    onChangePage={this.onChangePage}
                    paginationServer={true}
                  />
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalAdd />

        {this.props.confirm ?
          <SweetAlert
            success
            title="SUCCESS"
            onConfirm={() => this.props.setDataMonitoring("confirm", false)}  >
            {this.props.msgSuccess}
            </SweetAlert> : null}

        {this.state.failedExport ?
          <SweetAlert
            error
            title="FAILED"
            onConfirm={() => this.setState({failedExport: false})}  >
            Don't have data to download
            </SweetAlert> : null}
      </React.Fragment>
    )
  }
}

const mapStatetoProps = state => {
  const { saldoBank, saldoMember, saldoCashOut, revenue, balance, banks, toggleAdd, confirm, msgSuccess, BATransfer, currentPage, totalItems, size } = state.Monitoring
  return { saldoBank, saldoMember, saldoCashOut, revenue, balance, banks, toggleAdd, confirm, msgSuccess, BATransfer, currentPage, totalItems, size };
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkMonitoring, setDataMonitoring, checkBATransfer, exportBATransfer })(MonitoringBalancePage));