import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, Badge, Input, Button } from "reactstrap";
import { activateAuthLayout } from "../../../store/actions";
import { checkStoreBadge, setNewStoreBadge, addNewStoreBadge } from "../store/storeBadge/actions"
import ModalEdit from "./ModalEdit"
import NotificationMessage from "../../NotificationMessage/Pages/Notification"

class StoreBadgePages extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: "",
            name: "",
            email: "",
            storeLogo: "",
            storeRating: "",
            badges: [],
            tmpName: ""
        }
        this.buttonEdit = this.buttonEdit.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.changeRowsPerPage = this.changeRowsPerPage.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.searchName = this.searchName.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    async componentDidMount(){
        let props = this.props
        await this.props.activateAuthLayout()
        await this.props.checkStoreBadge(this.props.history, {
        page: props.currentPage,
        size: props.size,
        }, true)
    }

    onChangeInput = async (e) => {
        const {name, value} = e.target
        let dataBadges = this.state.badges
        const filter = dataBadges.filter(el => el.id === +value)
        if(name === "badges"){
            if(filter.length > 0){
                const newFilter = dataBadges.filter(el => el.id !== +value)
                await this.setState({
                    [name]: newFilter
                })
            } else {
                let newData = {
                    id: +value
                }
                dataBadges.push(newData)
                await this.setState({
                    [name]: dataBadges
                })
            }
        } else {
            await this.setState({
                [name]: value
            })
        }
    }

    async changeRowsPerPage(size){
        let props = this.props
        await this.props.setNewStoreBadge("size", size)
        await this.props.checkStoreBadge(this.props.history, {
            page: props.currentPage,
            size: size,
            name: this.state.tmpName,
        }, false)
    }

    async onChangePage(page){
        await this.props.setNewStoreBadge("page", page)
        let props = this.props
        await this.props.checkStoreBadge(this.props.history, {
            page: page,
            size: props.size,
            name: this.state.tmpName,
        }, false)
    }

    async searchName(){
        let props = this.props
        await this.props.setNewStoreBadge("page", 1)
        await this.props.setNewStoreBadge("size", 10)
        await this.props.checkStoreBadge(this.props.history, {
            page: props.page,
            size: props.size,
            name: this.state.tmpName,
        }, false)
    }

    buttonEdit = async (data) => {
        await this.setState({
            id: data.id,
            name: data.name,
            email: data.email,
            storeLogo: data.store_logo,
            storeRating: data.store_ratting,
            badges: data.badges
        })
        await this.props.setNewStoreBadge("toggleEdit", !this.props.toggleEdit)
    }

    async handleKeyPress(e){
        if (e.key === "Enter") {
            let props = this.props
            await this.props.setNewStoreBadge("page", 1)
            await this.props.setNewStoreBadge("size", 10)
            await this.props.checkStoreBadge(this.props.history, {
                page: props.page,
                size: props.size,
                name: this.state.tmpName,
            }, false)
        }
    }

    render(){
        const columns = [
            {
                name: "ID",
                selector: "id",
                sortable: true,
                center: true,
                width: "100px"
            },
            {
                name: "Name",
                selector: "name",
                width: "150px"
            },
            {
                name: "Email",
                selector: "email",
                width: "200px"
            },
            {
                name: "Store Logo",
                selector: "store_logo",
                center: true,
                width: "100px",
                cell: row => {
                    return <img src={row.store_logo} alt={row.store_logo} />
                }
            },
            {
                name: "Store Rating",
                selector: "store_ratting",
                width: "50px",
                cell: row => {
                    return(
                        row.store_ratting === 0 ? "0" : row.store_ratting
                    )
                }
            },
            {
                name: "Badges",
                selector: "badges",
                center: true,
                grow: 10,
                cell: row => {
                    return(
                        row.badges.length === 0 ? "" :
                        row.badges.map(el => {
                            return (<Badge key={el.id} style={{backgroundColor: el.color}} className="mr-1">{el.name}</Badge>)
                            // return <EmailWrap el={el} key={el.id}/>
                        })
                    )
                }
            },
            {
                name: "Action",
                center: true,
                cell: (row) => {
                    return (
                    <>
                        <i className="ion-edit mr-2 text-primary" style={{fontSize: "25px", cursor: "pointer"}} data-pack="default" data-tags="change, update, write, type, pencil" onClick={() => this.buttonEdit(row)}></i>
                    </>
                    )
                }
            },
        ]
        return(
            <React.Fragment>
                <NotificationMessage />
                <Row>
                    <Col className="stretched_card">
                        <Card>
                        <CardBody>
                            <Container>
                            <Row>
                                <Col xl="3" lg="3" md="4" sm="12">
                                    <div className="input-group">
                                        <Input className="mr-1" type="text" onKeyPress={this.handleKeyPress} placeholder="Search name" name="tmpName" value={this.state.tmpName} onChange={this.onChangeInput}/>
                                        <Button onClick={this.searchName}>Search</Button>
                                    </div>
                                </Col>
                            </Row>
                            </Container>
                            <Container>
                            <DataTable
                                title="Store Badge"
                                columns={columns}
                                data={this.props.storeBadges}
                                pagination
                                responsive={true}
                                paginationTotalRows={this.props.totalItems}
                                paginationRowsPerPageOptions={[10,20,50,100]}
                                onChangeRowsPerPage={this.changeRowsPerPage}
                                onChangePage={this.onChangePage}
                                paginationServer={true}
                            />
                            </Container>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ModalEdit 
                onChangeInput={this.onChangeInput}
                data={{id: this.state.id, name: this.state.name, email: this.state.email, storeRating: this.state.storeRating, storeLogo: this.state.storeLogo, badges: this.state.badges}}
                />
            </React.Fragment>
        )
    }

}

const mapStatetoProps = state => {
    const { currentPage, totalItems, size, storeBadges, toggleAdd, toggleEdit } = state.StoreBadge
    return { currentPage, totalItems, size, storeBadges, toggleAdd, toggleEdit }
}

export default withRouter(connect(mapStatetoProps, { activateAuthLayout, checkStoreBadge, setNewStoreBadge, addNewStoreBadge })(StoreBadgePages));
