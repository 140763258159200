import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalFooter, Button, Col, FormGroup, Label, } from 'reactstrap';
import { setDataPost, cudPost } from "../store/post/actions"
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'
import { AvField, AvForm } from "availity-reactstrap-validation";
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css'; // ES6
import {BASE_URL_UPLOAD} from "../../../../helpers/constanta"
import axios from "axios"

var quillObj

class ModalEditPost extends Component{
    constructor(props){
        super(props)
        this.state = {
            expRight: false,
        }
        this.toggle = this.toggle.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.onChangeEditor = this.onChangeEditor.bind(this)
        this.imageHandler = this.imageHandler.bind(this)
    }

    toggle(){
        this.props.setDataPost("toggleEdit", !this.props.toggleEdit)
    }

    async onChangeInput(e){
        const {name, value} = e.target
        await this.setState({
            [name]: value
        })
    }

    async onChangeEditor(value){
        const payload = {
            target: {
                name: "content", value
            }
        }
        await this.props.onChangeInput(payload)
    }

    async imageHandler(){
        const input = document.createElement('input');
        input.setAttribute('type', 'file');  
        input.setAttribute('accept', 'image/*');  
        input.click();  

        input.onchange = async () => {  
            try {
                var fileImg = input.files[0];
                
                var FormData = require('form-data');
                const fd = new FormData();
                fd.append("file", fileImg, fileImg.name)
        
                const config = {
                    url: BASE_URL_UPLOAD,
                    method: "POST",
                    data: fd
                }
    
                const result = await axios(config)
                const range = quillObj.getEditorSelection();
                quillObj.getEditor().insertEmbed(range.index, 'image', result.data.imageurl);
            } catch (error) {
                console.log(error);
            }
        };  
    }

    modules = {
        toolbar: {
            container: "#t1",
            handlers: {
                undo: undoChange,
                redo: redoChange,
                image: this.imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        }
    }
    
    formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "color",
        "code-block"
    ]

    async submitForm(){
        const payload = {
            title: this.props.data.title,
            snippet: this.props.data.snippet,
            content: this.props.data.content,
        }
        await this.toggle()
        await this.props.cudPost("/admin/post", payload, "PUT", this.props.data.id)

        
    }
    

    render() {
        return(
            <React.Fragment>
                <Modal className="modal-lg" isOpen={this.props.toggleEdit} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Edit Post</ModalHeader>
                    <AvForm
                        onValidSubmit={this.submitForm}
                        >
                        <FormGroup>
                        <Label sm="12">Title<span className="text-danger">*</span></Label>
                            <Col sm="12">
                                <AvField type="text" placeholder="Input title" name="title" value={this.props.data.title} onChange={this.props.onChangeInput} required/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                        <Label sm="12">Snippet<span className="text-danger">*</span></Label>
                            <Col sm="12">
                                <AvField type="text" placeholder="Input snippet" name="snippet" value={this.props.data.snippet} onChange={this.props.onChangeInput} required/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                        <Label sm="12">Content</Label>
                            <Col sm="12">
                                <div id="t1">
                                    <span className="ql-formats">
                                        <button className="ql-bold" />
                                        <button className="ql-italic" />
                                        <button className="ql-underline" />
                                        <button className="ql-strike" />
                                    </span>
                                    <span className="ql-formats">
                                    <select className="ql-font">
                                        <option value="arial" selected> Arial </option>
                                        <option value="comic-sans">Comic Sans</option>
                                        <option value="courier-new">Courier New</option>
                                        <option value="georgia">Georgia</option>
                                        <option value="helvetica">Helvetica</option>
                                        <option value="Inter">Inter</option>
                                        <option value="lucida">Lucida</option>
                                    </select>
                                    <select className="ql-size">
                                        <option value="extra-small">Extra Small</option>
                                        <option value="small">Small</option>
                                        <option value="medium" selected>Medium</option>
                                        <option value="large">Large</option>
                                    </select>
                                    <select className="ql-header">
                                        <option value="1">Heading 1</option>
                                        <option value="2">Heading 2</option>
                                        <option value="3">Heading 3</option>
                                        <option value="4">Heading 4</option>
                                        <option value="5">Heading 5</option>
                                        <option value="6">Heading 6</option>
                                        <option value="" selected>Normal</option>
                                    </select>
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-list" value="ordered" />
                                        <button className="ql-list" value="bullet" />
                                        <button className="ql-indent" value="-1" />
                                        <button className="ql-indent" value="+1" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-script" value="super" />
                                        <button className="ql-script" value="sub" />
                                        <button className="ql-blockquote" />
                                        <button className="ql-direction" />
                                    </span>
                                    <span className="ql-formats">
                                        <select className="ql-align" />
                                        <select className="ql-color" />
                                        <select className="ql-background" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-link" />
                                        <button className="ql-image" />
                                        <button className="ql-video" />
                                    </span>
                                    <span className="ql-formats">
                                        <button className="ql-formula" />
                                        <button className="ql-code-block" />
                                        <button className="ql-clean" />
                                    </span>
                                    <span className="ql-formats">
                                    <button className="ql-undo">
                                        <CustomUndo />
                                    </button>
                                    <button className="ql-redo">
                                        <CustomRedo />
                                    </button>
                                    </span>
                                </div>
                                <ReactQuill 
                                    ref={(el) => {  
                                        quillObj = el;  
                                    }}
                                    theme="snow"
                                    value={this.props.data.content}
                                    onChange={this.onChangeEditor}
                                    placeholder="Add a description of your event" 
                                    modules={this.modules}
                                    formats={this.formats}
                                />
                            </Col>
                        </FormGroup>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        <LaddaButton
                            loading={this.state.expRight}
                            data-style={EXPAND_RIGHT}
                            className="btn btn-primary"
                        >Save changes</LaddaButton>
                    </ModalFooter>
                    </AvForm>
            </Modal>
            </React.Fragment>
        )
    }
}

//============= SETUP FOR REACT-QUILL =====================//

const CustomUndo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
        <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
        />
    </svg>
);

  // Redo button icon component for Quill editor
const CustomRedo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
        <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
        />
    </svg>
);

function undoChange() {
    this.quill.history.undo();
}

function redoChange() {
    this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);


  // Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "Inter",
    "lucida"
];
Quill.register(Font, true);

// For resize image
Quill.register('modules/imageResize', ImageResize);

const mapStatetoProps = state => {
    const { toggleEdit } = state.Post
    return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setDataPost, cudPost })(ModalEditPost))