import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { ADD_CATEGORY_P2P } from '../actionTypes';
import {  setCategoryP2P, setNewCategoryP2P } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* categoryP2P({payload: {url, state, method, id}}) {
    try {
        yield call(hitAPIWasmarket, url, state, method, id)
        const response = yield call(callGetCategory, "/admin/p2p/category", {})
        yield put(setCategoryP2P(response))
        if(method === "POST"){
          yield put(setNewCategoryP2P("descSuccess", "Sukses menambah category!"))
        }
        if(method === "PUT"){
          yield put(setNewCategoryP2P("descSuccess", "Sukses mengganti category!"))
        }
        if(method === "DELETE"){
          yield put(setNewCategoryP2P("descSuccess", "Sukses menghapus category!"))
        }
        yield put(setNewCategoryP2P("successDelete", true))
    } catch (error) {
      console.log(error, "saga======")
      yield put(setNewCategoryP2P("failedDelete", true))
      yield put(setNewCategoryP2P("descFailed", "Internal server error saga!"))
    }
}

export function* watchUserCategoryP2P() {
    yield takeEvery(ADD_CATEGORY_P2P, categoryP2P)
}

function* addCategoryP2PSaga() {
    yield all([fork(watchUserCategoryP2P)]);
}

export default addCategoryP2PSaga;