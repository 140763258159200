import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { UPDATE_DATA_P2P } from '../actionTypes';
import {  setP2P, setDataP2P } from '../actions';
//============ Authentication Functions =============*
import { callGetCategory, hitAPIWasmarket } from '../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* updatesDataP2P({payload: {url, state, method, id}}) {
    try {
        const { from, to, buyer_name, seller_name, idpelanggan, status, payment_status, name, statusOrder, buyer_id } = state

        yield call(hitAPIWasmarket, url, {status: statusOrder, buyer_id}, method, id)
        const response = yield call(callGetCategory, "/admin/p2p", {from, to, buyer_name, seller_name, idpelanggan, status, payment_status, name })

        yield put(setP2P(response))
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("confirm", true))
        yield put(setDataP2P("expRightEdit1", false))
        yield put(setDataP2P("expRightEdit2", false))
        yield put(setDataP2P("msgSuccess", "Sukses update transaction P2P"))
    } catch (error) {
        yield put(setDataP2P("toggleSee", false))
        yield put(setDataP2P("expRightEdit1", false))
        yield put(setDataP2P("expRightEdit2", false))
        yield put(setDataP2P("failedUpdate", true))
        yield put(setDataP2P("msgFailed", error.message))
        console.log(error);
    }
}

export function* watchUpdateDataP2P() {
    yield takeEvery(UPDATE_DATA_P2P, updatesDataP2P)
}

function* updateDataP2P() {
    yield all([fork(watchUpdateDataP2P)]);
}

export default updateDataP2P;