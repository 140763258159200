import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_KYC, } from './actionTypes';
import {  setKYC } from './actions';
//============ Authentication Functions =============*
import { callGetCategory } from '../../../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* managementUser({payload: {state}}) {
    try {
        const response = yield call(callGetCategory, "/admin/kyc", state)
        yield put(setKYC(response))
    } catch (error) {
        if(error.responseCode !== 404){
        }else if(error.responseCode === 404){
            yield put(setKYC({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
        }
    }
}

export function* watchKYC() {
    yield takeEvery(CHECK_KYC, managementUser)
}

function* KYCSaga() {
    yield all([fork(watchKYC)]);
}

export default KYCSaga;