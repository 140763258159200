import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_CATEGORY_P2P, } from './actionTypes';
import { setCategoryP2P } from './actions';
import { setUsername } from "../../Category/store/username/actions"
//============ Authentication Functions =============*
import { callGetCategory, checkToken } from '../../../helpers/authUtils';


//============ Dispatch Redux Actions directly =============*
function* category_p2p({payload: {history, state, flag}}) {
    try {
        let response = null
        if(flag === true){
            const responseUsername = yield call(checkToken)
            yield put(setUsername(responseUsername))
        }
        response = yield call(callGetCategory, "/admin/p2p/category", state)
        yield put(setCategoryP2P(response))
        // yield put(setNewCategoryP2P("expRight", false))
    } catch (error) {
        if(error.responseCode !== 404){
            history.push('/logout')
        }else if(error.responseCode === 404){
            yield put(setCategoryP2P({
                rows: [],
                currentPage: 1,
                totalItems: 0,
                totalPages: 0,
            }))
            // yield put(setNewCategoryP2P("expRight", false))
        }
    }
}

export function* watchCategoryP2P() {
    yield takeEvery(CHECK_CATEGORY_P2P, category_p2p)
}

function* categoryP2PSaga() {
    yield all([fork(watchCategoryP2P)]);
}

export default categoryP2PSaga;