import { CHECK_TRANSACTION, SET_TRANSACTION, SET_DATA_TRANSACTION, CHECK_FIND_NAME, UPDATE_TRANSACTION, EXPORT_TRANSACTION } from "./actionTypes"

export const checkTransaction = (history, state, flag) => {
  return {
    type: CHECK_TRANSACTION,
    payload: {history, state, flag}
  }
}

export const setTransaction = (props) => {
  return {
    type: SET_TRANSACTION,
    payload: props
  }
}

export const setDataTransaction = (name, data) => {
  return {
    type: SET_DATA_TRANSACTION,
    payload: {name, data}
  }
}

export const checkFindName = (state) => {
  return {
    type: CHECK_FIND_NAME,
    payload: {state}
  }
}
export const updateTransaction = (url, state, method, id) => {
  return {
    type: UPDATE_TRANSACTION,
    payload: {url, state, method, id}
  }
}

export const exportTransaction = (state) => {
    return {
      type: EXPORT_TRANSACTION,
      payload: {state}
    }
  }