import { takeEvery, fork, all, call } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { EXPORT_MANAGEMENT_USER } from '../actionTypes';
//============ Authentication Functions =============*
import { exportFile } from '../../../../helpers/authUtils';
import fileDownload from 'js-file-download';
import moment from "moment"
//============ Dispatch Redux Actions directly =============*
function* exportManagementUser({payload}) {
    try {
      const result = yield call(exportFile, "/admin/user/export", payload.state)
      fileDownload(result, "management user (" + moment().format("YYYY-MM-DD") + ").xlsx")
    } catch (error) {
      console.log(error);
    }
}

export function* watchExportManagementUser() {
    yield takeEvery(EXPORT_MANAGEMENT_USER, exportManagementUser)
}

function* exportManagementUserSaga() {
    yield all([fork(watchExportManagementUser)]);
}

export default exportManagementUserSaga;