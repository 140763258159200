import { takeEvery, fork, all, call, put } from 'redux-saga/effects';
//============ Redux States for Login =============*
import { CHECK_LOGIN } from './actionTypes';
import { setUsername } from '../../../modules/Category/store/username/actions'
//============ Authentication Functions =============*
import { getToken, checkToken } from '../../../helpers/authUtils'; //userSetInLocalStorage
//============ Redux Action =============*
import { apiError } from './actions'

//============ Dispatch Redux Actions directly =============*
function* loginUser({ payload: { email, password, history } }) {
    try {
        yield call(getToken, {email: email, password: password})
        const respUsername = yield call(checkToken)
        yield put(setUsername(respUsername.name))
        
        history.push('/manage-user');
        setTimeout(() => {
            window.location.reload()
        }, 500);
        

    } catch (error) {
        yield put(apiError("Email or password wrong"));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

function* loginSaga() {
    yield all([fork(watchUserLogin)]);
}

export default loginSaga;