import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, Button, Col, FormGroup, Label } from 'reactstrap';
import { setNewSetting, addNewSetting } from "../store/setting/actions"
import { AvField, AvForm } from "availity-reactstrap-validation";
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'

class ModalEdit extends Component{
    constructor(props){
        super(props)
        this.state = {
            expRight: false,
        }
        this.toggle = this.toggle.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    toggle = async () => {
        await this.props.setNewSetting("toggleEdit", !this.props.toggleEdit)
    }

    async submitForm(){
        let result = {...this.props.data}
        await this.setState({
            expRight: true
        })
        delete result.id
        await this.props.addNewSetting("/admin/setting", result, "PUT", this.props.data.id)
        await this.setState({
            expRight: false
        })
        await this.toggle()
    }

    render(){
        return(
            <React.Fragment>
                <Modal isOpen={this.props.toggleEdit} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} >Edit Fee</ModalHeader>
                    <AvForm onValidSubmit={this.submitForm}>
                        <FormGroup>
                            <Label sm="12">Seller Product Fee Transaction</Label>
                            <Col sm="12">
                                <AvField type="number" placeholder="Input FEE" name="sellerFee" value={this.props.data.sellerFee === 0 ? "0" : this.props.data.sellerFee } onChange={this.props.onChangeInput} 
                                    validate={{
                                        required: { value: true, errorMessage: 'Seller FEE is required'},
                                        min: { value: 0, errorMessage: 'Seller FEE minimal 0' }
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label sm="12">Fee VA</Label>
                            <Col sm="12">
                                <AvField required type="number" placeholder="Input name" name="feeVA" value={this.props.data.feeVA === 0 ? "0" : this.props.data.feeVA} onChange={this.props.onChangeInput} 
                                    validate={{
                                        required: { value: true, errorMessage: 'FEE VA is required'},
                                        min: { value: 0, errorMessage: 'FEE VA minimal 0' }
                                    }}
                                />
                            </Col>
                        </FormGroup>
                    <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    <LaddaButton
                        loading={this.state.expRight}
                        data-style={EXPAND_RIGHT}
                        className="btn btn-primary"
                    >Save changes</LaddaButton>
                    
                    </ModalFooter>
                    </AvForm>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    const { toggleEdit } = state.Setting
    return { toggleEdit };
}

export default withRouter(connect(mapStatetoProps, { setNewSetting, addNewSetting })(ModalEdit));