import { CHECK_STORE_BADGE, SET_STORE_BADGE, SET_CHANGE_STORE_BADGE, ADD_STORE_BADGE } from "./actionTypes"

export const checkStoreBadge = (history, state, flag) => {
  return {
    type: CHECK_STORE_BADGE,
    payload: {history, state, flag}
  }
}

export const setStoreBadge = (props) => {
  return {
    type: SET_STORE_BADGE,
    payload: props
  }
}

//edit property di reducer berdasarkan nama initialState
export const setNewStoreBadge = (name, data) => {
  return {
    type: SET_CHANGE_STORE_BADGE,
    payload: {name, data}
  }
}

export const addNewStoreBadge = (url, state, method, id) => {
  return {
    type: ADD_STORE_BADGE,
    payload: {url, state, method, id}
  }
}